import { ApiResponse, UserDsrConsentRequest } from '../../@types';
import Api from '../api';

const DSR_CONSENT_REQUEST_BASE = 'dsr-consent/';

class UserDsrConsentRequestApi {
    public async createDsrConsentRequest(request: UserDsrConsentRequest, accessToken: string): Promise<ApiResponse> {
        const {isShared, showId} = request;
        const response = await Api.performPOSTRequest(
            `${DSR_CONSENT_REQUEST_BASE}${showId}`,
            {isShared : isShared},
            accessToken
        );
        return response;
    }
}

export default new UserDsrConsentRequestApi();