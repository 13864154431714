import React, { useEffect, useState } from 'react';
import { Alert, Form, ListGroup } from 'react-bootstrap';
import { ApiResponseHandler, Nullable } from '../../@types';
import { Attendee } from '../../@types/attendee';
import { useAccessToken } from '../../hook/accessTokenHook';
import { useAppSelector, useDebounce } from '../../hook/appHook';
import AttendeeService from '../../service/attendee/AttendeeService';
import { Variant } from '../../util/bootstrapUtil';
import MBLabelGroup from '../app/mbLabelGroup';

interface AttendeeAutocompleteProps {
    onSelect: (attendee: Attendee) => any;
}

const buildAttendeeText = (attendee: Attendee): string =>
    `${attendee.firstName} ${attendee.lastName} - ${attendee.email} - ${attendee.customerNumber} ${attendee.customerName}`;

const AttendeeAutocomplete: React.FC<AttendeeAutocompleteProps> = ({ onSelect }) => {
    const accessToken = useAccessToken();
    const { selectedShow } = useAppSelector((state) => state.ShowState);
    const [filterText, setFilterText] = useState('');
    const [error, setError] = useState<Nullable<string>>(null);
    const [attendees, setAttendees] = useState<Attendee[]>([]);
    const [isResultsVisible, setIsResultsVisible] = useState(false);
    const debouncedFilterText = useDebounce(filterText, 350);

    const showId: number = selectedShow?.showId ?? 0;

    useEffect(() => {
        if (!isResultsVisible) {
            return;
        }

        if (debouncedFilterText.trim().length < 3) {
            setAttendees([]);
        } else {
            if (!accessToken) {
                return;
            }

            const handler: ApiResponseHandler<Attendee[]> = {
                onSuccess: (attendees) => {
                    setAttendees(attendees ?? []);
                    setIsResultsVisible(true);
                },
                onError: (error) => {
                    setError(error);
                    setAttendees([]);
                },
            };

            AttendeeService.getAttendees(accessToken, showId, handler, { filterText: debouncedFilterText.trim() });
        }
    }, [debouncedFilterText, setAttendees, accessToken, showId, isResultsVisible]);

    const handleResultClick = (attendee: Attendee) => {
        setIsResultsVisible(false);
        onSelect(attendee);
        setAttendees([]);
        setFilterText(buildAttendeeText(attendee));
    };

    const handleInputChange = (text: string) => {
        setFilterText(text);
        const isShowResults = text.trim().length >= 3;

        if (isShowResults !== isResultsVisible) {
            setIsResultsVisible(isShowResults);
            setAttendees([]);
        }
    };

    return (
        <div>
            {!!error && (
                <Alert variant={Variant.DANGER} onClose={() => setError(null)} dismissible>
                    {error}
                </Alert>
            )}
            <MBLabelGroup id="attendeeAutocomplete" label="Search for an Attendee" isBlock>
                <Form.Control
                    type="search"
                    placeholder="Search attendees..."
                    aria-label="Search Attendees"
                    value={filterText}
                    autoFocus
                    onChange={(event) => handleInputChange(event.target.value)}
                    id="attendeeAutocomplete"
                />
            </MBLabelGroup>
            {isResultsVisible && !!attendees.length && (
                <ListGroup
                    style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        marginTop: 5,
                        width: 'calc(100% - 2rem)',
                        zIndex: 9000,
                    }}
                    className="attendee-autocomplete-result-container"
                >
                    {attendees.map((attendee) => (
                        <ListGroup.Item
                            key={`attendeeResult_${attendee.email}`}
                            className="attendee-autocomplete-result"
                            onClick={() => handleResultClick(attendee)}
                        >
                            {buildAttendeeText(attendee)}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            )}
        </div>
    );
};

export default AttendeeAutocomplete;
