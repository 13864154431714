import React, {useEffect, useState} from "react";
import {Container, Col, Row, Accordion, Alert} from 'react-bootstrap';
import { APP_ROOT_TITLE } from "../App";
import { useAppSelector } from "../hook/appHook";
import { UserNotes, Presenter, Theme, Nullable, ApiResponseHandler } from "../@types";
import ThemeNoteList from "../component/theme/themeNoteList";
import PresenterNoteList from "../component/presenter/presenterNoteList";
import { useAccessToken } from "../hook/accessTokenHook";
import { Session } from "../@types/session";
import SessionService from "../service/session/sessionService";
import Loader from "../component/app/loader";
import SessionNoteList from "../component/session/sessionNoteList";
import { Variant } from "../util/bootstrapUtil";

const EMPTY_NOTES:UserNotes = {
    itemNotes: [],
    presenterFeatureNotes: [],
    themeFeatureNotes: [],
    sessionNotes: [],
    sessionFeatureNotes: []
};

const NoteListContainer:React.FC<any> = () => {
    const {isLoading, notes, likes, followupRequests} = useAppSelector((state) => state.UserState);
    const {presenters} = useAppSelector((state) => state.PresenterState);
    const {themes} = useAppSelector((state) => state.ThemeState);
    const attendee = useAppSelector((state) => state.UserState.attendee);
    const showId = useAppSelector((state) => state.ShowState.selectedShow?.showId ?? 0);
    
    const [allNotes, setAllNotes] = useState<UserNotes>(EMPTY_NOTES);
    const [presentersWithNotes, setPresentersWithNotes] = useState<Presenter[]>([]);
    const [themesWithNotes, setThemesWithNotes] = useState<Theme[]>([]);
    const [isApiLoading, setIsApiLoading] = useState(false);
    const [error, setError] = useState<Nullable<string>>(null);

    const [sessions, setSessions] = useState<Nullable<Session[]>>(null);
    const accessToken = useAccessToken();

    useEffect(() => {
        document.title = `${APP_ROOT_TITLE} - Notes`
    }, []);

    useEffect(() => {
        if (isLoading || !accessToken || (sessions != null)) {
            return;
        }

        if (!notes.sessionFeatureNotes.length 
                && !notes.sessionNotes.length
                && !likes.likedSessions.length
                && !likes.likedSessionFeatures.length) {
            setSessions([]);
            return;
        }

        const handler:ApiResponseHandler<Session[]> = {
            onSuccess: (sessions) => {
                setSessions(sessions.filter((session) => {
                    const sessionId = session.sessionId;
                    if (!!notes.sessionNotes.find((sessionNote) => sessionId === sessionNote.sessionId)
                            || !!likes.likedSessions.find((likedSession) => !!likedSession.isLiked && sessionId === likedSession.sessionId)) {
                        return true;
                    }
                    
                    const features = session.features ?? [];
                    const sessionFeatures = features.filter((feature) => {
                        const featureId = feature.featureId;
                        return !!notes.sessionFeatureNotes.find((featureNote) => featureId === featureNote.featureId)
                            || !!likes.likedSessionFeatures.find((likedFeature) => !!likedFeature.isLiked && (featureId === likedFeature.featureId));
                    });

                    return !!sessionFeatures.length;
                }));
            },
            onError: (error) => {
                setError(error);
                setSessions([]);
            },
            onComplete : () => setIsApiLoading(false)
        };

        setIsApiLoading(true);
        SessionService.getSessionList(accessToken, showId, true, handler);

    }, [isLoading, notes, likes, accessToken, sessions, showId]);

    useEffect(() => {
        const themesWithNotes = themes.filter((theme) => 
            !!theme.features.find((feature) => 
                !!notes.themeFeatureNotes.find((featureNote) => feature.featureId === featureNote.featureId)
                || !!likes.likedThemeFeatures.find((likedFeature) => feature.featureId === likedFeature.featureId && likedFeature.isLiked)));

        const presentersWithNotes = presenters.filter((presenter) => 
            presenter.itemNumbers.find((itemNumber) => !!notes.itemNotes.find((itemNote) => itemNumber === itemNote.itemNumber)
                || likes.likedItems.find((likedItem) => likedItem.itemNumber === itemNumber && likedItem.isLiked))
            || !!presenter.features.filter((presenterFeature) => 
                !!notes.presenterFeatureNotes.find((featureNote) => featureNote.featureId === presenterFeature.featureId)
                || likes.likedPresenterFeatures.find((likedFeature) => (likedFeature.featureId === presenterFeature.featureId) && likedFeature.isLiked)).length
            || !!followupRequests.find((followup) => followup.presenterId === presenter.presenterId));

        setPresentersWithNotes(presentersWithNotes);
        setThemesWithNotes(themesWithNotes);
        setAllNotes(notes);        
    }, [presenters, themes, notes, allNotes, likes, followupRequests]);

    const isNotePresent = !!presentersWithNotes.length
        || !!themesWithNotes.length;

    return(
        <div>
            <Container fluid className="gx-5">
                {(isLoading || isApiLoading) && <Loader />}
                {!!error && 
                <Alert variant={Variant.DANGER} 
                        onClose={() => setError(null)} 
                        dismissible/>
                }
                <Row className="border-bottom mb-2 gx-0">
                    <Col sm={12} md={1}>
                        <h2>Notes</h2>
                    </Col>
                    {!!attendee?.dsrName &&
                    <Col className="d-md-flex justify-content-end">
                        <div>
                            <h4>Martin Bros Sales Rep</h4>
                            <p>
                                <span id="noteListDsrName" className="me-1">{attendee.dsrName}</span>
                                {!!attendee.dsrEmail &&
                                <a id="noteListDsrEmail" href={`mailto:${attendee.dsrEmail}`}>{attendee.dsrEmail}</a>
                                }
                                {!!attendee.dsrEmail && !!attendee.dsrPhone && <span> | </span>}
                                {!!attendee.dsrPhone &&
                                <a id="noteListDsrPhone" href={`tel:${attendee.dsrPhone}`}>{attendee.dsrPhone}</a>
                                }
                            </p>
                        </div>
                    </Col>
                    }
                </Row>

                {!isNotePresent &&
                    <Row className="gx-0 mt-4">
                        <Col className="text-center">
                            {isLoading ? 'Collecting notes...' : 'No notes have been created'}
                        </Col>
                    </Row>
                }

                {!!presentersWithNotes.length &&
                    <Row className="mt-3 gx-0">
                        <Col>
                            <h4>Presenters</h4>
                        </Col>
                        <Accordion id="presenterNotes" alwaysOpen={false}>
                            <PresenterNoteList 
                                    presenters={presentersWithNotes} 
                                    notes={allNotes}
                                    likes={likes}
                                    followupRequests={followupRequests}/>
                        </Accordion>
                    </Row>
                }

                {!!themesWithNotes.length &&
                    <Row className="mt-3 gx-0">
                        <Col>
                            <h4>Themes</h4>
                        </Col>
                        <Accordion  id="themeNotes" alwaysOpen={false}>
                            <ThemeNoteList 
                                    themes={themesWithNotes} 
                                    featureNotes={allNotes.themeFeatureNotes}
                                    likedFeatures={likes.likedThemeFeatures} />
                        </Accordion>
                    </Row>
                }

                {!!sessions?.length &&
                    <Row className="mt-3 gx-0">
                        <Col>
                            <h4>Sessions</h4>
                        </Col>
                        <Accordion id="sessionNotes" alwaysOpen={false}>
                             <SessionNoteList sessions={sessions} 
                                    likedSessions={likes.likedSessions}
                                    sessionNotes={allNotes.sessionNotes}
                                    likedFeatures={likes.likedSessionFeatures}
                                    featureNotes={allNotes.sessionFeatureNotes} />
                        </Accordion>
                    </Row>
                }
            </Container>
        </div>
    )
}
            
export default NoteListContainer;