import React, { useEffect, useState } from 'react'
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { ApiResponseHandler, Nullable, Show } from '../@types';
import { APP_ROOT_TITLE } from '../App';
import Loader from '../component/app/loader';
import ShowList from '../component/show/showList';
import { useAccessToken } from '../hook/accessTokenHook';
import ShowService from '../service/show/showService';

export interface ShowListContainerProps {
    isNavigateOnChange : boolean
}

const ShowListContainer:React.FC<ShowListContainerProps> = ({isNavigateOnChange}) => {
    const accessToken = useAccessToken();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [shows, setShows] = useState<Nullable<Show[]>>(null);
    const [error, setError] = useState<Nullable<string>>(null);
    
    useEffect(() => {
        document.title = `${APP_ROOT_TITLE} - Events` 

        if (isLoading || (shows != null) || !accessToken) {
            return;
        }

        setIsLoading(true);
        const handler:ApiResponseHandler<Show[]> = {
            onSuccess : (shows) => setShows(shows ?? []),
            onError : (error) => {
                setError(error);
                setShows([]);
            },
            onComplete : () => setIsLoading(false)
        };

        ShowService.getShowList(accessToken, handler);
    }, [isLoading, shows, accessToken]);

    return (
        <Container fluid={false} className="gx-5 container-events">
            {!!error &&
                <Alert 
                        variant="danger" 
                        onClose={() => setError(null)} 
                        dismissible>
                    {error}
                </Alert>
            }
            {!shows?.length && 
                <Row className="gx-0 pb-2 pb-md-0 mb-3">
                    <Col>
                        <h2>No events are currently available</h2>
                    </Col>
                </Row>
            }
            {!!shows?.length && 
                <>
                    <Row className="border-bottom border-light gx-0 pb-2 pb-md-0 mb-3">
                        <Col>
                            <h2>Please choose an event</h2>
                        </Col>
                    </Row>
                    <Row className="g-4">
                        <Col sm={12}>
                            <ShowList shows={shows} isNavigateOnChange={isNavigateOnChange} />
                        </Col>
                    </Row>
                </> 
            }
            {isLoading && <Loader />}
        </Container>
    );
};

export default ShowListContainer;