import React, {ChangeEvent, HTMLProps, LegacyRef, useState } from 'react';
import MBButtonLink from './mbButtonLink';
import { Variant } from '../../../util/bootstrapUtil';

export interface SelectOptionItem {
    value:string,
    text:string
}

interface ToggleSelectProps extends HTMLProps<HTMLSelectElement> {
    inputClass?:string,
    buttonClass?:string,
    buttonVariant?:Variant,
    identifier: string,
    defaultText:string,
    value:string,
    options:SelectOptionItem[]
    isRequired?: boolean,
    isDisabled?: boolean,
    validationMessage?: string,
    onValueChanged: (value:string) => void,
    isEditValid : (value:string) => boolean,
    onEditFieldChanged?: (isInFocus:boolean) => void
}

const ToggleSelect:React.FC<ToggleSelectProps> = (props) => {
    
    const selectedOption = props.options.find((option) => option.value === props.value);
    const selectedText = selectedOption?.text ?? (props.defaultText ?? 'Choose an option');

    const [isInputDisplayed, setIsInputDisplayed] = useState(false);
    const [displayText, setDisplayText] = useState(selectedText);
    const [value, setValue] = useState(props.value);
    const [isValid, setIsValid] = useState(false);
    const selectInput:LegacyRef<HTMLSelectElement> = React.useRef(null);

    React.useEffect(() => {
        if (isInputDisplayed) {
            selectInput?.current?.focus();
        }
    }, [isInputDisplayed]);

    function enableEditing() {
        if (!props.isDisabled) {
            setIsInputDisplayed(true);
        }
    }

    function onValueChanged(event:ChangeEvent<HTMLSelectElement>) {
        const select = event.currentTarget;
        const selectedOption = select.options[select.selectedIndex];
        const newValue = selectedOption.value;
        const newText = selectedOption.text;
        setValue(newValue);
        setDisplayText(newText);
        const isValid = props.isEditValid?.(newValue) ?? true;
        setIsValid(isValid);
    }

    function onInputBlur() {
        setIsInputDisplayed(false);
        props.onEditFieldChanged?.(false);
        props.onValueChanged(value);
    }

    function onInputFocus() {
        props.onEditFieldChanged?.(true);
    }

    const buttonClasses:string[] = [props.buttonClass ?? ''];
    if (isInputDisplayed) {
        buttonClasses.push('d-none');
    }

    if (!!props.isRequired) {
        buttonClasses.push('required-field');
    }
    
    const inputClasses:string[] = [props.inputClass ?? ''];
    if (!isInputDisplayed) {
        inputClasses.push('d-none');
    }

    return (
        <>
            {!isInputDisplayed &&
                <div className={`mb-plaintext ${props.className ?? ''}`}>
                    <MBButtonLink id={`${props.identifier}-toggleButton`}
                            variant={props.buttonVariant}
                            className={`text-start ${buttonClasses.join(' ')}`}
                            onClick={enableEditing}>
                        {displayText}
                    </MBButtonLink>
                </div>
            }
            <select ref={selectInput}
                    value={value}
                    onChange={onValueChanged}
                    onFocus={onInputFocus}
                    onBlur={onInputBlur}
                    id={props.identifier} 
                    className={`mb-form-control ${inputClasses.join(' ')} ${props.className ?? ''}`}
                    disabled={props.isDisabled}>
                {!selectedOption &&
                    <option value={props.value}>{selectedText}</option>
                }
                {props.options.map((option) => 
                    <option key={`selectToggle-${option.value}`} value={option.value}>{option.text}</option>
                )}
            </select>
            {!isValid && !!props.validationMessage &&
                <div className="error-message">
                    {props.validationMessage}
                </div>
            }
        </>
    );
};

export default ToggleSelect;