import { ApiResponseHandler, FileUploadRequest, Nullable } from "../../@types";
import { REQUIRED_TOKEN_MESSAGE } from "../../api/api";
import MediaApi from "../../api/media/mediaApi";
import { handleApiResponse } from "../../util/apiUtil";

export const IMAGE = 'image';
export const VIDEO = 'video';
export type MEDIA_TYPE = typeof IMAGE | typeof VIDEO;

class MediaService {
    public async uploadFile(token:Nullable<string>, request:FileUploadRequest, handler:ApiResponseHandler<boolean>):Promise<void> {
        if (!token) {
            handleApiResponse({data: null, error: REQUIRED_TOKEN_MESSAGE}, handler);
            return;
        }

        const response = await MediaApi.postFile(token, request);
        handleApiResponse(response, handler);
    }
}

export default new MediaService();