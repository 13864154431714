import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import { Variant } from '../../../util/bootstrapUtil';

const MBButtonLink:React.FC<ButtonProps> = ({children, ...buttonProps}) => {
    buttonProps.className = `p-0 ${buttonProps.className ?? ''}`;
    
    if (Variant.PLAIN !== buttonProps.variant) {
        buttonProps.variant = Variant.LINK;
    }
    
    return (
        <Button {...buttonProps}>                    
            {children}
        </Button>            
    );
};

export default MBButtonLink;