import React from 'react';
import { Theme } from '../../@types';
import ThemeList from './themeList';

export interface GroupedThemeListProps {
    themes:Theme[]
}

const THEME_NAME_COMPARATOR = (theme1:Theme, theme2:Theme) => {
    return (theme1.name < theme2.name)
        ? -1
        : (theme1.name > theme2.name) 
            ? 1
            : 0;
}

const GroupedThemeList:React.FC<GroupedThemeListProps> = ({themes}) => {
    const themesNotInGroup:Theme[] = themes.filter((theme) => !theme.groupName).sort(THEME_NAME_COMPARATOR);
    const groupedThemes:{groupName:string, sort:number, themes:Theme[]}[] = [];

    themes.filter((theme) => !!theme.groupName)
    .forEach((theme) => {
        const groupName = theme.groupName ?? '';
        let group = groupedThemes.find((group) => group.groupName === groupName);
        if (!group) {
            group = {
                groupName : groupName, 
                sort : theme.floorOrder ?? 0,
                themes : []
            };

            groupedThemes.push(group);
        }

        group.themes.push(theme);
        group.themes.sort(THEME_NAME_COMPARATOR);
    });

    groupedThemes.sort((group1, group2) => group1.sort - group2.sort);

    return (
        <div>
            {!!themesNotInGroup &&
                <div className="mb-2">
                    <ThemeList themes={themesNotInGroup} />
                </div>
            }
            {!!groupedThemes.length && groupedThemes.map((themeGroup) => 
                <div className="mb-2" key={`groupedThemes-${themeGroup.groupName}`}>
                    <h3 className="p-2 bg-charcoal-200">{themeGroup.groupName}</h3>
                    <ThemeList themes={themeGroup.themes} />
                </div>
            )}
        </div>
    );
};

export default GroupedThemeList;