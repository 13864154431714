import { createSlice } from "@reduxjs/toolkit";
import { ThemeState } from "../../@types";

const INITIAL_STATE:ThemeState = {
    isLoading : false,
    themes : []
};

const themeSlice = createSlice({
    name: 'theme',
    initialState: INITIAL_STATE,
    reducers: {
        fetchThemeList: (state) => {
            state.isLoading = true;
        },
        fetchThemeListSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.themes = payload;
        },
        fetchThemeListFailure: (state) => {
            state.isLoading = false;
            state.themes = [];
        }
    }
});

export const {fetchThemeList, fetchThemeListSuccess, fetchThemeListFailure} = themeSlice.actions;
export default themeSlice.reducer;