export const ID_PARAM = ':id';
export const SEARCH_PARAM = ':searchTerm';
export const ROUTE_SESSION_LIST_PARAM = ':group';
export const PRESENTER_ID_PARAM = ':presenterId';
export const FEATURE_ID_PARAM = ':featureId';
export const THEME_ID_PARAM = ':themeId';
export const SESSION_ID_PARAM = ':sessionId';
export const SLOT_TIME_PARAM = ':time';

export const ROUTE_SHOW_LIST = '/events-list';
export const ROUTE_SESSION_LIST = '/session-list';
export const ROUTE_SESSION_DETAIL = `/session-detail/${ID_PARAM}`;
export const ROUTE_SESSION_DETAIL_SEARCH = `/session-detail/${ID_PARAM}/search/${SEARCH_PARAM}`;
export const ROUTE_SESSION_DETAIL_SLOT = `/session-detail/${ID_PARAM}/slot/${SLOT_TIME_PARAM}`;
export const ROUTE_PRESENTER_LIST = '/presenter-list';
export const ROUTE_THEME_LIST = '/theme-list';
export const ROUTE_ITEM_LIST = '/item-list';
export const ROUTE_NOTE_LIST = '/note-list';
export const ROUTE_ATTENDEE_LIST = '/attendee-list';
export const ROUTE_PRESENTER_DETAIL = '/presenter-detail/' + ID_PARAM;
export const ROUTE_PRESENTER_FEATURE_DETAIL = `/presenter/${PRESENTER_ID_PARAM}/feature/${FEATURE_ID_PARAM}`;
export const ROUTE_THEME_DETAIL = `/theme-detail/${ID_PARAM}`;
export const ROUTE_THEME_FEATURE_DETAIL = `/theme/${THEME_ID_PARAM}/feature/${FEATURE_ID_PARAM}`;
export const ROUTE_SESSION_FEATURE_DETAIL = `/session/${SESSION_ID_PARAM}/feature/${FEATURE_ID_PARAM}`;
export const ROUTE_MAP = '/map';
export const ROUTE_MY_AGENDA = '/my-agenda';
