import { ApiResponseHandler, AppThunk, LikedFeature, LikedItem, LikedSession, MBDispatchActionWithPayload, Note, Nullable, User, UserPresenterFollowup, UserDsrConsent, FeatureType } from "../../../@types";
import UserLikesService, { LikedFeatureRequest, LikedItemRequest, LikedSessionRequest } from "../../../service/user/userLikesService";
import UserNoteService, { NoteRequest } from "../../../service/user/userNoteService";
import UserPresenterFollowupRequestService from "../../../service/user/userPresenterFollowupRequestService";
import UserDsrConsentService from "../../../service/user/userDsrConsentService";
import UserService from "../../../service/user/userService";
import { PermissionSet } from "../../../constants/PermissionSet";
import {
    cancelPresenterFollowupRequestSuccess,
    createPresenterFollowupRequestSuccess,
    createDsrConsentRequestSuccess,
    initializeEmployee,
    initializeEmployeeFailure,
    initializeEmployeeSuccess,
    loadUserPermissionSetSuccess,
    loadUserPermissionSetFailure,
    updateItemNoteSuccess,
    updateLikedItemSuccess,
    updateLikedPresenterFeatureSuccess,
    updateLikedSessionSuccess,
    updateLikedThemeFeatureSuccess,
    updatePresenterFeatureNoteSuccess,
    updateSessionNoteSuccess,
    updateThemeFeatureNoteSuccess,
    fetchAttendeeSuccess,
    fetchAttendee,
    updateLikedSessionFeatureSuccess,
    updateSessionFeatureNoteSuccess
} from "../../user/userState";
import b2bUserService from "../../../service/user/b2bUserService";
import AttendeeService from "../../../service/attendee/AttendeeService";
import { Attendee } from "../../../@types/attendee";
import { PRESENTER_FEATURE_KEY } from "../../../service/presenter/presenterService";
import { THEME_FEATURE_KEY } from "../../../service/theme/themeService";
import { SESSION_FEATURE_KEY } from "../../../service/session/sessionService";

export function loadUser(token:Nullable<string>):AppThunk {
    return (dispatch, getState) => {
        if (!token) {
            return;
        }

        if(getState().UserState.permissionSets == null) {
            const permissionHandler: ApiResponseHandler<PermissionSet[]> = {
                onSuccess: (permissionSet) => dispatch(loadUserPermissionSetSuccess(permissionSet)),
                onError: (error: string) => dispatch(loadUserPermissionSetFailure()),
            }

            b2bUserService.getUserPermissions(token, permissionHandler)
        }

        const showId = getState().ShowState.selectedShow?.showId;
        if (!showId) {
            return;
        }
        dispatch(initializeEmployee());

        if (!token) {
            dispatch(initializeEmployeeFailure());
        }
        else {
            const handler:ApiResponseHandler<User> = {
                onSuccess : (user) => dispatch(initializeEmployeeSuccess(user)),
                onError : (error:string) => dispatch(initializeEmployeeFailure())
            }

            UserService.getUserDetails(token, showId, handler);
        }
    }
}

export function loadUserAttendee(token:string):AppThunk {
    return (dispatch, getState) => {
        const showId = getState().ShowState.selectedShow?.showId ?? 0;
        
        dispatch(fetchAttendee());

        const handler:ApiResponseHandler<Nullable<Attendee>> = {
            onSuccess : (attendee) => dispatch(fetchAttendeeSuccess(attendee ?? {})),
            onError : () => dispatch(fetchAttendeeSuccess({}))
        };

        AttendeeService.getCurrentUserAttendee(token, showId, handler);
    }
}

export function saveNote(token:Nullable<string>, note:Note, featureType:Nullable<FeatureType>, callbackHandler:ApiResponseHandler<any>):AppThunk {
    return (dispatch, getState) => {
        if (!token) {
            return;
        }

        const showId = getState().ShowState.selectedShow?.showId ?? 0;

        let dispatchSuccessAction:MBDispatchActionWithPayload;
        const removedNote:Note = {};

        if (featureType != null) {
            switch (featureType) {
                case PRESENTER_FEATURE_KEY : 
                    dispatchSuccessAction = updatePresenterFeatureNoteSuccess;

                    removedNote.presenterId = note.presenterId;
                    removedNote.featureId = note.featureId;
                    break;
                case THEME_FEATURE_KEY : 
                    dispatchSuccessAction = updateThemeFeatureNoteSuccess;
                    removedNote.featureId = note.featureId;
                    break;
                case SESSION_FEATURE_KEY :
                    dispatchSuccessAction = updateSessionFeatureNoteSuccess;
                    removedNote.featureId = note.featureId;
                    break;
            }
        }
        else {
            if (!!note.itemNumber) {
                dispatchSuccessAction = updateItemNoteSuccess;
                removedNote.itemNumber = note.itemNumber;
            }
            else if (!!note.sessionId) {
                dispatchSuccessAction = updateSessionNoteSuccess;
                removedNote.sessionId = note.sessionId;
            }
        }

        const handler:ApiResponseHandler<any> = {
            onSuccess : (updatedNote) => {
                const payload = Object.keys(updatedNote).length ? updatedNote: removedNote;
                dispatch(dispatchSuccessAction(payload));
                callbackHandler.onSuccess(null);
            },
            onError : (error:string) => {
                callbackHandler.onError(error);
            },
            onComplete : () => callbackHandler.onComplete?.()
        };

        const request:NoteRequest = {
            accessToken : token,
            note : note,
            showId : showId
        };

        UserNoteService.saveNote(request, featureType, handler);
    }
}

export function updateLikedItem(token:Nullable<string>, likedItem:LikedItem, callbackHandler:ApiResponseHandler<any>):AppThunk {
    return (dispatch, getState) => {
        if (!token) {
            return;
        }

        const showId = getState().ShowState.selectedShow?.showId;
        if (!showId) {
            return;
        }

        const handler:ApiResponseHandler<any> = {
            onSuccess : () => {
                dispatch(updateLikedItemSuccess(likedItem));
                callbackHandler.onSuccess(null);
            },
            onError : (error:string) => {
                callbackHandler.onError(error);
            },
            onComplete: () => callbackHandler.onComplete?.()
        };

        const request:LikedItemRequest = {
            accessToken : token,
            showId : showId,
            likedItem : likedItem
        };

        UserLikesService.saveLikedItem(request, handler);
    }
}

export function updateLikedThemeFeature(token:Nullable<string>, likedFeature:LikedFeature, callbackHandler:ApiResponseHandler<any>):AppThunk {
    return (dispatch, getState) => {
        if (!token) {
            return;
        }

        const showId = getState().ShowState.selectedShow?.showId;
        if (!showId) {
            return;
        }

        const handler:ApiResponseHandler<LikedFeature> = {
            onSuccess : () => {
                dispatch(updateLikedThemeFeatureSuccess(likedFeature));
                callbackHandler.onSuccess(null);
            },
            onError : (error:string) => {
                callbackHandler.onError(error);
            },
            onComplete: () => callbackHandler.onComplete?.()
        }

        const request:LikedFeatureRequest = {
            accessToken : token,
            showId : showId,
            likedFeature : likedFeature
        };

        UserLikesService.saveLikedThemeFeature(request, handler);
    }
}

export function updateLikedPresenterFeature(token:Nullable<string>, likedFeature:LikedFeature, callbacks:ApiResponseHandler<LikedFeature>):AppThunk {
    return (dispatch, getState) => {
        if (!token) {
            return;
        }

        const showId = getState().ShowState.selectedShow?.showId ?? 0;
        if (!showId) {
            return;
        }

        const handler:ApiResponseHandler<LikedFeature> = {
            onSuccess : (response) => {
                dispatch(updateLikedPresenterFeatureSuccess(likedFeature));
                callbacks.onSuccess(response);
            },
            onError : (error) => callbacks.onError(error),
            onComplete : () => callbacks.onComplete?.()
        };

        const request:LikedFeatureRequest = {
            accessToken : token,
            showId : showId,
            likedFeature : likedFeature
        };

        UserLikesService.saveLikedPresenterFeature(request, handler);
    }
}

export function updateLikedSession(token:Nullable<string>, likedSession:LikedSession, callbacks:ApiResponseHandler<LikedSession>):AppThunk {
    return (dispatch, getState) => {
        if (!token) {
            return;
        }

        const showId = getState().ShowState.selectedShow?.showId ?? 0;
        if (!showId) {
            return;
        }

        const handler:ApiResponseHandler<LikedSession> = {
            onSuccess : (response) => {
                dispatch(updateLikedSessionSuccess(likedSession));
                callbacks.onSuccess(response);
            },
            onError : (error) => callbacks.onError(error),
            onComplete : () => callbacks.onComplete?.()
        };

        const request:LikedSessionRequest = {
            accessToken : token,
            showId : showId,
            likedSession : likedSession
        };

        UserLikesService.saveLikedSession(request, handler);
    }
}

export function updateLikedSessionFeature(token:Nullable<string>, likedFeature:LikedFeature, handlerCallback:ApiResponseHandler<LikedFeature>):AppThunk {
    return (dispatch, getState) => {
        if (!token) {
            return;
        }

        const showId = getState().ShowState.selectedShow?.showId ?? 0;
        if (!showId) {
            return;
        }

        const handler:ApiResponseHandler<LikedFeature> = {
            onSuccess : (response) => {
                dispatch(updateLikedSessionFeatureSuccess(likedFeature));
                handlerCallback.onSuccess(response);
            },
            onError : (error) => handlerCallback.onError(error),
            onComplete : () => handlerCallback.onComplete?.()
        };

        const request:LikedFeatureRequest = {
            accessToken : token,
            showId : showId,
            likedFeature : likedFeature
        };

        UserLikesService.saveLikedSessionFeature(request, handler);
    };
}

export function createPresenterFollowupRequest(
    accessToken: Nullable<string>,
    presenterId: number,
    callbacks: ApiResponseHandler<UserPresenterFollowup>
): AppThunk {
    return (dispatch, getState) => {
        if (!accessToken) {
            return;
        }

        const showId = getState().ShowState.selectedShow?.showId ?? 0;
        if (!showId) {
            return;
        }

        const handler: ApiResponseHandler<UserPresenterFollowup> = {
            onSuccess: (response) => {
                dispatch(createPresenterFollowupRequestSuccess({ followupRequest: response }));
                callbacks.onSuccess(response);
            },
            onError: (error) => callbacks.onError(error),
            onComplete: () => callbacks.onComplete?.(),
        };

        UserPresenterFollowupRequestService.createFollowupRequest({ presenterId, showId, accessToken }, handler);
    };
}

export function cancelPresenterFollowupRequest(
    accessToken: Nullable<string>,
    presenterId: number,
    callbacks: ApiResponseHandler<void>
): AppThunk {
    return (dispatch, getState) => {
        if (!accessToken) {
            return;
        }

        const showId = getState().ShowState.selectedShow?.showId ?? 0;
        if (!showId) {
            return;
        }

        const handler: ApiResponseHandler<UserPresenterFollowup> = {
            onSuccess: () => {
                dispatch(cancelPresenterFollowupRequestSuccess({ presenterId }));
                callbacks.onSuccess();
            },
            onError: (error) => callbacks.onError(error),
            onComplete: () => callbacks.onComplete?.(),
        };

        UserPresenterFollowupRequestService.removeFollowupRequest({ presenterId, showId, accessToken }, handler);
    };
}

export function createDsrConsentRequest(
    accessToken: Nullable<string>,
    isShared: boolean,
    callbacks: ApiResponseHandler<UserDsrConsent>
): AppThunk {
    return (dispatch, getState) => {
        if (!accessToken) {
            return;
        }

        const showId = getState().ShowState.selectedShow?.showId ?? 0;
        if (!showId) {
            return;
        }

        const handler: ApiResponseHandler<UserDsrConsent> = {
            onSuccess: (response) => {
                dispatch(createDsrConsentRequestSuccess({ dsrConsent: response }));
                callbacks.onSuccess(response);
            },
            onError: (error) => callbacks.onError(error),
            onComplete: () => callbacks.onComplete?.(),
        };

        UserDsrConsentService.createDsrConsentRequest({ isShared, showId }, accessToken, handler);
    };
}