import { ApiResponse } from '../../@types';
import { NoteRequest } from '../../service/user/userNoteService';
import Api from '../api';

const USER_NOTE_BASE = 'user-note';

class UserNoteApi {
    public async updateItemNote(request:NoteRequest):Promise<ApiResponse> {
        const {accessToken, note, showId} = request;
        const response = await Api.performPOSTRequest(`${USER_NOTE_BASE}/item/${showId}`, note, accessToken);
        return response;
    }

    public async updatePresenterFeatureNote(request:NoteRequest):Promise<ApiResponse> {
        const {accessToken, note, showId} = request;
        const response = await Api.performPOSTRequest(`${USER_NOTE_BASE}/presenter/feature/${showId}`, note, accessToken);
        return response;
    }

    public async updateThemeFeatureNote(request:NoteRequest):Promise<ApiResponse> {
        const {accessToken, note, showId} = request;
        const response = await Api.performPOSTRequest(`${USER_NOTE_BASE}/theme/feature/${showId}`, note, accessToken);
        return response;
    }

    public async updateSessionNote(request:NoteRequest):Promise<ApiResponse> {
        const {accessToken, note, showId} = request;
        const response = await Api.performPOSTRequest(`${USER_NOTE_BASE}/session/${showId}`, note, accessToken);
        return response;
    }

    async updateSessionFeatureNote(request:NoteRequest):Promise<ApiResponse> {
        const {accessToken, note, showId} = request;
        const response = await Api.performPOSTRequest(`${USER_NOTE_BASE}/session/${showId}/feature`, note, accessToken);
        return response;
    }
}

export default new UserNoteApi();