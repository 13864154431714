import { ApiTokenResponse, CookieDao } from '@martinbros/component';
import { Nullable } from '../../@types';
import { getConfiguration } from '../../config/configuration';

const { cookieDomain, environmentId, isLocal } = getConfiguration();
const cookieDao = new CookieDao({ isLocal, environmentId, cookieDomain });

class CookieService {
    public getUserTokens(): Nullable<ApiTokenResponse> {
        return cookieDao.retrieveLastUserTokens();
    }

    public saveAccessToken(accessToken: string): void {
        cookieDao.persistUserAccessToken(accessToken);
    }
}

export default new CookieService();
