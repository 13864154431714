import React from 'react';
import './style/styles.scss';
import { Provider } from 'react-redux';
import Main from './container/main';
import AppStore from './state/appStore';
export const APP_ROOT_TITLE = 'Martin Bros.';

const App = () => 
  <Provider store={AppStore}>
    <Main />
  </Provider>

export default App;