import React from "react";
import { Container, Col, Row} from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { useAppSelector } from "../hook/appHook";
import { PRESENTER_FEATURE_KEY } from "../service/presenter/presenterService";
import PageHeader from "../component/app/pageHeader";
import FeatureDetail from "../component/feature/featureDetail";

const PresenterFeatureDetail = () => {
    const {presenterId, featureId} = useParams();
    const actualPresenterId = parseInt(presenterId ?? '0');
    const featId = parseInt(featureId ?? '0');

    const {presenters} = useAppSelector((state) => state.PresenterState);
    const presenter = presenters.find(presenter => presenter.presenterId === actualPresenterId);
    const feature = presenter?.features.find(feature => feature.featureId === featId);

    if (!presenter || !feature) {
        return (
            <Container fluid>
                <Row>
                    <Col className="text-center">Presenter or feature could not be found</Col>
                </Row>
            </Container>
        );
    }

    return (
        <div>
            <PageHeader link={`/presenter-detail/${actualPresenterId}`} title={`Presenter Feature for ${feature.title}`} />
            <FeatureDetail feature={feature} featureType={PRESENTER_FEATURE_KEY} />
        </div>
    );
};

export default PresenterFeatureDetail;