import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Theme } from "../@types";
import { APP_ROOT_TITLE } from "../App";
import Loader from "../component/app/loader";
import DsrAlertModal from '../component/app/DsrAlertModal';
import { useAppDispatch, useAppSelector } from "../hook/appHook";
import GroupedThemeList from "../component/theme/groupedThemeList";
import { useNavigate } from "react-router-dom";
import { clearRedirectPath } from "../state/action/appAction/appAction";

const ThemeListContainer:React.FC<any> = () => {
    const {isLoading, themes} = useAppSelector((state) => state.ThemeState);
    const redirectPath = useAppSelector((state) => state.AppState.redirectPath);

    const [filteredThemes, setFilteredThemes] = useState<Theme[]>([]);
    const [allThemes, setAllThemes] = useState<Theme[]>([]);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        document.title = `${APP_ROOT_TITLE} - Themes`;

        if (redirectPath?.length) {
            navigate(redirectPath);
            dispatch(clearRedirectPath());
            return;
        }

        if (!!themes.length && !allThemes.length) {
            setAllThemes(themes);
            setFilteredThemes(themes);
            return;
        }
    }, [themes, allThemes, redirectPath, navigate, dispatch]);

    const onSearchChanged = (event:React.ChangeEvent<HTMLInputElement>) => {
        const search:string = event.currentTarget.value.trim().toLowerCase();
        if (search.length === 0) {
            setFilteredThemes(allThemes);
            return;
        }

        if (search.length < 1) {
            return;
        }

        const matchedThemes = allThemes.filter((theme) => 
            (theme.name.toLowerCase().indexOf(search) > -1)
            || (theme.description.toLowerCase().indexOf(search) > -1));

        setFilteredThemes(matchedThemes);        
    }

    return (
        <div>
            {isLoading && <Loader />}
            <Container fluid className="gx-5">
                <Row className="border-bottom gx-0 pb-2 pb-md-0 mb-3">
                    <Col>
                        <h2>Themes</h2>
                    </Col>
                    <Col md="auto" className="justify-content-end">
                        <Form.Control type="text" onChange={onSearchChanged} placeholder="Search themes..." id="themeListSearch" />
                    </Col>
                </Row>

                {!!filteredThemes.length && <GroupedThemeList themes={filteredThemes} />}
            </Container>
            <DsrAlertModal/>
        </div>
    )
}

export default ThemeListContainer; 