import React from 'react';
import { Link } from 'react-router-dom';
import { Presenter } from '../../@types';

interface PresentedByLinkProps {
    presenter: Presenter;
    searchTerm?: string;
}

const PresentedByLink: React.FC<PresentedByLinkProps> = ({ presenter, searchTerm = '' }) => {
    return (
        <Link className="item-presenter-link" to={`/presenter-detail/${presenter.presenterId}/${searchTerm}`}>
            <span className="d-inline-block">{presenter.name}</span>
            {!!presenter.boothAssignment && (
                <span className="d-inline-block ps-1">- Booth: {presenter.boothAssignment}</span>
            )}
        </Link>
    );
};

export default PresentedByLink;
