import { createSlice } from '@reduxjs/toolkit';
import { AppState, AppUser, CognitoUserTokens, Nullable } from '../../@types';

const INITIAL_STATE:AppState = {
    isLoading: false,
    isLoginFailed : false,
    isInitialized : false,
    appUser : null,
    redirectPath : null,
    isRefreshing: false,
};

const appSlice = createSlice({
    name: 'app',
    initialState: INITIAL_STATE,
    reducers: {
        initializeApp: (state) => {
            state.isLoading = true;
        },
        initializeAppSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.isInitialized = true;
            state.appUser = (payload.appUser ?? null);
            state.isLoginFailed = !!payload.isLoginFailed;
        },
        setRedirectPath:(state, {payload}) => {
            state.redirectPath = payload;
        },
        removeRedirectPath:(state) => {
            state.redirectPath = null;
        },
        accessTokenRefresh: (state) => {
            state.isRefreshing = true;
        },
        accessTokenRefreshSuccess: (state, {payload}) => {
            let user:Nullable<AppUser> = null;
            if (state.appUser) {
                const tokens:CognitoUserTokens = {
                    ...state.appUser?.tokens,
                    accessToken : payload
                };

                user = {
                    ...state.appUser,
                    tokens : tokens
                };
            }

            state.appUser = user
            state.isRefreshing = false;
        },
        userLogout: (state) => {
            state.isLoading = true;
        },
        userLogoutSuccess: (state) => {
            state.isLoading = false;
            state.isLoginFailed = true;
            state.appUser = null;
        }
    }
})

export const {
    initializeApp,
    initializeAppSuccess, 
    setRedirectPath,
    removeRedirectPath,
    accessTokenRefresh,
    accessTokenRefreshSuccess, 
    userLogout, 
    userLogoutSuccess} = appSlice.actions;
export default appSlice.reducer;