import { Nullable } from "../@types";

export function parseErrorMessage(error:unknown):Nullable<string> {
    if (error == null) {
        return null;
    }

    if (typeof error === 'string') {
        return error as string;
    }

    if (typeof error === 'object') { 
        const errorObject = error as Error;
        return errorObject.message;
    }

    return 'An unknown error occured';
}