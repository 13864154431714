import { ApiResponseHandler, Show } from "../../@types";
import ShowApi from "../../api/show/showApi";
import { handleApiResponse } from "../../util/apiUtil";

class ShowService {
    public async getShowList(token:string, handler:ApiResponseHandler<Show[]>):Promise<void> {
        const response = await ShowApi.fetchShowList(token);
        handleApiResponse(response, handler);  
    }

    async selectShow(showId:number, token:string, handler:ApiResponseHandler<Show>):Promise<void> {
        const response = await ShowApi.selectShow(showId, token);
        handleApiResponse(response, handler);
    }
}

export default new ShowService();