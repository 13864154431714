import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { SessionWithStart } from '../../@types/session';
import { ID_PARAM, ROUTE_SESSION_DETAIL } from '../../config/routes';
import { Size } from '../../util/bootstrapUtil';
import SessionAttendanceIcon from './SessionAttendanceIcon';

interface UserSessionSummaryProps {
    sessionWithStart: SessionWithStart;
}

const UserSessionSummary: React.FC<UserSessionSummaryProps> = ({ sessionWithStart }) => {
    const navigate = useNavigate();
    const { attendanceStatus, startLabel, session } = sessionWithStart;

    const handleClick = () => {
        navigate(ROUTE_SESSION_DETAIL.replace(ID_PARAM, String(session.sessionId)));
    };

    return (
        <div className="user-session-summary-container">
            <Row className="bg-charcoal-100 p-2 border-light border rounded fw-700 d-block d-md-flex" onClick={handleClick} role="button">
                <Col sm={1}>
                    <SessionAttendanceIcon
                            attendance={attendanceStatus}
                            color="text-blue"
                            size={Size.LARGE} />
                </Col>
                <Col className="p-0" sm={11} md={6}>
                    <div className="me-2">
                        <div>
                            {startLabel} | {session.name}
                        </div>
                        <div>
                            {!!session.location && <span>Location: {session.location}</span>}
                        </div>
                    </div>
                </Col>
                <Col className="p-0 user-session-summary-ceu-type text-nowrap" sm={1} md={2}>
                    CEU: {session.ceuType}
                </Col>
                <Col className="p-0 user-session-summary-session-type text-nowrap" sm={1} md={2}>
                    {session.sessionType}
                </Col>
                <Col className="p-0 user-session-summary-duration text-nowrap" sm={1} md={1}>
                    {session.duration} min
                </Col>
            </Row>
        </div>
    );
};

export default UserSessionSummary;
