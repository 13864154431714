import { ApiResponseHandler, Theme } from "../../@types"
import { Session } from "../../@types/session";
import { REQUIRED_TOKEN_MESSAGE } from "../../api/api";
import ThemeApi from "../../api/theme/themeApi";
import { handleApiResponse } from "../../util/apiUtil"

export const THEME_FEATURE_KEY = 'theme';

class ThemeService {
    public async getThemeList(token:string, showId:number, handler:ApiResponseHandler<Theme[]>):Promise<void> {
        const response = await ThemeApi.fetchThemeList(token, showId);
        handleApiResponse(response, handler);
    }

    async getThemeSessions(token:string, showId:number, themeId:number, handler:ApiResponseHandler<Session[]>):Promise<void> {
        if (!token || !themeId || !showId) {
            handleApiResponse({data: [], error: REQUIRED_TOKEN_MESSAGE}, handler);
            return;
        }

        const response = await ThemeApi.fetchThemeSessions(token, showId, themeId);
        handleApiResponse(response, handler);
    }
}

export default new ThemeService();