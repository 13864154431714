import React, { useState } from "react";
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { ApiResponseHandler, LikedSession, Nullable } from "../../@types";
import { Session } from "../../@types/session";
import { useAccessToken } from "../../hook/accessTokenHook";
import { useAppDispatch, useAppSelector } from "../../hook/appHook";
import { AttendanceStatus } from "../../service/session/sessionService";
import { updateLikedSession } from "../../state/action/user/userAction";
import IdentityDisplay from "../app/identityDisplay";
import LikeComponent from "../like/likeComponent";
import NoteEditor, { NoteEditorProps } from "../note/noteEditor";
import MBToast from "../toast/mbToast";
import SessionAttendanceIcon from "./SessionAttendanceIcon";
import SessionCeuIcon from "./SessionCeuIcon";
import { Size } from "../../util/bootstrapUtil";

export interface SessionProps {
    startLabel?:string,
    time:number,
    remainingSeats?:number,
    attendanceStatus?:Nullable<AttendanceStatus>
    session: Session
};

const SessionSummary:React.FC<SessionProps> = ({startLabel, attendanceStatus, session, time, remainingSeats}) => {
    const accessToken = useAccessToken();
    const dispatch = useAppDispatch();

    const [isShowNoteEditor, setIsShowNoteEditor] = useState(false);
    const [error, setError] = useState<Nullable<string>>(null);
    const [isLikeLoading, setIsLikeLoading] = useState(false);
    const {notes, likes} = useAppSelector((state) => state.UserState);
    const isShowActive = useAppSelector((state) => state.ShowState.selectedShow?.isActive ?? false);
    
    const sessionNotes = notes.sessionNotes;
    const likedSessions = likes.likedSessions;
    const sessionNote = sessionNotes?.find((note) => note.sessionId === session.sessionId);

    const noteContent = sessionNote ?? {};
    const noteEditorModalProps:NoteEditorProps = {
        title: "Session Note",
        note: {
            ...noteContent,
            sessionId: session.sessionId
        },
        session: session,
        onModalClose: () => setIsShowNoteEditor(false)
    }

    const isLiked:Nullable<boolean> = likedSessions?.find((likedSession) => 
    likedSession.sessionId === session.sessionId)?.isLiked ?? null;

    const onLikeChanged = (isLike:Nullable<boolean>) => {
        const likedSession:LikedSession = {
            sessionId : session.sessionId,
            isLiked : isLike
        };

        const handler:ApiResponseHandler<LikedSession> = {
            onSuccess : (response:LikedSession) => {},
            onError : (error:string) => setError(error),
            onComplete : () => setIsLikeLoading(false)
        };

        setIsLikeLoading(true);
        dispatch(updateLikedSession(accessToken, likedSession, handler));
    }

    const attendanceIcon = (attendanceStatus == null) 
        ? null
        : <SessionAttendanceIcon attendance={attendanceStatus}
                color="text-primary"
                size={Size.EXTRA_LARGE}
                className="ms-2" />

    return (
        <Col className="d-flex session-summary-container">
            <Card className="d-flex1">
                <Row className="g-0 h-100">
                    <Col md="auto" sm={12} className="card-image-container d-none d-sm-flex">
                        <div className="logo-container">
                            <IdentityDisplay
                                entityId={session.sessionId}
                                name={session.name}
                                logoUrl={session.thumbnailUrl}
                                iconColor={session.color}
                                size="lg" />
                        </div>
                    </Col>
                    <Col sm>
                        <Card.Body className="p-0">
                            <Link to={`/session-detail/${session.sessionId}/slot/${time}`} className="card-link h-100">
                                <Card.Header as="h4" className="d-flex align-items-center">
                                    <span className="me-auto presenter-summary-name">
                                        {`${startLabel ?? ''} ${session.name}`}
                                    </span>
                                    <span className="d-flex">
                                        {attendanceIcon}
                                        <SessionCeuIcon 
                                            ceuType={session.ceuType} 
                                            color='text-primary'
                                            size={Size.EXTRA_LARGE}
                                            className="ms-2" />
                                    </span>
                                </Card.Header>
                            </Link>
                            <Row className="p-1">
                                <Col xs={1} className="d-sm-none card-image-container-mobile w-auto">
                                    <IdentityDisplay
                                        entityId={session.sessionId}
                                        name={session.name}
                                        logoUrl={session.thumbnailUrl}
                                        iconColor={session.color}
                                        size="sm" />
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            {session.sessionType}
                                        </Col>
                                        <Col>
                                            {session.duration} minutes
                                        </Col>
                                        <Col>
                                            {session.location}
                                        </Col>
                                    </Row>
                                    {(remainingSeats != null) &&
                                    <Row className="mt-1">
                                        <Col className={`${(!remainingSeats ? 'text-red' : '')} fw-600`}>
                                            {remainingSeats} Seats Remaining
                                        </Col>
                                    </Row>
                                    }
                                    <Row>
                                        <Col>
                                            <div className="mt-1">
                                                {session.shortDescription}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                {isShowActive &&
                                <Col xs={12} md={3}>
                                    <Row className="justify-content-end">
                                        <Col className="w-100 d-md-block d-sm-flex justify-content-end text-end" style={{minWidth: 110}}>
                                            <div className="d-inline-block d-md-block">
                                                <LikeComponent isLiked={isLiked} onLikeChanged={onLikeChanged} isLikeLoading={isLikeLoading} />
                                            </div>
                                            <div className="d-inline-block d-md-block">
                                                <Button className="mb-button item-note-button mx-3" title="Update Note" variant="" size="sm" onClick={() => setIsShowNoteEditor(true)}>Note</Button>
                                            </div>
                                            {isShowNoteEditor &&
                                                <NoteEditor {...noteEditorModalProps} />
                                            }
                                        </Col>
                                    </Row>   
                                </Col>
                                }
                            </Row>
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
            {!!error &&
                <MBToast mbToastProps={{ title: 'Failed to save like setting', message: error }} />
            }
        </Col>
    )
};

export default SessionSummary;