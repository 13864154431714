import { ApiResponse, ApiResponseHandler } from "../../@types";
import { 
    Session, 
    SessionByDate, 
    SessionByDateRequest, 
    SessionFeature, 
    SessionItemRequest, 
    SessionPresenterRequest, 
    SessionTimeSlot, 
    SessionTimeSlotChangeRequest, 
    SessionUserAttendanceRequest, 
    SessionUserPresenterSharing, 
    SessionUserRegistrationRequest, 
    TimeSlotUser
} from "../../@types/session";
import { REQUIRED_TOKEN_MESSAGE } from "../../api/api";
import SessionApi from "../../api/session/sessionApi";
import { handleApiResponse } from "../../util/apiUtil";

export enum AttendanceStatus {
    SCHEDULED = 'scheduled',
    ATTENDED = 'attended'
}

export enum CeuType {
    NONE = 'None',
    GENERAL = 'General',
    SANITATION = 'Sanitation',
    EXHIBIT = "Exhibit"
}

export enum SessionType {
    CHEF_DEMO = 'Chef Demo',
    SELF_GUIDED = 'Self-Guided',
    TRAINING = 'Training',
    SEMINAR = 'Seminar',
    WORKSHOP = 'Workshop'
}

export enum SessionGroup {
    EXPERIENCE = 'experience',
    SESSION = 'session'
}

export const SESSION_FEATURE_KEY = 'session';

class SessionService {
    public async getSessionList(token:string, showId:number, isFeatureIncluded:boolean, handler:ApiResponseHandler<Session[]>):Promise<void> {
        if (!token) {
            const error: ApiResponse = { data: null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }
        
        const response = await SessionApi.fetchSessionList(token, showId, isFeatureIncluded);
        handleApiResponse(response, handler);  
    }

    public async getSession(token:string, showId:number, sessionId:number, handler:ApiResponseHandler<Session>):Promise<void> {
        if (!token) {
            const error: ApiResponse = { data: null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }
        
        const response = await SessionApi.fetchSession(token, showId, sessionId);
        handleApiResponse(response, handler);  
    }

    async getSessionsByDate(token:string, request:SessionByDateRequest, handler:ApiResponseHandler<SessionByDate[]>):Promise<void> {
        if (!token) {
            const error:ApiResponse = {
                data : [],
                error : REQUIRED_TOKEN_MESSAGE
            };

            handleApiResponse(error, handler);
            return;
        }

        const response = await SessionApi.fetchSessionsByDate(token, request);
        handleApiResponse(response, handler);
    }

    async getSessionFeatures(token:string, showId:number, sessionId:number, handler:ApiResponseHandler<SessionFeature[]>):Promise<void> {
        if (!showId || !sessionId) {
            handleApiResponse({data:[], error: null}, handler);
            return;
        }

        const response = await SessionApi.fetchSessionFeatures(token, showId, sessionId);
        handleApiResponse(response, handler);
    }
    
    async getSessionFeatureById(token:string, showId:number, featureId:number, handler:ApiResponseHandler<SessionFeature>):Promise<void> {
        const response = await SessionApi.fetchSessionFeatureById(token, showId, featureId);
        return handleApiResponse(response, handler);
    }

    public async saveSession(token:string, showId: number, session:Session, handler:ApiResponseHandler<Session>):Promise<void> {
        if (!token) {
            const error: ApiResponse = { data: null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const response = await SessionApi.saveSession(token, showId, session);
        handleApiResponse(response, handler);  
    }

    public async saveSessionItem(request:SessionItemRequest, handler:ApiResponseHandler<string[]>):Promise<void> {
        if (!request || !request.accessToken || !request.showId) {
            const error: ApiResponse = { data: null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const response = await SessionApi.saveSessionItem(request);
        handleApiResponse(response, handler);  
    }

    public async saveSessionPresenter(request:SessionPresenterRequest, handler:ApiResponseHandler<number[]>):Promise<void> {
        if (!request || !request.accessToken || !request.showId) {
            const error: ApiResponse = { data: null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const response = await SessionApi.saveSessionPresenter(request);
        handleApiResponse(response, handler);  
    }

    public async saveSessionTimeSlot(request:SessionTimeSlotChangeRequest, handler:ApiResponseHandler<SessionTimeSlot[]>):Promise<void> {
        if (!request || !request.accessToken || !request.showId) {
            const error: ApiResponse = { data: null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const response = await SessionApi.saveSessionTimeSlot(request);
        handleApiResponse(response, handler);  
    }

    public async saveSessionUserRegistration(request:SessionUserRegistrationRequest, handler:ApiResponseHandler<TimeSlotUser[]>):Promise<void> {
        if (!request || !request.accessToken || !request.showId) {
            const error: ApiResponse = { data: null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const response = await SessionApi.saveSessionUserRegistration(request);
        handleApiResponse(response, handler);  
    }

    public async saveSessionUserPresenterSharing(request:SessionUserPresenterSharing, handler:ApiResponseHandler<TimeSlotUser[]>):Promise<void> {
        if (!request || !request.accessToken || !request.showId) {
            const error: ApiResponse = { data: null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const response = await SessionApi.saveSessionUserPresenterSharing(request);
        handleApiResponse(response, handler);  
    }

    public async saveSessionUserAttendance(request:SessionUserAttendanceRequest, handler:ApiResponseHandler<TimeSlotUser[]>):Promise<void> {
        if (!request || !request.accessToken || !request.showId) {
            const error: ApiResponse = { data: null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const response = await SessionApi.saveSessionUserAttendance(request);
        handleApiResponse(response, handler);  
    }

    public async deleteSession(token:string, showId: number, sessionId:number, handler:ApiResponseHandler<Session>):Promise<void> {
        if (!token || !sessionId || !showId) {
            const error: ApiResponse = { data: null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const response = await SessionApi.deleteSession(token, showId, sessionId);
        handleApiResponse(response, handler);  
    }

    public async deleteSessionItem(request:SessionItemRequest, handler:ApiResponseHandler<string[]>):Promise<void> {
        if (!request || !request.accessToken || !request.showId) {
            const error: ApiResponse = { data: null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const response = await SessionApi.deleteSessionItem(request);
        handleApiResponse(response, handler);  
    }

    public async deleteSessionPresenter(request:SessionPresenterRequest, handler:ApiResponseHandler<number[]>):Promise<void> {
        if (!request || !request.accessToken || !request.showId) {
            const error: ApiResponse = { data: null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const response = await SessionApi.deleteSessionPresenter(request);
        handleApiResponse(response, handler);  
    }

    public async deleteSessionTimeSlot(request:SessionTimeSlotChangeRequest, handler:ApiResponseHandler<SessionTimeSlot[]>):Promise<void> {
        if (!request || !request.accessToken || !request.showId) {
            const error: ApiResponse = { data: null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const response = await SessionApi.deleteSessionTimeSlot(request);
        handleApiResponse(response, handler);  
    }

    async deleteCurrentUserSessionRegistration(request:SessionUserRegistrationRequest, handler:ApiResponseHandler<TimeSlotUser[]>):Promise<void> {
        if (!request || !request.accessToken || !request.showId) {
            const error: ApiResponse = { data: null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const response = await SessionApi.deleteSessionUserRegistration(request);
        handleApiResponse(response, handler);  
    }

    public async deleteSessionUserRegistration(request:SessionUserRegistrationRequest, handler:ApiResponseHandler<TimeSlotUser[]>):Promise<void> {
        if (!request || !request.accessToken || !request.showId) {
            const error: ApiResponse = { data: null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        if (!request.email) {
            handleApiResponse({data: null, error: 'An email address is required'}, handler);
            return;
        }

        const response = await SessionApi.deleteSessionUserRegistration(request);
        handleApiResponse(response, handler);  
    }
}

export default new SessionService();