import { ApiResponse, Nullable } from '../../@types';
import { AttendeeCheckInRequest, AttendeeQueryParams } from '../../@types/attendee';
import Api from '../api';

const ATTENDEE_BASE = 'attendees/';

class AttendeeApi {
    async fetchCurrentUserAttendee(token:string, showId:number):Promise<ApiResponse> {
        return await Api.performAwsGETRequest(`attendee/current/${showId}`, token);
    }

    async fetchAgenda(token:string, showId:number, emailAddress:Nullable<string>):Promise<ApiResponse> {
        const param = !!emailAddress 
            ? `?email=${encodeURIComponent(emailAddress)}`
            : '';
        
        return await Api.performAwsGETRequest(`attendee/agenda/${showId}${param}`, token);
    }

    public async fetchAttendees(
        accessToken: string,
        showId: number,
        params: AttendeeQueryParams
    ): Promise<ApiResponse> {
        const url = `${ATTENDEE_BASE}${showId}`;
        return Api.performAwsGETRequest(url, accessToken, params);
    }

    public async putAttendeeCheckIn(accessToken: string, request: AttendeeCheckInRequest): Promise<ApiResponse> {
        const url = `${ATTENDEE_BASE}check-in`;
        return Api.performPUTRequest(url, request, accessToken);
    }
}

export default new AttendeeApi();
