import React from 'react';
import { Carousel, Row } from 'react-bootstrap';
import { Feature, FeatureType } from '../../@types';
import FeatureComponent from './featureComponent';

export interface FeatureListProps {
    features: Feature[],
    featureType : FeatureType,
    entityId: number
}

const FeatureList:React.FC<FeatureListProps> = ({features, featureType, entityId}) => {
    const featureList = features.map((feature, index) => 
        <FeatureComponent key={`${featureType}-feature-${index}`} feature={feature} featureType={featureType} entityId={entityId} />
    );

    const responsiveFeatureList = features.map((feature, index) =>
        <Carousel.Item key={feature.featureId}>
            <FeatureComponent key={`${featureType}-feature-${index}`} feature={feature} featureType={featureType} entityId={entityId} />
        </Carousel.Item>
    );

    return (
        <div>
            <Row lg={2} xxl={3} className="mb-3 g-4 d-none d-lg-flex" id="featureList">
                {featureList}
            </Row>

            <Carousel variant="dark" interval={null} bsPrefix="mb-carousel" className="d-flex d-lg-none position-relative">
                {responsiveFeatureList}
            </Carousel>
        </div>
    );
}

export default FeatureList;