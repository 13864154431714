import { useEffect, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { PermissionSet } from '../constants/PermissionSet';
import { AppDispatch } from '../state/appStore';
import { RootState } from '../state/reducer';

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useIsEmployeeUser = (): boolean => {
    const { appUser } = useAppSelector((state) => state.AppState);

    return appUser?.userType === 'employee' ?? false;
};

export const useDebounce = <T>(value: T, delay: number): T => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const timer = setTimeout(() => setDebouncedValue(value), delay);

        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);

    return debouncedValue;
};

export const useIsPermissionAuthorized = (permission: PermissionSet): boolean => {
    const { permissionSets } = useAppSelector((state) => state.UserState);

    return permissionSets?.includes(permission) ?? false;
};
