import React, { useEffect, useState } from 'react';
import { Nullable } from '../../@types';
import {google, outlook, office365, yahoo, ics, CalendarEvent} from 'calendar-link';
import MBIcon from '../app/mbIcon';
import MBButtonLink from '../app/input/mbButtonLink';
import UserService from '../../service/user/userService';
import MBModal from '../modal/mbModal';
import { Size } from '../../util/bootstrapUtil';
import MBLabelGroup from '../app/mbLabelGroup';
import { Col, Form, Row } from 'react-bootstrap';

export interface TimeSlotCalendarEventLinkProps {
    time:Date,
    sessionName:string,
    sessionDescription:string,
    duration:number,
    sesionLocation:Nullable<string>
}

interface EmailClient {
    id:string,
    name:string
}

const GOOGLE_CLIENT:EmailClient = {id: 'google', name: 'Google'};
const YAHOO_CLIENT:EmailClient = {id: 'yahoo', name: 'Yahoo'};
const ICAL_CLIENT:EmailClient = {id: 'ics', name: 'iCal'};
const OUTLOOK_CLIENT:EmailClient = {id: 'outlook', name: 'Outlook'};
const O365_CLIENT:EmailClient = {id: 'office365', name: 'Office365'};

const EMAIL_CLIENTS:EmailClient[] = [
    GOOGLE_CLIENT, YAHOO_CLIENT, ICAL_CLIENT, OUTLOOK_CLIENT, O365_CLIENT
];

function getClientById(id:string):Nullable<EmailClient> {
    return EMAIL_CLIENTS.find((client) => client.id === id);
}

const TimeSlotCalendarEventLink:React.FC<TimeSlotCalendarEventLinkProps> = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [clientPreferenceOption, setClientPreferenceOption] = useState(O365_CLIENT.id);
    const [selectedClient, setSelectedClient] = useState(O365_CLIENT);

    useEffect(() => {
        const clientPreference = UserService.getEmailClientPreference();
        if (!!clientPreference) {
            const selectedClient = getClientById(clientPreference);
            if (selectedClient) {
                setSelectedClient(selectedClient);
                setClientPreferenceOption(selectedClient.id);
            }
        }
    }, []);

    const calendarEvent:CalendarEvent = {
        title: props.sessionName,
        description : props.sessionDescription,
        location : props.sesionLocation ?? '',
        duration : [props.duration, 'minute'],
        start: props.time
    };     

    let linkText = '';
    switch (selectedClient.id) {
        case GOOGLE_CLIENT.id :
            linkText = google(calendarEvent);
            break; 
        case YAHOO_CLIENT.id : 
            linkText = yahoo(calendarEvent);
            break;
        case ICAL_CLIENT.id : 
            linkText = ics(calendarEvent);
            break;
        case OUTLOOK_CLIENT.id : 
            linkText = outlook(calendarEvent);
            break;
        case O365_CLIENT.id :
            linkText = office365(calendarEvent);
    }

    function saveClientPreference():Promise<boolean> {
        const client = getClientById(clientPreferenceOption);
        if (!client) {
            return Promise.resolve(false);
        }

        UserService.saveEmailClientPreference(clientPreferenceOption);
        setSelectedClient(client);
        return Promise.resolve(true);
    }

    return (
        <span>
            <span className="text-nowrap">
                <a href={linkText} className="me-1" 
                        target="_blank"
                        rel="noreferrer">
                    Add to Calendar ({selectedClient.name})
                </a>
                <MBButtonLink onClick={() => setIsModalOpen(true)}>
                    <MBIcon icon="settings" />
                </MBButtonLink>
            </span>
            {isModalOpen &&
            <MBModal show={true}
                    onHide={() => setIsModalOpen(false)}
                    mbModalProps={{
                        title : "Calendar Preference",
                        size: Size.SMALL,
                        actionBtnText : 'Save',
                        onModalSubmit: saveClientPreference,
                        onModalCancel: () => Promise.resolve(true)}}>
                <Row>
                    <Col>
                        <MBLabelGroup id={'clientEmailPreference'} 
                                label={'Calendar Tool'} >
                            <Form.Select
                                    value={clientPreferenceOption}
                                    onChange={(event) => setClientPreferenceOption(event.target.value)}>
                                {EMAIL_CLIENTS.map((client) => 
                                <option key={`emailClientPreference-${client.id}`} value={client.id}>
                                    {client.name}
                                </option>
                                )}
                            </Form.Select>
                        </MBLabelGroup>
                    </Col>
                </Row>
            </MBModal>
            }
        </span>
    );
};

export default TimeSlotCalendarEventLink;