import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as AppRoutes from '../config/routes';
import Header from '../component/header';
import Footer from '../component/footer';
import ThemeListContainer from './themeListContainer';
import ShowListContainer from './showListContainer';
import PresenterListContainer from './presenterListContainer';
import ItemListContainer from './itemListContainer';
import NoteListContainer from './noteListContainer';
import ThemeFeatureDetail from './themeFeatureDetailContainer';
import PresenterFeatureDetail from './presenterFeatureDetailContainer';
import PresenterDetail from '../component/presenter/presenterDetail';
import { loadUser, loadUserAttendee } from '../state/action/user/userAction';
import { useAppDispatch, useAppSelector } from '../hook/appHook';
import { useAccessToken } from '../hook/accessTokenHook';
import { APP_ROOT_TITLE } from '../App';
import HeaderEvents from '../component/header_events';
import { initializeShowAction } from '../state/action/show/showAction';
import ThemeDetail from '../component/theme/themeDetail';
import MapDetail from '../component/map/mapDetail';
import ShowLoading from './showLoading';
import ScrollToTop from '../component/app/scrollToTop';
import SessionListContainer from './sessionListContainer';
import AttendeeListContainer from './AttendeeListContainer';
import SessionFeatureContainer from './sessionFeatureContainer';
import MyAgendaContainer from './MyAgendaContainer';
import SessionDetailContainer from './sessionDetailContainer';

const Home = () => {
    document.title = `${APP_ROOT_TITLE}`;
    
    const dispatch = useAppDispatch();
    const token = useAccessToken();
    const {ShowState, UserState, ThemeState, PresenterState} = useAppSelector((state) => state);

    useEffect(() => {
        document.title = `${APP_ROOT_TITLE}`;
        if (token 
                && !UserState.isLoading 
                && !UserState.isInitialized) {
            dispatch(loadUser(token));
        }

        if (token 
                && ShowState.selectedShow?.showId 
                && !ShowState.isLoading 
                && !ShowState.isInitialized) {
            dispatch(initializeShowAction(token));
        }

        if (token 
                && !ShowState.isLoading
                && !!ShowState.selectedShow?.showId
                && UserState.isInitialized
                && !UserState.isLoading
                && (UserState.attendee == null)) {
            dispatch(loadUserAttendee(token));
        }

    }, [dispatch, token, ShowState, UserState]);

    if (!ShowState.selectedShow?.showId) {
        return(
            <BrowserRouter>
                <HeaderEvents />
                <div className="container-body-events">
                    <Routes>
                        <Route path="*" element={<ShowListContainer isNavigateOnChange={false} />} />
                    </Routes>
                </div>
            </BrowserRouter>
        );
    }

    if (PresenterState.isLoading || ThemeState.isLoading) {
        return (
            <BrowserRouter>
                <Header />
                <ShowLoading />
            </BrowserRouter>
        );
    }

    const defaultRoute = ThemeState.themes.length 
        ? <Route path="*" element={<ThemeListContainer />} />
        : <Route path="*" element={<PresenterListContainer />} />;

    return (
        <BrowserRouter>
            <Header />
            <div className="container-body">
                <Routes>
                    <Route path={AppRoutes.ROUTE_SHOW_LIST} element={<ShowListContainer isNavigateOnChange={true} />} />
                    <Route path={AppRoutes.ROUTE_SESSION_LIST} element={<SessionListContainer />}>
                        <Route path={AppRoutes.ROUTE_SESSION_LIST_PARAM} element={<SessionListContainer />} />
                    </Route>
                    <Route path={AppRoutes.ROUTE_SESSION_DETAIL} element={<SessionDetailContainer />}>
                        <Route path={AppRoutes.ROUTE_SESSION_DETAIL_SEARCH} element={<SessionDetailContainer />} />
                        <Route path={AppRoutes.ROUTE_SESSION_DETAIL_SLOT} element={<SessionDetailContainer />} />
                    </Route>
                    <Route path={AppRoutes.ROUTE_PRESENTER_LIST} element={<PresenterListContainer />} />
                    <Route path={AppRoutes.ROUTE_THEME_LIST} element={<ThemeListContainer />} />
                    <Route path={AppRoutes.ROUTE_ITEM_LIST} element={<ItemListContainer />}>
                        <Route path={AppRoutes.SEARCH_PARAM} element={<ItemListContainer />} />
                    </Route>
                    <Route path={AppRoutes.ROUTE_NOTE_LIST} element={<NoteListContainer />} />
                    <Route path={AppRoutes.ROUTE_ATTENDEE_LIST} element={<AttendeeListContainer />} />
                    <Route path={AppRoutes.ROUTE_PRESENTER_DETAIL} element={<PresenterDetail />}>
                        <Route path={AppRoutes.SEARCH_PARAM} element={<PresenterDetail />} />
                    </Route>
                    <Route path={AppRoutes.ROUTE_SESSION_FEATURE_DETAIL} element={<SessionFeatureContainer />} />
                    <Route path={AppRoutes.ROUTE_PRESENTER_FEATURE_DETAIL} element={<PresenterFeatureDetail />}>
                        <Route path={AppRoutes.SEARCH_PARAM} element={<PresenterFeatureDetail />} />
                    </Route>
                    <Route path={AppRoutes.ROUTE_THEME_DETAIL} element={<ThemeDetail />}>
                        <Route path={AppRoutes.SEARCH_PARAM} element={<ThemeDetail />} />
                    </Route>
                    <Route path={AppRoutes.ROUTE_MAP} element={<MapDetail />} />
                    <Route path={AppRoutes.ROUTE_THEME_FEATURE_DETAIL} element={<ThemeFeatureDetail />}>
                        <Route path={AppRoutes.SEARCH_PARAM} element={<ThemeFeatureDetail />} />
                    </Route>
                    <Route path={AppRoutes.ROUTE_MY_AGENDA} element={<MyAgendaContainer />} />
                    {/* Default view will be 
                        Theme list (if present) <ThemeListContainer /> 
                        or Presenter List <PresenterListContainer />
                    */}
                    {defaultRoute}
                </Routes>
                <ScrollToTop />
            </div>
            <Footer />
        </BrowserRouter>
    );
};

export default Home;