import { ApiResponseHandler, Nullable } from "../../@types";
import { REQUIRED_TOKEN_MESSAGE } from "../../api/api";
import CustomerApi from "../../api/customer/b2bCustomerApi";
import { handleApiResponse } from "../../util/apiUtil";

class CustomerService {
    public async searchCustomerAccounts(token:Nullable<string>, search:string, handler:ApiResponseHandler<any>) {
        if (!token) {
            handleApiResponse({data: null, error: REQUIRED_TOKEN_MESSAGE}, handler);
            return;
        }

        if (search.length < 3) {
            handleApiResponse({data: [], error: null}, handler);
            return;
        }

        const response = await CustomerApi.searchCustomerAccounts(token, search);
        handleApiResponse(response, handler);
    }
}

export default new CustomerService();