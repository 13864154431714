import React from "react";
interface ExternalLinkProps {
    link: string,
    content: string
}

const ExternalLink:React.FC<ExternalLinkProps> = ({link, content}) => {

    return (
        <a 
        href={link} 
        title={content} 
        target="_blank" 
        rel="noreferrer" 
        className="external-link"
        aria-label={content}
        >
            {content} 
        </a>
    )
}

export default ExternalLink