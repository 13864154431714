import React from 'react';
import { CeuType } from '../../service/session/sessionService';
import MBIcon from '../app/mbIcon';
import { Size } from '../../util/bootstrapUtil';

interface SessionCeuIconProps {
    ceuType: CeuType;
    size?: Size;
    color?: string;
    className?: string;
}

const SessionCeuIcon: React.FC<SessionCeuIconProps> = ({ ceuType, size, color, className }) => {
    if (!ceuType || (CeuType.NONE === ceuType)) {
        return null;
    }

    const isExhibit = (CeuType.EXHIBIT === ceuType);

    return <MBIcon icon={isExhibit ? 'co_present' : 'school'} 
            color={color}  
            size={size} 
            className={className} 
            title={`Eligible for ${isExhibit ? 'Exhibit' : 'CEU'} credit`} />;
};

export default SessionCeuIcon;
