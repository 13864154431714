import React, { useState } from "react";
import { ApiResponseHandler, Feature, FeatureType, LikedFeature, Note, Nullable} from "../../@types";
import { Container, Col, Row, Button} from 'react-bootstrap';
import Image from "../app/image";
import LikeComponent from "../like/likeComponent";
import NoteEditor, { NoteEditorProps } from "../note/noteEditor";
import { useAccessToken } from "../../hook/accessTokenHook";
import { useAppDispatch, useAppSelector } from "../../hook/appHook";
import { THEME_FEATURE_KEY } from "../../service/theme/themeService";
import { updateLikedPresenterFeature, updateLikedSessionFeature, updateLikedThemeFeature } from "../../state/action/user/userAction";
import MBToast from "../toast/mbToast";
import ExternalLink from "../items/externalLink";
import { PRESENTER_FEATURE_KEY } from "../../service/presenter/presenterService";
import { SESSION_FEATURE_KEY } from "../../service/session/sessionService";

interface FeatureDetailProps {
    feature: Feature,
    featureType: FeatureType
}

const FeatureDetail:React.FC<FeatureDetailProps> = ({feature, featureType}) => {
    const accessToken = useAccessToken();
    const dispatch = useAppDispatch();

    const [isShowNoteEditor, setIsShowNoteEditor] = useState(false);
    const [error, setError] = useState<Nullable<string>>(null);
    const [isLikeLoading, setIsLikeLoading] = useState(false);
    const {notes, likes} = useAppSelector((state) => state.UserState);
    const isShowActive = useAppSelector((state) => state.ShowState.selectedShow?.isActive ?? false);

    let featureLikes:LikedFeature[] = [];
    let featureNotes:Note[] = [];
    switch (featureType) {
        case THEME_FEATURE_KEY :
            featureLikes = likes.likedThemeFeatures;
            featureNotes = notes.themeFeatureNotes;
            break;
        case PRESENTER_FEATURE_KEY : 
            featureLikes = likes.likedPresenterFeatures;
            featureNotes = notes.presenterFeatureNotes;
            break;
        case SESSION_FEATURE_KEY : 
            featureLikes = likes.likedSessionFeatures;
            featureNotes = notes.sessionFeatureNotes;
            break;
    }

    const featureNote = featureNotes.find((note) => note.featureId === feature.featureId);

    const noteContent = featureNote ?? {};
    const noteEditorModalProps:NoteEditorProps = {
        title: "Feature Note",
        featureType : featureType,
        note: {
            ...noteContent,
            featureId: feature.featureId
        },
        onModalClose: () => setIsShowNoteEditor(false)
    }

    const isLiked:Nullable<boolean> = featureLikes.find((likedFeature) => 
        likedFeature.featureId === feature.featureId)?.isLiked ?? null;
    
    const onLikeChanged = (isLike:Nullable<boolean>) => {
        const likedFeature:LikedFeature = {
            featureId : feature.featureId,
            isLiked : isLike
        };

        const handler:ApiResponseHandler<LikedFeature> = {
            onSuccess : (response:LikedFeature) => {},
            onError : (error:string) => setError(error),
            onComplete : () => setIsLikeLoading(false)
        };

        setIsLikeLoading(true);
        switch (featureType) {
            case THEME_FEATURE_KEY :
                dispatch(updateLikedThemeFeature(accessToken, likedFeature, handler));
                break;
            case PRESENTER_FEATURE_KEY : 
                dispatch(updateLikedPresenterFeature(accessToken, likedFeature, handler));
                break;
            case SESSION_FEATURE_KEY : 
                dispatch(updateLikedSessionFeature(accessToken, likedFeature, handler));
        }
    }

    if (!feature?.featureId) {
        return (
            <Container fluid>
                <Row>
                    <Col className="text-center">Feature could not be found</Col>
                </Row>
            </Container>
        );
    }

    return (
        <div>
            <Container fluid className="gx-5 mb-4">
                <Row className="align-items-start gl-0">
                    <Col sm="auto" className="mr-3">
                        <Image 
                            src={feature.thumbnailUrl} 
                            alt={`${feature.title} photo`} 
                        />
                    </Col>
                    <Col md className="my-2 my-md-0 ">
                        <h2 className="text-start mb-2 feature-title">{feature.title}</h2>
                        {!!feature.description &&
                        <p className="feature-description">{feature.description}</p>
                        }
                        {!!feature.url &&
                        <p className="feature-b2b-link">
                            <ExternalLink link={feature.url} content="Download" />
                        </p>
                        }
                        {!!feature.extendedDescription &&
                        <div dangerouslySetInnerHTML={{__html: feature.extendedDescription}} />
                        }
                    </Col>
                    {isShowActive &&
                    <Col sm="auto">
                        <div className="d-inline-block d-md-block">
                            <LikeComponent isLiked={isLiked} onLikeChanged={onLikeChanged} isLikeLoading={isLikeLoading} />
                        </div>
                        <div className="d-inline-block d-md-block">
                            <Button className="mb-button item-note-button mx-2" title="Update Note" variant="" size="sm" onClick={() => setIsShowNoteEditor(true)}>Note</Button>
                        </div>
                        {isShowNoteEditor &&
                            <NoteEditor {...noteEditorModalProps} />
                        }
                    </Col>
                    }    
                </Row>
            </Container>

            {!!error &&
                <MBToast mbToastProps={{ title: 'Failed to save like setting', message: error }} />
            }
        </div>
    );
};

export default FeatureDetail;