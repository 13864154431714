import React from "react";
import PresenterContactComponent from "./presenterContact";
import { PresenterContact } from "../../@types";

export interface PresenterContactsProps {
    contacts : PresenterContact[]
}

const PresenterContacts:React.FC<PresenterContactsProps> = ({contacts}) => {
    const contactComponents = contacts.map((contact) => <PresenterContactComponent key={contact.email} contact={contact} /> );

    return (
        <>
             {contactComponents}
        </>
    )
}

export default PresenterContacts;