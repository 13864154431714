import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Col, Container, Form, FormCheck, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { ApiResponseHandler, Item, ItemSearchRequest, LikedSession, Nullable } from '../@types';
import { Session, SessionFeature, SessionItemRequest, SessionPresenterRequest, SessionTimeSlot, SessionTimeSlotChangeRequest, SessionUserPresenterSharing, SessionUserRegistrationRequest } from '../@types/session';
import { APP_ROOT_TITLE } from '../App';
import { useAccessToken } from '../hook/accessTokenHook';
import { useAppDispatch, useAppSelector, useDebounce, useIsEmployeeUser, useIsPermissionAuthorized } from '../hook/appHook';
import ItemService from '../service/item/itemService';
import ImageUploader from '../component/app/imageUploader';
import ToggleInputText from '../component/app/input/toggleInputText';
import ToggleSelect from '../component/app/input/toggleSelect';
import ToggleTextArea from '../component/app/input/toggleTextAreas';
import Loader from '../component/app/loader';
import MBIcon from '../component/app/mbIcon';
import MBLabelGroup from '../component/app/mbLabelGroup';
import ItemSelector from '../component/item/itemSelector';
import ItemList from '../component/items/itemList';
import TogglePresenterSelector from '../component/presenter/togglePresenterSelector';
import { PermissionSet } from '../constants/PermissionSet';
import { parseDateYYYYMMDD, formatDateYYYYMMDD, formatISOAtUtc, isValidDate, convertToCentralFromLocal, formatAtCentralTime } from '../util/DateTimeUtil';
import { TEXT_INPUT_TYPE } from '../util/inputUtil';
import SessionService, { CeuType, SESSION_FEATURE_KEY, SessionType } from '../service/session/sessionService';
import FeatureList from '../component/feature/featureList';
import TimeSlotInput from '../component/session/timeSlotInput';
import ConfirmationModal, { ConfirmationProps } from '../component/modal/mbModalConfirmation';
import { MBModalProps } from '../component/modal/mbModal';
import { TimeSlotUser } from '../@types/session';
import { Size, Variant } from '../util/bootstrapUtil';
import TimeSlotCalendarEventLink from '../component/session/timeSlotCalendarEventLink';
import SessionSlotAttendeeList from '../component/session/sessionSlotAttendeeList';
import LikeComponent from '../component/like/likeComponent';
import { updateLikedSession } from '../state/action/user/userAction';
import NoteEditor from '../component/note/noteEditor';

interface SessionHeader {
    name:string,
    sessionType:SessionType,
    duration:number,
    location:string,
    ceuType:CeuType,
    shortDescription:string,
    extendedDescription:string,
    maxSeats:number,
    thumbnailUrl:string
}

const SessionDetailContainer: React.FC = () => {
    const { id, searchTerm, time } = useParams();
    const sessionId = parseInt(id ?? '0');
    const defaultSlot = useMemo(() => {
        return !!time ? new Date(parseInt(time)) : null;
    }, [time]);

    const dispatch = useAppDispatch();
    const { selectedShow } = useAppSelector((state) => state.ShowState);
    const showStartDate = selectedShow?.eventStartDate ?? '';
    const showEndDate = selectedShow?.eventEndDate ?? '';
    const showId = selectedShow?.showId ?? 0;

    const { emailAddress, attendee, likes, notes } = useAppSelector((state) => state.UserState);
    const attendeeFullName = !!attendee ? `${attendee.firstName} ${attendee.lastName}` : '';
    const isLiked = likes.likedSessions.find((session) => session.sessionId === sessionId)?.isLiked;
    const sessionNote = notes.sessionNotes.find((session) => session.sessionId === sessionId) ?? {};

    const isSessionEditable = useIsPermissionAuthorized(PermissionSet.SHOW_PS_M_SITE_CONTENT);
    const isAttendanceManageable = useIsPermissionAuthorized(PermissionSet.SHOW_PS_M_ATTENDANCE);
    const isAbleToRegister = !!attendee?.email && !isSessionEditable && !isAttendanceManageable;

    const accessToken = useAccessToken();

    const [error, setError] = useState<Nullable<string>>(null);
    const [timeSlotError, setTimeSlotError] = useState<Nullable<string>>(null);
    const [session, setSession] = useState<Nullable<Session>>(null);
    const [items, setItems] = useState<Nullable<Item[]>>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLikeLoading, setIsLikeLoading] = useState<boolean>(false);
    const [isShowNoteEditor, setIsShowNoteEditor] = useState<boolean>(false);
    const [isDeleteSessionModalConfirmation, setIsDeleteSessionModalConfirmation] = useState<boolean>(false);
    const [slotTimeToDelete, setSlotTimeToDelete] = useState<Nullable<string>>(null);
    const [searchItemText, setSearchItemText] = useState<string>('');
    const [features, setFeatures] = useState<Nullable<SessionFeature[]>>(null);
    const debouncedSearchItemText = useDebounce(searchItemText, 750);
    const isEmployee = useIsEmployeeUser();
    const [sessionHeader, setSessionHeader] = useState<SessionHeader>({
        name : '',
        sessionType : SessionType.TRAINING,
        ceuType : CeuType.NONE,
        duration : 0,
        location : '',
        shortDescription : '',
        extendedDescription : '',
        maxSeats : 0,
        thumbnailUrl : ''
    });
    const debouncedHeader = useDebounce(sessionHeader, 800);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<Nullable<SessionTimeSlot>>(null);
    

    function generateDateOptions():string[] {
        if (!showStartDate || !showEndDate) {
            return [];
        }
    
        if (showStartDate === showEndDate) {
            return [showStartDate];
        }
    
        let date = parseDateYYYYMMDD(showStartDate, '-');
        const endDate = parseDateYYYYMMDD(showEndDate, '-');
    
        if (!date || !endDate) {
            return [];
        }
    
        const dates:string[] = [showStartDate];
    
        while(date < endDate) {
            date.setDate(date.getDate() + 1);
            const dateString = formatDateYYYYMMDD(date);
            dates.push(dateString);
        }
    
        return dates;
    }

    const isTimeSlotDisabled = useCallback((session:Session, timeSlot:SessionTimeSlot) => {
        if(!timeSlot || !timeSlot.time || isAttendanceManageable) {
            return false;
        }

        return new Date(timeSlot.time) < new Date() || ((session?.maxSeats ?? 0) - (timeSlot.users.length ?? 0)) < 1;
    }, [isAttendanceManageable]);

    const searchItems = useCallback(
        (search: string | undefined) => {
            setIsLoading(true);

            if (!showId || !accessToken || !session || !session.items?.length) {
                setIsLoading(false);
                return;
            }

            const request: ItemSearchRequest = {
                showId: showId,
                presenterIds: session.presenters,
                searchTerm: search,
            };

            const handler: ApiResponseHandler<Item[]> = {
                onSuccess: (items) => {
                    setItems(items?.filter(item => session.items?.includes(item.itemNumber)));
                },
                onError: (error) => setItems([]),
                onComplete: () => setIsLoading(false),
            };

            ItemService.searchItems(accessToken, request, handler);
        },
        [accessToken, session, showId]
    );

    const saveSession = useCallback((headerUpdates:SessionHeader) => {
        if (!showId 
                || isLoading 
                || !accessToken) {
            return;
        }

        if (!headerUpdates.name) {
            return;
        }

        const isChanged = (session?.name !== headerUpdates.name)
            || (session?.duration !== headerUpdates.duration)
            || (session?.location !== headerUpdates.location)
            || (session?.shortDescription !== headerUpdates.shortDescription)
            || (session?.extendedDescription !== headerUpdates.extendedDescription)
            || (session?.maxSeats !== headerUpdates.maxSeats)
            || (session?.sessionType !== headerUpdates.sessionType)
            || (session?.ceuType !== headerUpdates.ceuType)
            || (session?.thumbnailUrl !== headerUpdates.thumbnailUrl);

        if (!isChanged) {
            return;
        }

        const updatedSession:Session = {
            ...session,
            ...headerUpdates,
            showId : showId,
            sessionId : session?.sessionId ?? 0,
            items : session?.items ?? [],
            timeSlots : session?.timeSlots ?? [],
            color: session?.color ?? ''
        };

        const handler: ApiResponseHandler<Session> = {
            onSuccess: (session) => {
                setItems(null);
                setSession(session);
            },
            onError: (error) => setError(error),
            onComplete: () => setIsLoading(false),
        };

        setIsLoading(true);
        SessionService.saveSession(accessToken, showId, updatedSession, handler);
    }, [accessToken, showId, session, isLoading]);

    useEffect(() => {
        searchItems(debouncedSearchItemText);
    }, [debouncedSearchItemText, searchItems]);

    useEffect(() => {
        document.title = `${APP_ROOT_TITLE} - Session Details`;
    }, []);

    useEffect(() => {
        if (!accessToken || (session == null) || (features != null)) {
            return;
        }

        if (!session.sessionId) {
            setFeatures([]);
            return;
        }

        const handler:ApiResponseHandler<SessionFeature[]> = {
            onSuccess : (sessionFeatures) => setFeatures(sessionFeatures),
            onError : (error) => setError(error),
            onComplete : () => setIsLoading(false)
        };

        setIsLoading(true);
        SessionService.getSessionFeatures(accessToken, showId, session.sessionId, handler);
    }, [accessToken, session, showId, features]);

    useEffect(() => {
        if (isLoading || (session != null) || !accessToken || !showId) {
            return;
        }

        const newSession:Session = {
            showId: showId,
            sessionId: 0,
            name: "New Session",
            sessionType: SessionType.CHEF_DEMO,
            duration: 0,
            location: "",
            shortDescription: "",
            extendedDescription: "",
            thumbnailUrl: "",
            ceuType: CeuType.NONE,
            presenters: [],
            themes: [],
            items: [],
            maxSeats: 0,
            timeSlots: [],
            color: ""
        };

        if (sessionId === 0) {
            setSession(newSession);
            setSessionHeader({
                name : newSession.name,
                sessionType : newSession.sessionType,
                ceuType : newSession.ceuType,
                duration : newSession.duration,
                location : newSession.location,
                shortDescription : newSession.shortDescription,
                extendedDescription : newSession.extendedDescription,
                maxSeats : newSession.maxSeats,
                thumbnailUrl : newSession.thumbnailUrl
            });
            return;
        }

        const handler:ApiResponseHandler<Session> = {
            onSuccess : (session) => {
                session?.timeSlots?.forEach(timeSlot => timeSlot.isDisabled = isTimeSlotDisabled(session, timeSlot));
                setSession(session ?? {})

                if ((isAbleToRegister || isAttendanceManageable) && session?.timeSlots?.length) {
                    let selectedSlot = null;
                    if (isValidDate(defaultSlot)) {
                        const datestamp = formatISOAtUtc(defaultSlot);
                        selectedSlot = session.timeSlots.find((slot) => slot.time === datestamp);
                    }
                    
                    if (selectedSlot == null) {
                        session.timeSlots.find(timeSlot => !timeSlot.isDisabled);
                    }
                    
                    setSelectedTimeSlot(selectedSlot);
                }

                setSessionHeader({
                    name : session.name,
                    sessionType : session.sessionType,
                    ceuType : session.ceuType,
                    duration : session.duration,
                    location : session.location,
                    shortDescription : session.shortDescription,
                    extendedDescription : session.extendedDescription,
                    maxSeats : session.maxSeats,
                    thumbnailUrl : session.thumbnailUrl
                });
            },
            onError : (error) => {
                setError(error);
                setSession(newSession);
                setSessionHeader({
                    name : newSession.name,
                    sessionType : newSession.sessionType,
                    ceuType : newSession.ceuType,
                    duration : newSession.duration,
                    location : newSession.location,
                    shortDescription : newSession.shortDescription,
                    extendedDescription : newSession.extendedDescription,
                    maxSeats : newSession.maxSeats,
                    thumbnailUrl : newSession.thumbnailUrl
                });
            },
            onComplete : () => setIsLoading(false)
        };

        setIsLoading(true);
        SessionService.getSession(accessToken, showId, sessionId, handler);
    }, [isLoading, session, accessToken, sessionId, showId, isAbleToRegister, isAttendanceManageable, isTimeSlotDisabled, defaultSlot]);

    useEffect(() => {
        saveSession({...debouncedHeader});
    }, [debouncedHeader, saveSession]);

    useEffect(() => {
        if (!isLoading && session && items == null) {
            searchItems(searchTerm);
        }
    }, [items, session, isLoading, searchTerm, searchItems]);

    useEffect(() => {
        if (!timeSlotError) {
            return;
        }

        document.getElementById('timeSlotErrorContainer')?.scrollIntoView({
            block : 'center',
            behavior : 'smooth'
        });
    }, [timeSlotError]);

    function updateSessionLike(isLiked:Nullable<boolean>) {
        const likedSession:LikedSession = {
            sessionId : sessionId,
            isLiked : isLiked
        };

        const handler:ApiResponseHandler<LikedSession> = {
            onSuccess : (response:LikedSession) => {},
            onError : (error:string) => setError(error),
            onComplete : () => setIsLikeLoading(false)
        };

        setIsLikeLoading(true);
        dispatch(updateLikedSession(accessToken, likedSession, handler));
    }

    function updateTimeSlotWithWalkIn(updatedTimeSlotUsers: TimeSlotUser[]) {
        if (!selectedTimeSlot || !session) {
            return;
        }

        const timeSlot: SessionTimeSlot = { ...selectedTimeSlot, users: updatedTimeSlotUsers };

        setSelectedTimeSlot(timeSlot);
        setSession({
            ...session,
            timeSlots: session.timeSlots.map((slot) => {
                if (slot.time === timeSlot.time) {
                    return timeSlot;
                }

                return slot;
            }),
        });
    };

    if (!session) {
        return (
            <Container fluid>
                <Row>
                    <Col className="text-center">Session could not be found</Col>
                </Row>
            </Container>
        );
    }

    const deleteSession = () => {
        if (!showId || !accessToken || !session) {
            return;
        }

        if(session.sessionId === 0) {
            window.location.href = '/session-list';
            return;
        }

        setIsDeleteSessionModalConfirmation(true);
    }

    const addNewTimeSlot = (date:Date) => {
        if (!showId 
                || !accessToken 
                || !session ) {
            return;
        }

        if (!isValidDate(date)) {
            return;
        }

        const request:SessionTimeSlotChangeRequest = {
            accessToken: accessToken,
            showId: showId,
            sessionId: session.sessionId,
            time: formatISOAtUtc(convertToCentralFromLocal(date))
        };

        const handler: ApiResponseHandler<SessionTimeSlot[]> = {
            onSuccess: (timeSlots) => {
                    timeSlots?.forEach(timeSlot => timeSlot.isDisabled = isTimeSlotDisabled(session, timeSlot));

                    const updatedSession:Session = {
                    ...session,
                    timeSlots: timeSlots
                };

                setSession(updatedSession);
                setTimeSlotError(null);
            },
            onError: (error) => setTimeSlotError(error),
            onComplete: () => setIsLoading(false),
        };

        setIsLoading(true);
        SessionService.saveSessionTimeSlot(request, handler);
    }

    async function removeTimeSlot():Promise<boolean> {
        if (!showId 
            || !accessToken 
            || !session 
            || !slotTimeToDelete) {
            return false;
        }

        const request:SessionTimeSlotChangeRequest = {
            accessToken: accessToken,
            showId: showId,
            sessionId: session.sessionId,
            time: slotTimeToDelete
        };

        let isSuccess = false;
        const handler: ApiResponseHandler<SessionTimeSlot[]> = {
            onSuccess: (timeSlots) => {
                    isSuccess = true;
                    
                    timeSlots?.forEach(timeSlot => isTimeSlotDisabled(session, timeSlot));

                    const updatedSession:Session = {
                    ...session,
                    timeSlots: timeSlots
                };

                setSession(updatedSession);
                setTimeSlotError(null);
            },
            onError: (error) => setTimeSlotError(error),
            onComplete: () => setIsLoading(false),
        };

        setSlotTimeToDelete(null);
        setIsLoading(true);
        await SessionService.deleteSessionTimeSlot(request, handler);
        return isSuccess;
    }

    const onTimeSlotRegister = () => {
        if(!showId || !accessToken || !session || !selectedTimeSlot) {
            return;
        }

        const request:SessionUserRegistrationRequest = {
            accessToken: accessToken,
            showId: showId,
            sessionId: session.sessionId,
            name: attendeeFullName,
            time: selectedTimeSlot.time
        };

        const handler: ApiResponseHandler<TimeSlotUser[]> = {
            onSuccess: (timeSlotUsers) => {
                const sessionTimeSlots = [...session.timeSlots];
                const timeSlot = sessionTimeSlots.find(timeSlot => timeSlot.time === selectedTimeSlot.time);
                if(timeSlot) {
                    timeSlot.users = timeSlotUsers;

                    sessionTimeSlots?.forEach(timeSlot => timeSlot.isDisabled = isTimeSlotDisabled(session, timeSlot));

                    const updatedSession:Session = {
                        ...session,
                        timeSlots: sessionTimeSlots
                    };
    
                    setSession(updatedSession);
                    setSelectedTimeSlot(null);
                }

                setTimeSlotError(null);
            },
            onError: (error) => setTimeSlotError(error),
            onComplete: () => setIsLoading(false),
        };

        setIsLoading(true);
        SessionService.saveSessionUserRegistration(request, handler)
    }

    const onTimeSlotCancel = (selectedTimeSlot:SessionTimeSlot) => {
        if(!showId || !accessToken || !session || !selectedTimeSlot) {
            return;
        }

        const request:SessionUserRegistrationRequest = {
            accessToken: accessToken,
            showId: showId,
            sessionId: session.sessionId,
            name: attendeeFullName,
            time: selectedTimeSlot.time
        };

        const handler: ApiResponseHandler<TimeSlotUser[]> = {
            onSuccess: (timeSlotUsers) => {
                const sessionTimeSlots = [...session.timeSlots];
                const timeSlot = sessionTimeSlots.find(timeSlot => timeSlot.time === selectedTimeSlot.time);
                if(timeSlot) {
                    timeSlot.users = timeSlotUsers;

                    sessionTimeSlots?.forEach(timeSlot => timeSlot.isDisabled = isTimeSlotDisabled(session, timeSlot));

                    const updatedSession:Session = {
                        ...session,
                        timeSlots: sessionTimeSlots
                    };
    
                    setSession(updatedSession);

                    const firstValidTimeSlot = updatedSession?.timeSlots.find(timeSlot => !timeSlot.isDisabled);
                    if((isAbleToRegister || isAttendanceManageable) && firstValidTimeSlot) {
                        setSelectedTimeSlot(firstValidTimeSlot);
                    }
                }

                setTimeSlotError(null);
            },
            onError: (error) => setTimeSlotError(error),
            onComplete: () => setIsLoading(false),
        };

        setIsLoading(true);
        SessionService.deleteCurrentUserSessionRegistration(request, handler)
    }

    const onSharingChange = (time:string, registeredUser:TimeSlotUser) => {
        if(!showId || !accessToken || !session || !registeredUser) {
            return;
        }

        const request:SessionUserPresenterSharing = {
            accessToken: accessToken,
            showId: showId,
            sessionId: session.sessionId,
            email: emailAddress,
            time: time,
            isSharingWithPresenter: !registeredUser.isSharingWithPresenter
        };

        const handler: ApiResponseHandler<TimeSlotUser[]> = {
            onSuccess: (timeSlotUsers) => {
                const sessionTimeSlots = [...session.timeSlots];
                const timeSlot = sessionTimeSlots.find(timeSlot => timeSlot.time === time);
                if(timeSlot) {
                    timeSlot.users = timeSlotUsers;

                    sessionTimeSlots?.forEach(timeSlot => timeSlot.isDisabled = isTimeSlotDisabled(session, timeSlot));

                    const updatedSession:Session = {
                        ...session,
                        timeSlots: sessionTimeSlots
                    };
    
                    setSession(updatedSession);
                }

                setTimeSlotError(null);
            },
            onError: (error) => setTimeSlotError(error),
            onComplete: () => setIsLoading(false),
        };

        setIsLoading(true);
        SessionService.saveSessionUserPresenterSharing(request, handler)
    }

    function onUserAttendanceChanged(time:string, users:TimeSlotUser[]) {
        if (!session) {
            return;
        }

        const sessionTimeSlots = [...session.timeSlots];
        const timeSlot = sessionTimeSlots.find(timeSlot => timeSlot.time === time);
        if (timeSlot) {
            timeSlot.users = users;

            const updatedSession:Session = {
                ...session,
                timeSlots: sessionTimeSlots
            };

            setSession(updatedSession);
            setSelectedTimeSlot(timeSlot);
        }
    }

    const presenterSelected = (selectedPresenterId:number) => {
        if (!showId || !accessToken || !session) {
            return;
        }

        const request:SessionPresenterRequest = {
            accessToken: accessToken,
            showId: showId,
            sessionId: session.sessionId,
            presenterId: selectedPresenterId
        };

        const handler: ApiResponseHandler<number[]> = {
            onSuccess: (presenterIds) => {
                    const updatedSession:Session = {
                    ...session,
                    presenters: presenterIds
                };
        
                setItems(null);
                setSession(updatedSession);
            },
            onError: (error) => setError(error),
            onComplete: () => setIsLoading(false),
        };

        setIsLoading(true);
        SessionService.saveSessionPresenter(request, handler);
    }

    async function removeSelectedPresenter(presenterId:number):Promise<boolean> {
        if (!showId || !accessToken || !session || !presenterId) {
            return false;
        }

        const request:SessionPresenterRequest = {
            accessToken: accessToken,
            showId: showId,
            sessionId: session.sessionId,
            presenterId: presenterId
        };

        let isSuccess = false;
        const handler: ApiResponseHandler<number[]> = {
            onSuccess: (presenterIds) => {
                    isSuccess = true;
                    const updatedSession:Session = {
                    ...session,
                    presenters: presenterIds
                };
        
                setItems(null);
                setSession(updatedSession);
            },
            onError: (error) => setError(error),
            onComplete: () => setIsLoading(false),
        };

        setIsLoading(true);
        await SessionService.deleteSessionPresenter(request, handler);
        return isSuccess;
    }

    const itemSelected = (itemNumber:string) => {
        if (!showId || !accessToken || !session) {
            return;
        }

        const request:SessionItemRequest = {
            accessToken: accessToken,
            showId: showId,
            sessionId: session.sessionId,
            itemNumber: itemNumber
        };

        const handler: ApiResponseHandler<string[]> = {
            onSuccess: (itemNumbers) => {
                    const updatedSession:Session = {
                    ...session,
                    items: itemNumbers
                };
        
                setItems(null);
                setSession(updatedSession);
            },
            onError: (error) => setError(error),
            onComplete: () => setIsLoading(false),
        };

        setIsLoading(true);
        SessionService.saveSessionItem(request, handler);
    }

    const removeSelectedItem = (itemNumber:string) => {
        if (!showId || !accessToken || !session) {
            return;
        }

        const request:SessionItemRequest = {
            accessToken: accessToken,
            showId: showId,
            sessionId: session.sessionId,
            itemNumber: itemNumber
        };

        const handler: ApiResponseHandler<string[]> = {
            onSuccess: (itemNumbers) => {
                    const updatedSession:Session = {
                    ...session,
                    items: itemNumbers
                };
        
                setItems(null);
                setSession(updatedSession);
            },
            onError: (error) => setError(error),
            onComplete: () => setIsLoading(false),
        };

        setIsLoading(true);
        SessionService.deleteSessionItem(request, handler);
    }

    const userRegisteredTimeslot = session?.timeSlots?.find(timeSlot => timeSlot?.users?.find(registeredUser => registeredUser.email === emailAddress));
    const registeredUser = userRegisteredTimeslot?.users?.find(user => user.email === emailAddress);

    const deleteModalProps:MBModalProps = {
        mbModalProps: {
            actionBtnText:"Delete",
            title:`Delete Session`,
            size: Size.SMALL,
            onModalSubmit: () => {
                return new Promise(resolve => {
                    const handler: ApiResponseHandler<Session> = {
                        onSuccess: (session) => {
                            window.location.href = '/session-list';
                            resolve(true);
                        },
                        onError: (error) => {
                            setError(error);
                            resolve(false);
                        },
                        onComplete: () => setIsLoading(false),
                    };
            
                    setIsLoading(true);
                    SessionService.deleteSession(accessToken ?? '', showId, session.sessionId, handler);
                });
            }
        }
    }

    const deleteSessionModalProps:ConfirmationProps = {
        title: 'Delete Session',
        message:`Are you sure you want to delete this session?`,
        mbModalProps: deleteModalProps.mbModalProps,
        show: true,
        onHide: () => setIsDeleteSessionModalConfirmation(false)
    }

    return (
        <div>
            {!!error &&
                <Alert variant={Variant.DANGER} onClose={() => setError(null)} dismissible>
                    {error}
                </Alert>
            }
            <Container fluid className="gx-5 mb-4">
                <Row>
                    <Col xs="auto" className="mr-3">
                        <ImageUploader 
                            currentImageUrl={session.thumbnailUrl} 
                            uploadDirectory={`cdn/show/${showId}/session/${sessionId}`} 
                            onImageUploaded={(value) => setSessionHeader({...sessionHeader, thumbnailUrl: value})}
                            isDisabled={!isSessionEditable}
                        />
                    </Col>
                    <Col className="mb-sm-0">
                        <Row>
                            <Col md={9}>
                                <Row>
                                    <Col>
                                        <ToggleInputText 
                                            inputType={TEXT_INPUT_TYPE.TEXT}
                                            buttonVariant={Variant.PLAIN}
                                            identifier="name"
                                            value={session.name}
                                            placeholderText="New Session"
                                            onValueChanged={(value) => setSessionHeader({...sessionHeader, name: value})}
                                            isEditValid={(name) => true}
                                            isDisabled={!isSessionEditable}
                                            buttonClass={`fs-xl ${!isSessionEditable ? 'no-border cursor-default' : ''}`}
                                            inputClass="fs-xl"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3" style={{maxWidth: 700}}>
                                    <Col>
                                        <ToggleTextArea 
                                            buttonVariant={Variant.PLAIN}
                                            identifier="shortDescription"
                                            value={session.shortDescription}
                                            placeholderText="Short Description"
                                            onValueChanged={(value) => setSessionHeader({...sessionHeader, shortDescription: value})}
                                            isEditValid={(shortDescription) => true}
                                            isDisabled={!isSessionEditable}
                                            buttonClass={`${!isSessionEditable ? 'no-border cursor-default' : ''}`}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={3}>
                                <div className="d-flex justify-content-end" style={{minWidth: 185}}>
                                    <div className="d-none d-md-block">
                                        <LikeComponent isLiked={isLiked} onLikeChanged={updateSessionLike} isLikeLoading={isLikeLoading} />
                                        <div className="d-inline-block d-md-block">
                                            <Button className="mb-button item-note-button mx-3" 
                                                    title="Update Note" 
                                                    size={Size.SMALL}
                                                    onClick={() => setIsShowNoteEditor(true)}>
                                                Note
                                            </Button>
                                        </div>
                                    </div>
                                    {isSessionEditable && 
                                    <div>
                                        <Button className="mb-button w-auto mx-3" onClick={() => deleteSession()}>
                                            Remove
                                        </Button>    
                                    </div>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col sm="auto">
                                <MBLabelGroup label="Session Type" id="sessionType" className="d-flex" style={{minWidth: 150}}>
                                    <ToggleSelect 
                                        buttonVariant={Variant.PLAIN}
                                        identifier="sessionType"
                                        defaultText={SessionType.CHEF_DEMO}
                                        value={session.sessionType}
                                        options={[
                                            {value: SessionType.CHEF_DEMO, text: SessionType.CHEF_DEMO},
                                            {value: SessionType.SELF_GUIDED, text: SessionType.SELF_GUIDED},
                                            {value: SessionType.TRAINING, text: SessionType.TRAINING},
                                            {value: SessionType.SEMINAR, text: SessionType.SEMINAR},
                                            {value: SessionType.WORKSHOP, text: SessionType.WORKSHOP}
                                        ]}
                                        onValueChanged={(value) => setSessionHeader({...sessionHeader, sessionType: value as SessionType})}
                                        isEditValid={(sessionType) => true}
                                        isDisabled={!isSessionEditable}
                                        buttonClass={`p-1 ${!isSessionEditable ? 'no-border cursor-default' : ''}`}
                                    />
                                </MBLabelGroup>
                            </Col>
                            <Col sm="auto">
                                <div className="d-flex" style={{minWidth: 150}}>
                                    <MBLabelGroup label="Duration" id="duration" className="d-flex">
                                        <ToggleInputText 
                                            inputType={TEXT_INPUT_TYPE.NUMBER}
                                            buttonVariant={Variant.PLAIN}
                                            identifier="duration"
                                            value={session.duration}
                                            placeholderText="0"
                                            onValueChanged={(value) => setSessionHeader({...sessionHeader, duration: parseInt(value)})}
                                            isEditValid={(duration) => !!parseInt(duration)}
                                            isDisabled={!isSessionEditable}
                                            buttonClass={`p-1 ${!isSessionEditable ? 'no-border cursor-default' : ''}`}
                                        />
                                    </MBLabelGroup>
                                    minutes
                                </div>
                            </Col>
                            <Col sm="auto">
                                <MBLabelGroup label="Location" id="location" className="d-flex" style={{minWidth: 150}}>
                                    <ToggleInputText 
                                        inputType={TEXT_INPUT_TYPE.TEXT}
                                        buttonVariant={Variant.PLAIN}
                                        identifier="location"
                                        value={session.location}
                                        placeholderText="None"
                                        onValueChanged={(value) => setSessionHeader({...sessionHeader, location: value})}
                                        isEditValid={(location) => true}
                                        isDisabled={!isSessionEditable}
                                        buttonClass={`p-1 ${!isSessionEditable ? 'no-border cursor-default' : ''}`}
                                    />
                                </MBLabelGroup>
                            </Col>
                            <Col sm="auto">
                                <MBLabelGroup label="CEU Credit" id="ceuType" className="d-flex" style={{minWidth: 150}}>
                                    <ToggleSelect 
                                        buttonVariant={Variant.PLAIN}
                                        identifier="ceuType"
                                        defaultText={CeuType.NONE}
                                        value={session.ceuType}
                                        options={[
                                            {value: CeuType.NONE, text: CeuType.NONE},
                                            {value: CeuType.GENERAL, text: CeuType.GENERAL},
                                            {value: CeuType.SANITATION, text: CeuType.SANITATION},
                                            {value: CeuType.EXHIBIT, text: CeuType.EXHIBIT}
                                        ]}
                                        onValueChanged={(value) => setSessionHeader({...sessionHeader, ceuType: value as CeuType})}
                                        isEditValid={(ceuType) => true}
                                        isDisabled={!isSessionEditable}
                                        buttonClass={`p-1 ${!isSessionEditable ? 'no-border cursor-default' : ''}`}
                                    />
                                </MBLabelGroup>
                            </Col>
                        </Row>
                        <Row className="d-md-none">
                            <LikeComponent isLiked={isLiked} onLikeChanged={updateSessionLike} isLikeLoading={isLikeLoading} />
                            <div>
                                <Button className="mb-button item-note-button mx-3" 
                                        title="Update Note" 
                                        size={Size.SMALL}
                                        onClick={() => setIsShowNoteEditor(true)}>
                                    Note
                                </Button>
                            </div>
                        </Row>
                        <Row className="mt-3" style={{maxWidth: 550}}>
                            <Col>
                                <TogglePresenterSelector 
                                    selectedPresenters={session.presenters ?? []} 
                                    onPresenterSelected={presenterSelected} 
                                    onPresenterRemoved={removeSelectedPresenter} 
                                    isDisabled={!isSessionEditable}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3" style={{maxWidth: 700}}>
                            <Col>
                                <ToggleTextArea
                                    buttonVariant={Variant.PLAIN}
                                    identifier="extendedDescription"
                                    value={session.extendedDescription}
                                    placeholderText="Extended Description"
                                    onValueChanged={(value) => setSessionHeader({...sessionHeader, extendedDescription: value})}
                                    isEditValid={(extendedDescription) => true}
                                    isHtmlContent={true}
                                    isDisabled={!isSessionEditable}
                                    inputClass='w-100'
                                    buttonClass={`${!isSessionEditable ? 'no-border cursor-default' : ''}`}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3" style={{maxWidth: 200}}>
                            <Col>
                                <MBLabelGroup label="Max Seats" id="maxSeats" className="d-flex">
                                    <ToggleInputText 
                                        inputType={TEXT_INPUT_TYPE.NUMBER}
                                        buttonVariant={Variant.PLAIN}
                                        identifier="maxSeats"
                                        value={session.maxSeats}
                                        placeholderText="0"
                                        onValueChanged={(value) => setSessionHeader({...sessionHeader, maxSeats: parseInt(value)})}
                                        isEditValid={(maxSeats) => !!parseInt(maxSeats)}
                                        isDisabled={!isSessionEditable}
                                        buttonClass={`p-1 ${!isSessionEditable ? 'no-border cursor-default' : ''}`}
                                    />
                                </MBLabelGroup>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            {!!timeSlotError &&
                            <Alert id="timeSlotErrorContainer"
                                    variant={Variant.DANGER} 
                                    onClose={() => setTimeSlotError(null)} 
                                    dismissible>
                                {timeSlotError}
                            </Alert>
                            }
                            {!userRegisteredTimeslot && 
                                <Col md={10}>
                                    <MBLabelGroup label="Available Times" id="availableTimes">
                                        {isSessionEditable && 
                                            <TimeSlotInput dates={generateDateOptions()} onTimeSlotAdded={addNewTimeSlot} />
                                        }
                                        {!!session?.timeSlots?.length &&
                                            <div className="d-flex flex-wrap">
                                                {session.timeSlots?.map(timeSlot => 
                                                    <div className="d-flex align-items-center mb-2" 
                                                            style={{marginRight: 20}}
                                                            key={`timeSlot-${timeSlot.time}`}>                                        
                                                        {(isAbleToRegister || isAttendanceManageable || isEmployee) &&  
                                                        <Form.Check type="radio"
                                                                id={`timeSlotRegister-${timeSlot.time}`}
                                                                name="timeSlotGroup"
                                                                checked={selectedTimeSlot?.time === timeSlot.time}
                                                                    disabled={timeSlot.isDisabled}
                                                                onChange={(event) => setSelectedTimeSlot(timeSlot)}
                                                                label={`${formatAtCentralTime(new Date(timeSlot.time))} Central - Seats Remaining ${session.maxSeats - (timeSlot.users.length ?? 0)}`} />
                                                        }
                                                        {(!isAbleToRegister && !isAttendanceManageable && !isEmployee) && 
                                                        <span className={`px-1 ${timeSlot.isDisabled ? 'fw-400 text-muted' : ''}`}>
                                                            {`${formatAtCentralTime(new Date(timeSlot.time))} Central`} {!isSessionEditable ? ` - Seats Remaining ${session.maxSeats - (timeSlot.users.length ?? 0)}` : ``}
                                                        </span>
                                                        }
                                                        {isSessionEditable && 
                                                        <Button variant={Variant.LINK} 
                                                                size={Size.SMALL}
                                                                className='pt-2'
                                                                onClick={() => setSlotTimeToDelete(timeSlot.time)} >
                                                            <MBIcon color="text-red" icon="close" />
                                                        </Button>
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                        }
                                        {(!!selectedTimeSlot && isAbleToRegister) && 
                                            <Button className="mb-button mt-2" onClick={onTimeSlotRegister}>
                                                Register Me
                                            </Button>
                                        }
                                    </MBLabelGroup>
                                </Col>
                            }
                            {(isAbleToRegister && userRegisteredTimeslot && registeredUser) &&
                                <Col md={8}>
                                    <div>
                                        <span className="fw-600">You are registered for the session on {formatAtCentralTime(new Date(userRegisteredTimeslot.time))} Central</span>
                                        <Button className="mb-button m-2 mx-3" onClick={(event) => onTimeSlotCancel(userRegisteredTimeslot)}>
                                            Cancel Registration
                                        </Button>
                                        <TimeSlotCalendarEventLink time={new Date(userRegisteredTimeslot.time)}
                                                sessionName={session.name}
                                                sessionDescription={session.shortDescription}
                                                duration={session.duration}
                                                sesionLocation={session.location} />
                                    </div>
                                    <div>
                                        <FormCheck
                                            type="checkbox"
                                            id="consentCheckbox"
                                            label="I consent to share my attendance information with the presenter(s)."
                                            checked={registeredUser.isSharingWithPresenter}
                                            onChange={(event) => onSharingChange(userRegisteredTimeslot.time, registeredUser)}
                                        />
                                    </div>
                                </Col>
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>
            {(selectedTimeSlot && (isAttendanceManageable || isEmployee)) && 
                <SessionSlotAttendeeList session={session} 
                        timeSlot={selectedTimeSlot} 
                        isAttendanceManageable={isAttendanceManageable} 
                        onUserAttendanceModified={onUserAttendanceChanged} 
                        onWalkinUserRegistered={updateTimeSlotWithWalkIn} />
            }
            {!!features?.length &&
            <Container fluid className="gx-5 mb-4">
                <FeatureList features={features} featureType={SESSION_FEATURE_KEY} entityId={session.sessionId} />
            </Container>
            }
            <Container fluid className="gx-4 gx-lg-5">
                <Row className="border-bottom gx-0 pb-2 pb-md-0 mb-3">
                    <Col md="auto" className='mt-2'>
                        <h2>Featured Items</h2>
                    </Col>
                    <Col md={3} className="mb-2 mx-auto">
                        {isSessionEditable && 
                            <ItemSelector 
                            selectedItems={session.items ?? []} 
                            presenterIds={session.presenters ?? []} 
                            onItemSelected={itemSelected} />
                        }  
                    </Col>
                    <Col md="auto" className="justify-content-end">
                        <Form.Control
                            type="text"
                            defaultValue={searchTerm}
                            onChange={(event) => setSearchItemText(event.target.value)}
                            placeholder={`Search featured items...`}
                            className="mt-2"
                        />
                    </Col>
                </Row>

                {isLoading && <Loader />}

                {!!items?.length && <ItemList items={items} isRemoveButtonDisplayed={isSessionEditable} onItemRemoved={removeSelectedItem} />}

                {!items?.length && (
                    <Row className="mt-4">
                        <Col className="text-center">{!!isLoading ? 'Preparing Items...' : 'No Items found'}</Col>
                    </Row>
                )}
                {isDeleteSessionModalConfirmation && 
                    <ConfirmationModal {...deleteSessionModalProps} /> 
                }
                {isShowNoteEditor &&
                    <NoteEditor title="Session Note"
                        note={{
                            ...sessionNote,
                            sessionId: session.sessionId
                        }}
                        session={session}
                        onModalClose={() => setIsShowNoteEditor(false)} />
                }
                {!!slotTimeToDelete &&
                    <ConfirmationModal 
                        message={`Are you sure you want to delete the slot for time ${formatAtCentralTime(new Date(slotTimeToDelete ?? ''))} Central? This will remove any attendance records for this slot.`}
                        show={true}
                        mbModalProps={{
                            title: 'Delete Session Time Slot',
                            actionBtnText: "Delete",
                            size : Size.LARGE,
                            isCloseButtonDisplayed : false,
                            onModalSubmit: () => removeTimeSlot(),
                            onModalCancel: () => {
                                setSlotTimeToDelete(null);
                                return Promise.resolve(true);
                            },
                            isActionBtnDisabled: undefined}} />
                }
            </Container>
        </div>
    );
};

export default SessionDetailContainer;