import { ApiResponse } from "../../@types";
import Api from "../api";

const USER_BASE = 'users/'

class B2BUserApi {
    public async fetchUserCustomers(token:string, customerId:number, isBillToOnlyIncluded:boolean):Promise<ApiResponse> {
        const url = `${USER_BASE}account/${customerId}?isBillToOnlyIncluded=${isBillToOnlyIncluded}`;
        return Api.performB2BGETRequest(url, token);
    }

    public async fetchUserPermissions(token: string): Promise<ApiResponse> {
        const url = `${USER_BASE}permissions?app=SHOW`;
        return Api.performB2BGETRequest(url, token);
    }
}

export default new B2BUserApi();