import React, { useState } from 'react';
import { Badge, Button, CloseButton, Col, Row } from 'react-bootstrap';
import { Nullable, Presenter } from '../../@types';
import Loader from '../app/loader';
import { useAppSelector, useDebounce } from '../../hook/appHook';
import MBIcon from '../app/mbIcon';
import ConfirmationModal from '../modal/mbModalConfirmation';
import { Size } from '../../util/bootstrapUtil';

export interface PresenterSelectorProps {
    selectedPresenters : number[],
    onPresenterSelected : (presenterId:number) => void,
    onPresenterRemoved : (presenterId:number) => void,
    onBlur? : () => void,
}

const PresenterSelector:React.FC<PresenterSelectorProps> = ({selectedPresenters, onPresenterSelected, onPresenterRemoved, onBlur}) => {
    const {isLoading, presenters} = useAppSelector((state) => state.PresenterState);
    const [search, setSearch] = useState('');
    const [selectedPresenterIds, setSelectedPresenterIds] = useState(selectedPresenters);
    const [selectedPresenterId, setSelectedPresenterId] = useState<Nullable<number>>(null);
    const [presenterToRemove, setPresenterToRemove] = useState<Nullable<Presenter>>(null);
    const debouncedSearchText = useDebounce(search, 750);

    const addSelectedPresenter = () => {
        setSearch('');

        if (selectedPresenterId) {
            onPresenterSelected(selectedPresenterId);
            setSelectedPresenterIds([...selectedPresenterIds, selectedPresenterId]);
            setSelectedPresenterId(null);
        }
    }

    function removeSelectedPresenter():Promise<boolean> {
        if (!presenterToRemove) {
            return Promise.resolve(false);
        }

        setSelectedPresenterIds(selectedPresenterIds.filter(presenter => presenterToRemove.presenterId !== presenter));
        onPresenterRemoved(presenterToRemove.presenterId);
        setPresenterToRemove(null);
        return Promise.resolve(true);
    }

    const presenterSelected = (presenter:Presenter) => {
        setSearch(presenter.name);
        setSelectedPresenterId(presenter.presenterId);
    }

    const autoCompletePresenters = (debouncedSearchText && !selectedPresenterId) ? presenters
        .filter((presenter) => !selectedPresenterIds.includes(presenter.presenterId))
        .filter((presenter) => 
        (presenter.name.toLowerCase().indexOf(debouncedSearchText.trim().toLowerCase()) > -1)
        || (presenter.boothAssignment && (presenter.boothAssignment?.toLowerCase().indexOf(debouncedSearchText.trim().toLowerCase()) > -1)))
    : [];

    return (
        <div className="position-relative" onBlur={onBlur}>
            {isLoading && <Loader className="postion-absolute" />}
            <Row className="mt-2">
                <Col>
                    <Row className="align-items-end">
                        <Col>
                            {selectedPresenterIds.map((presenterId) => {
                                const presenter = presenters.find(presenter => presenter.presenterId === presenterId);
                                return (
                                    <Badge key={`presenterBadge-${presenterId}`} 
                                            bg="" 
                                            text="dark" 
                                            className="customer-badge me-2"
                                            onClick={() => setPresenterToRemove(presenter)}>
                                        <span className="me-2">{presenter?.name}</span>
                                        <CloseButton />
                                    </Badge>
                            )})}
                            <div className="d-flex">
                                <div className="w-100 me-2">
                                    <input id="presenterSelectSearchInput" className="form-control" value={search} onChange={(event) => setSearch(event.target.value)} />
                                    {!!autoCompletePresenters.length &&
                                    <div className="position-relative">
                                        <ul className="bg-white mt-2 shadow rounded list-group w-100" style={{position: 'absolute', top: 0, maxHeight: 200, overflowY: 'auto'}}>
                                            {autoCompletePresenters.map((presenter) => 
                                                <li className="list-group-item cursor-pointer mb-group-item" key={`presenterOption-${presenter.presenterId}`} onClick={() => presenterSelected(presenter)}>
                                                    {presenter.name}
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    }
                                </div>
                                <Button className="mb-button pb-0 presenter-add-button" variant="" size="sm" onClick={addSelectedPresenter}>
                                    <MBIcon color="text-charcoal-700" icon="add" />
                                </Button>
                            </div>
                        </Col>
                    </Row>    
                </Col>
            </Row>
            {!!presenterToRemove &&
            <ConfirmationModal 
                message={`Are you sure you want to remove the Presenter - ${presenterToRemove.name}?`}
                show={true}
                mbModalProps={{
                    title: 'Delete Presenter',
                    actionBtnText: "Delete",
                    size: Size.LARGE,
                    isCloseButtonDisplayed : false,
                    onModalSubmit: () => removeSelectedPresenter(),
                    onModalCancel: () => {
                        setPresenterToRemove(null);
                        return Promise.resolve(true);
                    },
                    isActionBtnDisabled: undefined}} />
            }
        </div>
    );
}

export default PresenterSelector;