import React from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import IdentityDisplay from '../app/identityDisplay';
import { Session } from '../../@types/session';
import { LikedFeature, LikedSession, Note } from '../../@types';
import FeatureNote from '../note/featureNote';
import { SESSION_FEATURE_KEY } from '../../service/session/sessionService';
import MBIcon from '../app/mbIcon';
import MBLabelGroup from '../app/mbLabelGroup';
import { Size } from '../../util/bootstrapUtil';

export interface SessionNoteProps {
    session:Session,
    likedSessions:LikedSession[],
    likedSessionFeatures:LikedFeature[],
    sessionNotes:Note[],
    sessionFeatureNotes:Note[]
}

const SessionNote:React.FC<SessionNoteProps> = (props) => {
    const {session} = props;

    const isLiked = !!props.likedSessions.find((likedSession) => likedSession.isLiked && (session.sessionId === likedSession.sessionId));

    const features = session.features?.filter((feature) => 
        !!props.likedSessionFeatures.find((likedFeature) => likedFeature.isLiked && (feature.featureId === likedFeature.featureId))
        || !!props.sessionFeatureNotes.find((featureNote) => feature.featureId === featureNote.featureId));

    const sessionNote = props.sessionNotes.find((note) => session.sessionId === note.sessionId);
    const sessionClass = !features?.length
        ? 'empty-accordion'
        : '';

    return (
        <Accordion.Item eventKey={session.sessionId.toString()} className="border mb-2">
            <Accordion.Header as="div" className={`session-note-header ${sessionClass}`}>
                <Row className="m-0 align-items-center flex-grow-1">
                    <Col xs="auto">
                        <IdentityDisplay 
                                entityId={session.sessionId} 
                                size="sm" 
                                logoUrl={session.thumbnailUrl} 
                                name={session.name} 
                                iconColor={session.color} />
                    </Col>
                    <Col>
                        <div  className="d-flex">
                            <h5 className="m-0 theme-name">{session.name}</h5>
                            {isLiked && 
                            <MBIcon icon="thumb_up" 
                                    color='text-primary'
                                    size={Size.EXTRA_LARGE}
                                    className="ms-2" />
                            }
                        </div>
                        {!!sessionNote &&
                        <div>
                            {!!sessionNote.customerIds?.length &&
                            <MBLabelGroup id={`sessionNote-${session.sessionId}-customer`} 
                                    label="Customers">
                                {sessionNote.customerIds.join(', ')}
                            </MBLabelGroup>
                            }
                            {!!sessionNote.note?.length &&
                            <MBLabelGroup id={`sessionNote-${session.sessionId}-note`} 
                                    label="Note">
                                {sessionNote.note}
                            </MBLabelGroup>
                            }
                        </div>
                        }
                    </Col>
                </Row>
            </Accordion.Header>
            {!!features?.length &&
            <Accordion.Body className="theme-note-list">
                {features.map((feature) => {
                    const featureNote = props.sessionFeatureNotes.find((featureNote) => feature.featureId === featureNote.featureId) ?? null;
                    const isLiked = !!props.likedSessionFeatures.find((likedFeature) => likedFeature.featureId === feature.featureId)?.isLiked ?? false;
                    return <FeatureNote 
                            key={feature.featureId.toString()} 
                            feature={feature} 
                            note={featureNote} 
                            isLiked={isLiked}
                            featureType={SESSION_FEATURE_KEY} />
                })}
            </Accordion.Body>
            }
        </Accordion.Item>
    );
};

export default SessionNote;