import React from 'react'
import MBModal from "../modal/mbModal";
import {useAppDispatch, useAppSelector} from '../../hook/appHook';
import {useAccessToken} from '../../hook/accessTokenHook';
import {createDsrConsentRequest} from '../../state/action/user/userAction';
import { USER_TYPE_CUSTOMER } from '@martinbros/component';

const DsrAlertModal: React.FC<any> = () => {
    const accessToken = useAccessToken();
    const dispatch = useAppDispatch();

    const {isShared} = useAppSelector((state) => state.UserState);
    const {selectedShow} = useAppSelector((state) => state.ShowState);
    const {appUser} = useAppSelector((state) => state.AppState);
    const showName = selectedShow?.name ?? '';
    const isShowModal = appUser?.userType === USER_TYPE_CUSTOMER
        && selectedShow?.isActive
        && selectedShow?.isOpen
        && isShared == null;

    const createDsrConsent = async (isShared: boolean): Promise<boolean> => {
        return new Promise((resolve) => {
            dispatch(
                createDsrConsentRequest(accessToken, isShared, {
                    onSuccess: () => resolve(true),
                    onError: () => resolve(false),
                })
            );
        });
    };
    
    if (!isShowModal) {
        return null;
    }

    return (

        <MBModal
            show={true}
            mbModalProps={{
                title: `Welcome to ${showName}`,
                isCancelButtonDisplayed: true,
                isCloseButtonDisplayed: false,
                size: 'lg',
                actionBtnText: 'Share My Info',
                cancelBtnText: 'Do Not Share',
                onModalSubmit: () => createDsrConsent(true),
                onModalCancel: () => createDsrConsent(false),
            }}
            className="follow-up-confirmation-modal"
        >
            <p>
                Use this site to identify and make notes about showcased items and other content that you like.
                You’ll receive a follow-up email with all this information after the event.
            </p>
            <p>
                Your Sales Rep can help you follow up on the items you liked.
                Do you want to share this information with them as well?
            </p>
        </MBModal>
    );
}

export default DsrAlertModal;