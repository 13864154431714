import React from 'react';
import { CeuHours } from '../../@types/session';

export interface AttendeeCeuCreditProps {
    isAttended:boolean,
    creditHours:CeuHours
}

const AttendeeCeuCredit:React.FC<AttendeeCeuCreditProps> = ({creditHours, isAttended}) => {
    const isGeneralCreditPresent = !!creditHours.general;
    const isSanitationCreditPresent = !!creditHours.sanitation;
    
    const isCreditPresent = isGeneralCreditPresent
        || isSanitationCreditPresent 
        || !!creditHours.exhibit;    

    if (!isCreditPresent) {
        return null;
    }

    return (
        <div className="user-ceu-credits-container">
            <h4>CEU Credits {isAttended ? 'Earned' : 'Scheduled'}</h4>
            {(isGeneralCreditPresent || isSanitationCreditPresent) &&
            <p>
                {isGeneralCreditPresent &&
                <span>General Seminars: {creditHours.general}</span>
                }
                {isGeneralCreditPresent && isSanitationCreditPresent &&
                <span className="mx-2">|</span>
                }
                {isSanitationCreditPresent &&
                <span>Sanitation Seminars: {creditHours.sanitation}</span>
                }
            </p>
            }
            {!!creditHours.exhibit &&
            <p>
                <span>
                    Total Credit for Time in Chef Demos/Food Show Exhibits: {creditHours.exhibit}
                </span>
            </p>
            }
        </div>
    );
};

export default AttendeeCeuCredit;