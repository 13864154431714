import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Attendee } from '../../@types/attendee';
import {
    NOW_SHOWING_ALL,
    NOW_SHOWING_CHECKED_IN,
    NOW_SHOWING_NOT_CHECKED_IN,
    NowShowing,
} from '../../service/attendee/AttendeeService';
import MBLabelGroup from '../app/mbLabelGroup';
import AttendeeBlock from './AttendeeBlock';

interface AttendeeListProps {
    attendees: Attendee[];
    onModifyCheckIn: (email: string, isChecked: boolean) => any;
    isCheckInModifiable: boolean;
    filterText: string;
    onFilterTextChange: (filterText: string) => any;
    nowShowing: NowShowing;
    onNowShowingChange: (nowShowing: NowShowing) => any;
    isLoading: boolean;
}

const AttendeeList: React.FC<AttendeeListProps> = ({
    attendees,
    onModifyCheckIn,
    isCheckInModifiable,
    filterText,
    onFilterTextChange,
    nowShowing,
    onNowShowingChange,
    isLoading,
}) => {
    const hasFilterCriteria = !!filterText.trim() || nowShowing !== NOW_SHOWING_ALL;

    return (
        <>
            <Row className="border-bottom border-light gx-0 pb-2 pb-md-0 mb-3">
                <Col>
                    <h2>Attendee List</h2>
                </Col>
                <Col sm={12} md={9} lg={8} xl={6} xxl={4}>
                    <Row>
                        <Col>
                            <MBLabelGroup
                                id="attendeesNowShowing"
                                label="Now Showing"
                                isBlock={false}
                                htmlFor="attendeesNowShowingDropdown"
                                labelClasses="align-self-center"
                            >
                                <Form.Select
                                    id="attendeesNowShowingDropdown"
                                    onChange={(event) => onNowShowingChange(event.target.value as NowShowing)}
                                    value={nowShowing}
                                >
                                    <option value={NOW_SHOWING_ALL}>All Attendees</option>
                                    <option value={NOW_SHOWING_CHECKED_IN}>Checked In</option>
                                    <option value={NOW_SHOWING_NOT_CHECKED_IN}>Not Checked In</option>
                                </Form.Select>
                            </MBLabelGroup>
                        </Col>
                        <Col xxs={12} xs={12} sm={4}>
                            <Form.Control
                                type="search"
                                placeholder="Search attendees..."
                                aria-label="Filter"
                                value={filterText}
                                onChange={(event) => onFilterTextChange(event.target.value)}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="g-4">
                <Col sm={12}>
                    {!isLoading && !attendees.length && (
                        <Row className="gx-0 pb-2 pb-md-0 mb-3">
                            <Col>
                                <h2>
                                    No attendees{' '}
                                    {hasFilterCriteria
                                        ? 'match the specified criteria'
                                        : 'are registered for this event'}
                                </h2>
                            </Col>
                        </Row>
                    )}
                    {!!attendees.length &&
                        attendees.map((attendee) => (
                            <AttendeeBlock
                                key={`attendee_${attendee.email}`}
                                attendee={attendee}
                                onModifyCheckIn={onModifyCheckIn}
                                isCheckInModifiable={isCheckInModifiable}
                            />
                        ))}
                </Col>
            </Row>
        </>
    );
};

export default AttendeeList;
