import React, {HTMLProps, useState} from 'react';
import {Presenter} from '../../@types';
import {useAccessToken} from '../../hook/accessTokenHook';
import {useAppDispatch, useAppSelector} from '../../hook/appHook';
import {Button} from 'react-bootstrap';
import MBModal from '../modal/mbModal';
import {cancelPresenterFollowupRequest, createPresenterFollowupRequest} from '../../state/action/user/userAction';

interface PresenterFollowupProps extends HTMLProps<HTMLDivElement> {
    presenter: Presenter;
}

const PresenterFollowup: React.FC<PresenterFollowupProps> = (props) => {
    const {presenter} = props;

    const accessToken = useAccessToken();

    const dispatch = useAppDispatch();

    const [isConfirmFollowupRequest, setConfirmFollowupRequest] = useState(false);
    const [isConfirmCancelFollowup, setConfirmCancelFollowup] = useState(false);

    const {followupRequests} = useAppSelector((state) => state.UserState);
    const hasFollowupRequests = !!followupRequests.length;
    const followupRequestForPresenter = followupRequests.find(
        (request) => request.presenterId === presenter?.presenterId
    );

    const createFollowupRequest = ():Promise<boolean> => {
        return new Promise((resolve) => {
            dispatch(
                createPresenterFollowupRequest(accessToken, presenter?.presenterId, {
                    onSuccess: () => resolve(true),
                    onError: () => resolve(false),
                })
            );
        });
    };

    const cancelFollowupRequest = ():Promise<boolean> => {
        return new Promise((resolve) => {
            dispatch(
                cancelPresenterFollowupRequest(accessToken, presenter?.presenterId, {
                    onSuccess: () => resolve(true),
                    onError: () => resolve(false),
                })
            );
        });
    };

    return (
        <div className={props.className}>
            <div>
                {!followupRequestForPresenter && 
                    <Button className="mb-button request-follow-up-button"
                            onClick={() => hasFollowupRequests
                                ? createFollowupRequest()
                                : setConfirmFollowupRequest(true)}>
                        Request Follow Up
                    </Button>
                }
                {!!followupRequestForPresenter && 
                    <div>
                        <p>Follow-Up Requested</p>
                        <Button className="mb-button cancel-follow-up-button"
                                onClick={() => setConfirmCancelFollowup(true)}>
                            Cancel Follow Up
                        </Button>
                    </div>
                }
            </div>
            <MBModal show={isConfirmFollowupRequest}
                    mbModalProps={{
                        title: 'Request Follow Up',
                        isCancelButtonDisplayed: true,
                        isCloseButtonDisplayed: true,
                        actionBtnText: 'Share My Info',
                        size: 'lg',
                        onModalSubmit: createFollowupRequest,
                    }}
                    onHide={() => setConfirmFollowupRequest(false)}
                    className="follow-up-confirmation-modal">
                <div>
                    <p>
                        You are consenting to share your contact information and item likes/dislikes with this Vendor so
                        they can follow up with you directly.
                    </p>
                    <p>All information will be shared the day after the event.</p>
                </div>
            </MBModal>
            <MBModal show={isConfirmCancelFollowup}
                    onHide={() => setConfirmCancelFollowup(false)}
                    className="follow-up-cancel-modal"
                    mbModalProps={{
                        title: 'Cancel Follow Up',
                        isCancelButtonDisplayed: true,
                        isCloseButtonDisplayed: true,
                        actionBtnText: 'Do Not Share',
                        size: 'lg',
                        onModalSubmit: cancelFollowupRequest,
                    }}>
                <div>
                    <p className="fw-600">Do you want to cancel your follow-up request?</p>
                    <p>Your contact information will not shared with the Vendor after the event.</p>
                </div>
            </MBModal>
        </div>
    );
}

export default PresenterFollowup;