import React from 'react';
import { Link } from 'react-router-dom';
import { Session } from '../../@types/session';

interface SessionLinkProps {
    session: Session;
}

const SessionLink: React.FC<SessionLinkProps> = ({ session}) => {
    return (
        <Link className="session-link" to={`/session-detail/${session.sessionId}`}>
            <span className="d-inline-block">{session.name} ({session.location})</span>
        </Link>
    );
};

export default SessionLink;