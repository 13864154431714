import { ApiResponseHandler, ApiTokenResponse, Nullable, User } from '../../@types';
import UserApi from '../../api/user/userApi';
import UserDao from '../../persistence/storage/userDao';
import { handleApiResponse } from '../../util/apiUtil';
import CookieService from './cookieService';

class UserService {
    public async getUserDetails(token: string, showId: number, handler: ApiResponseHandler<User>): Promise<void> {
        const response = await UserApi.fetchUserDetails(token, showId);
        handleApiResponse(response, handler);
    }

    public getLastUserTokens(): Nullable<ApiTokenResponse> {
        return CookieService.getUserTokens();
    }

    getEmailClientPreference():Nullable<string> {
        return UserDao.retrieveEmailClientPreference();
    }

    getRedirectPath():Nullable<string> {
        return UserDao.retrieveRedirectPath();
    }

    public retrievePersistedSelectedShow() {
        return UserDao.retrievePersistedSelectedShow();
    }

    public saveUserAccessToken(accessToken:string) {
        CookieService.saveAccessToken(accessToken);
    }

    saveEmailClientPreference(preference:string) {
        if (!preference) {
            return;
        }

        UserDao.persistEmailClientPreference(preference);
    }

    saveRedirectPath(path:string) {
        return UserDao.persistRedirectPath(path);
    }
    
    public saveLastSelectedShow(showId:number) {
        if (showId) {
            UserDao.persistSelectedShow(showId);
        }
    }

    removeRedirectPath() {
        UserDao.removeRedirectPath();
    }
}

export default new UserService();