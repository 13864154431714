import { ApiResponse, FileUploadRequest } from "../../@types";
import Api from "../api";

class MediaApi {
    public async postFile(token:string, request:FileUploadRequest):Promise<ApiResponse> {
        const response = await Api.performPOSTRequest(`media/file`, request, token);
        return response;
    }
 }

export default new MediaApi();