import React from 'react'
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MBIcon from '../app/mbIcon';
import { Size } from '../../util/bootstrapUtil';

export interface MobileFooterLinkProps {
    className?:string,
    onClick?:() => void,
    route:string,
    icon:string,
    label:string
}

const MobileFooterLink:React.FC<MobileFooterLinkProps> = (props) => {
    
    return (
        <Col className={props.className}>
            <Link to={props.route} onClick={props.onClick} className="nav-link">
                <div>
                    <MBIcon icon={props.icon}
                            size={Size.EXTRA_LARGE} 
                            color="text-white" />
                </div>
                <div className="fs-sm">
                    {props.label}
                </div>
            </Link>
        </Col>
    );
}

export default MobileFooterLink;