import React, { useState } from 'react';
import { Alert, Col, Container, Form, Row } from 'react-bootstrap';
import { formatAtCentralTime } from '../../util/DateTimeUtil';
import { Session, SessionTimeSlot } from '../../@types/session';
import WalkInRegistrationButton from './WalkInRegistrationButton';
import MBIcon from '../app/mbIcon';
import { TimeSlotUser } from '../../@types/session';
import { useAccessToken } from '../../hook/accessTokenHook';
import { useAppSelector } from '../../hook/appHook';
import { SessionUserAttendanceRequest } from '../../@types/session';
import { ApiResponseHandler, Nullable } from '../../@types';
import SessionService from '../../service/session/sessionService';
import { SessionUserRegistrationRequest } from '../../@types/session';
import MBButtonLink from '../app/input/mbButtonLink';
import Loader from '../app/loader';
import { Size, Variant } from '../../util/bootstrapUtil';
import MBModal from '../modal/mbModal';

export interface SessionSlotAttendeeListProps {
    session:Session,
    timeSlot:SessionTimeSlot,
    isAttendanceManageable:boolean,
    onUserAttendanceModified:(time:string, timeSlotUsers:TimeSlotUser[]) => void,
    onWalkinUserRegistered:(timeSlotUsers:TimeSlotUser[]) => void
}

const SessionSlotAttendeeList:React.FC<SessionSlotAttendeeListProps> = (props) => {
    const registeredUsers = props.timeSlot.users;
    
    registeredUsers.sort((user1, user2) => {
        const username1 = user1.name.toLowerCase();
        const username2 = user2.name.toLowerCase();
        return (username1 < username2)
            ? -1
            : (username1 > username2)
                ? 1
                : 0
    });

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Nullable<string>>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState<Nullable<TimeSlotUser>>(null);

    const accessToken = useAccessToken();
    const showId = useAppSelector((state) => state.ShowState.selectedShow?.showId ?? 0);

    function onUserAttendanceChange(user:TimeSlotUser) {
        if(!showId || !accessToken) {
            return;
        }

        const request:SessionUserAttendanceRequest = {
            accessToken: accessToken,
            showId: showId,
            sessionId: props.session.sessionId,
            email: user.email,
            time: props.timeSlot.time,
            isAttended: !user.isAttended
        };

        const handler: ApiResponseHandler<TimeSlotUser[]> = {
            onSuccess: (timeSlotUsers) => {
                props.onUserAttendanceModified(props.timeSlot.time, timeSlotUsers);
            },
            onError: (error) => setError(error),
            onComplete: () => setIsLoading(false),
        };

        setIsLoading(true);
        SessionService.saveSessionUserAttendance(request, handler)
    }

    function prepareAttendeeRemoval(user:TimeSlotUser) {
        setUserToDelete(user);
        setIsDeleteModalOpen(true);
    }

    async function removeAttendee():Promise<boolean> {
        if (!accessToken || !userToDelete) {
            return true;
        }


        const request:SessionUserRegistrationRequest = {
            sessionId : props.session.sessionId,
            name : userToDelete.name,
            email : userToDelete.email,
            time : props.timeSlot.time,
            accessToken : accessToken,
            showId : showId
        };

        let isSuccess = false;
        const handler:ApiResponseHandler<TimeSlotUser[]> = {
            onSuccess : (users) => {
                props.onUserAttendanceModified(props.timeSlot.time, users);
                isSuccess = true;
            },
            onError : (message) => setError(message),
            onComplete : () => setIsLoading(false)
        };

        setIsLoading(true);
        await SessionService.deleteSessionUserRegistration(request, handler);
        return isSuccess;
    }
    
    return (
        <Container fluid className="gx-4 gx-lg-5 mb-3">
            {isLoading && <Loader />}
            {!!error && 
            <Alert variant={Variant.DANGER} 
                    onClose={() => setError(null)} 
                    dismissible>
                {error}
            </Alert>
            }
            <Row className="border-bottom gx-0 pb-2 pb-md-0 mb-3">
                <Col md="auto" className='mt-2'>
                    <h2>Session Attendance</h2>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col>
                    <div className="d-flex flex-row align-items-center">
                        <h4 className="me-4">
                            Time Slot - {formatAtCentralTime(new Date(props.timeSlot.time))} Central
                        </h4>
                        <WalkInRegistrationButton sessionId={props.session.sessionId} 
                                sessionTime={props.timeSlot.time}
                                onRegistration={props.onWalkinUserRegistered} />
                    </div>
                </Col>
            </Row>
            <Row>
                {!!registeredUsers.length && registeredUsers.map(registeredUser => 
                    <Col key={`time-slot-attendance-${registeredUser.email}`} 
                            lg={4}
                            md={6}
                            style={{minWidth: 240}}>
                        {!props.isAttendanceManageable && `${registeredUser.name} - ${registeredUser.email}`}
                        {props.isAttendanceManageable &&
                        <div className="d-flex align-items-center">
                            <Form.Check type="checkbox"
                                    id={`registerUser-${registeredUser.email}`}
                                    name="timeSlotGroup"
                                    checked={registeredUser.isAttended}
                                    onChange={(event) => onUserAttendanceChange(registeredUser)}
                                    label={`${registeredUser.name} - ${registeredUser.email}`} />
                            <MBButtonLink className="pt-1 ms-2" 
                                    onClick={() => prepareAttendeeRemoval(registeredUser)}>
                                <MBIcon icon="close" color="text-red" />
                            </MBButtonLink>
                        </div>
                        }
                    </Col>
                )}
            </Row>
            {isDeleteModalOpen &&
            <MBModal onHide={() => setIsDeleteModalOpen(false)}
                    show={true}
                    mbModalProps={{
                        title: 'Remove Attendee',
                        actionBtnText: 'Delete',
                        size: Size.SMALL,
                        onModalSubmit: () => removeAttendee()}}>
                Are you sure you want to unregister <strong>{userToDelete?.name ?? ''}</strong> from the session timeslot?
            </MBModal>
            }
        </Container>
    );
};

export default SessionSlotAttendeeList;