import { ApiResponse } from "../../@types";
import Api from "../api";

class PresenterApi {
    public async fetchPresenterList(token:string, showId:number):Promise<ApiResponse> {
        const response = await Api.performAwsGETRequest(`presenters/${showId}`, token);
        return response;
    }
}

export default new PresenterApi();