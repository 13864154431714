import React, { useState } from 'react';
import PresenterSelector from './presenterSelector';
import { useAppSelector } from '../../hook/appHook';
import Loader from '../app/loader';
import MBLabelGroup from '../app/mbLabelGroup';
import PresentedByLink from './presentedByLink';
import { Button, Col, Row } from 'react-bootstrap';

const IMAGE_PENCIL = require("../../images/pencil.png");

export interface TogglePresenterSelectorProps {
    selectedPresenters : number[],
    onPresenterSelected : (presenterId:number) => void,
    onPresenterRemoved : (presenterId:number) => void,
    isDisabled?: boolean,
    onEditFieldChanged?: (isInFocus:boolean) => void
}

const TogglePresenterSelector:React.FC<TogglePresenterSelectorProps> = (props) => {
    const {
        selectedPresenters,
        onPresenterSelected,
        onPresenterRemoved,
        isDisabled
    } = props;

    const {isLoading, presenters} = useAppSelector((state) => state.PresenterState);
    const [isInputDisplayed, setIsInputDisplayed] = useState(false);

    return (
        <div>
            {isLoading && <Loader className="postion-absolute" />}
            <MBLabelGroup label="Presented By" id="presenterSelectContainer">
                {!isInputDisplayed &&
                    <Row>
                        <Col className="align-items-center" md="auto">
                            {!selectedPresenters?.length && <span>Martin Bros</span>}
                            {!!selectedPresenters?.length && 
                                <div>
                                    {selectedPresenters.map((presenterId) => {
                                        const itemPresenter = presenters.find(presenter => presenter.presenterId === presenterId);
                                        if(!itemPresenter) {
                                            return null;
                                        }

                                        return <div key={`sessionPresenterLink_${itemPresenter.presenterId}`}>
                                            <PresentedByLink presenter={itemPresenter} />
                                        </div>
                                    })}
                                </div>
                            }
                        </Col>
                        <Col>
                            <Button 
                                variant="link"
                                className={`${!!isDisabled ? 'd-none' : ''}`}
                                onClick={(event) => setIsInputDisplayed(true)}
                            >
                                <img src={IMAGE_PENCIL} alt="Edit Presenters" height={18} />
                            </Button>
                        </Col>
                    </Row>
                }
                {!!isInputDisplayed &&
                    <PresenterSelector 
                        selectedPresenters={selectedPresenters} 
                        onPresenterSelected={onPresenterSelected} 
                        onPresenterRemoved={onPresenterRemoved} />
                }
            </MBLabelGroup>
        </div>
    );
}

export default TogglePresenterSelector;