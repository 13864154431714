import React from 'react';
import { Nullable } from '../../@types';
import { Session } from '../../@types/session';
import MBLabelGroup from '../app/mbLabelGroup';
import SessionLink from '../session/sessionLink';

interface PresenterSessionListProps {
    presenterSessions: Nullable<Session[]>;
}

const PresenterSessionList: React.FC<PresenterSessionListProps> = ({ presenterSessions }) => {
    if (!presenterSessions?.length) {
        return null;
    }

    presenterSessions?.sort((sessionA, sessionB) => {
        const nameA = sessionA.name;
        const nameB = sessionB.name;

        if (nameA < nameB) {
            return -1;
        }

        if (nameA > nameB) {
            return 1;
        }

        return 0;
    });

    return (
        <MBLabelGroup id="sessionPresenterList" label="Sessions" className="fw-600">
            {presenterSessions.map((session) => 
                <div key={`sessionPresenterLink_${session.sessionId}`}>
                    <SessionLink session={session} />
                </div>
            )}
        </MBLabelGroup>
    );
}

export default PresenterSessionList;