import { ApiResponseHandler, Nullable } from "../../@types";
import { handleApiResponse } from "../../util/apiUtil";
import B2BCustomerApi from "../../api/user/b2bUserApi";
import { REQUIRED_TOKEN_MESSAGE } from "../../api/api";
import { ApiResponse } from "../../@types";
import { PermissionSet } from "../../constants/PermissionSet";

class B2BUserService {
    public async getUserCustomers(
            token:Nullable<string>, 
            customerId:number, 
            isBillToOnlyIncluded:boolean,
            handler:ApiResponseHandler<any>):Promise<void> {
        if (!token || !customerId) {
            handleApiResponse({data: null, error: REQUIRED_TOKEN_MESSAGE}, handler);
            return;
        }

        const response = await B2BCustomerApi.fetchUserCustomers(token, customerId, isBillToOnlyIncluded);
        handleApiResponse(response, handler);
    }

    public async getUserPermissions(token: Nullable<string>, handler: ApiResponseHandler<PermissionSet[]>): Promise<void> {
        if (!token) {
            const error: ApiResponse = {data: null, error: REQUIRED_TOKEN_MESSAGE};
            handleApiResponse(error, handler);
            return;
        }
        const response = await B2BCustomerApi.fetchUserPermissions(token);
        handleApiResponse(response, handler);
    }
}

export default new B2BUserService();