import { ApiResponse, ItemSearchRequest } from "../../@types";
import Api from "../api";

const ITEM_BASE = 'item-search';

class ItemApi {
    public async searchItems(token:string, request:ItemSearchRequest):Promise<ApiResponse> {
        const response = await Api.performPOSTRequest(ITEM_BASE, request, token);
        return response;
    }
}

export default new ItemApi();