import React, { CSSProperties, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Image from '../component/app/image';
import Loader from '../component/app/loader';
import { buildLoginUrl } from '../config/configuration';
import { useAppSelector } from '../hook/appHook';

const LOGIN_URL = buildLoginUrl();

const Login: React.FC<{}> = () => {
    const { isLoading, isInitialized, isLoginFailed } = useAppSelector((state) => state.AppState);

    const isLoginNeeded = isLoginFailed || (!isLoading && isInitialized);
    useEffect(() => {
        if (isLoginNeeded) {
            window.location.href = LOGIN_URL;
        }
    }, [isLoginNeeded]);

    const containerStyle: CSSProperties = {
        backgroundColor: 'black',
        height: '100vh',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    };

    return (
        <div id="loginContainer" style={containerStyle}>
            {isLoading && <Loader />}
            <Container className="h-100">
                {!isInitialized && (
                    <Row className="text-center d-flex align-items-center h-100">
                        <Col>
                            <Image src="https://cdn.martinbros.com/content/m_badge_logo_300.png" alt="MB Logo" />
                            <div className="mt-4">
                                {isLoading && !isInitialized && (
                                    <div className="my-3 text-white">Please wait while we gather your data</div>
                                )}
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </div>
    );
};

export default Login;
