import React from 'react'
import { UserNotes, Likes, Presenter, UserPresenterFollowup } from '../../@types';
import PresenterNotes from '../note/presenterNotes';

export interface PresenterNoteListProps {
    presenters : Presenter[],
    notes : UserNotes,
    likes : Likes,
    followupRequests : UserPresenterFollowup[]
}

const PresenterNoteList:React.FC<PresenterNoteListProps> = ({presenters, notes, likes, followupRequests}) => {
    
    return (
        <>
            {presenters.map((presenter) => {
                const featureNotes = notes.presenterFeatureNotes.filter((featureNote) => presenter.features.find((feature) => feature.featureId === featureNote.featureId));
                const presenterItemNotes = notes.itemNotes.filter((itemNote) => presenter.itemNumbers.includes(itemNote.itemNumber ?? ''));
                const hasFollowupRequest = !!followupRequests.find((followup) => followup.presenterId === presenter.presenterId);
                return <PresenterNotes 
                                key={presenter.presenterId} 
                                presenter={presenter} 
                                featureNotes={featureNotes}
                                itemNotes={presenterItemNotes}
                                likes={likes}
                                hasFollowupRequest={hasFollowupRequest}/>
            })}
        </>
    );
}

export default PresenterNoteList;