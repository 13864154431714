import { ApiResponseHandler, Presenter } from "../../@types";
import PresenterApi from "../../api/presenter/presenterApi";
import { handleApiResponse } from "../../util/apiUtil";

export const PRESENTER_FEATURE_KEY = 'presenter';

class PresenterService {
    public async getPresenterList(token:string, showId:number, handler:ApiResponseHandler<Presenter[]>):Promise<void> {
        const response = await PresenterApi.fetchPresenterList(token, showId);
        handleApiResponse(response, handler);  
    }
}

export default new PresenterService();