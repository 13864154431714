import { ApiResponseHandler, AppThunk, Presenter, Show, Theme } from "../../../@types";
import PresenterService from "../../../service/presenter/presenterService";
import ThemeService from "../../../service/theme/themeService";
import UserService from "../../../service/user/userService";
import { setRedirectPath } from "../../app/appState";
import { fetchPresenterList, fetchPresenterListFailure, fetchPresenterListSuccess } from "../../presenter/presenterState";
import { initializeShow, initializeShowSuccess, updateSelectedShow } from "../../show/showState";
import { fetchThemeList, fetchThemeListSuccess, fetchThemeListFailure } from "../../theme/themeState";
import { userShowChange } from "../../user/userState";
import { isShowSelectable } from "../appAction/appAction";

export function updateSelectedEvent(show:Show, token:string):AppThunk {
    return(dispatch) => {
        if (show?.showId) {
            isShowSelectable(show, token)
            .then((isSelectable) => {
                if (isSelectable) {
                    dispatch(updateSelectedShow(show));
                    dispatch(userShowChange());
                    UserService.saveLastSelectedShow(show.showId);
                }
            });
        }
    }
}

export function initializeShowAction(token:string):AppThunk {
    return (dispatch, getState) => {
        const showId = getState().ShowState.selectedShow?.showId;
        if (!showId) {
            return;
        }

        dispatch(initializeShow());
        dispatch(fetchPresenterList());
        dispatch(fetchThemeList());

        const presenterPromise = new Promise((resolve) => {
            const handler:ApiResponseHandler<Presenter[]> = {
                onSuccess : (presenters) => dispatch(fetchPresenterListSuccess(presenters)),
                onError : (error) => dispatch(fetchPresenterListFailure()),
                onComplete : () => resolve(null)
            };

            PresenterService.getPresenterList(token, showId, handler);
        });

        const themePromise = new Promise((resolve) => {
            const handler:ApiResponseHandler<Theme[]> = {
                onSuccess : (themes) => dispatch(fetchThemeListSuccess(themes)),
                onError : (error) => dispatch(fetchThemeListFailure()),
                onComplete : () => resolve(null)
            };

            ThemeService.getThemeList(token, showId, handler);
        });

        const redirectPath = UserService.getRedirectPath();
        if (redirectPath?.length) {
            // it may need to be readded to state in the event
            // an employee needed to login first
            // we cannot redirect until a show has been selected
            dispatch(setRedirectPath(redirectPath));
        }

        Promise.all([presenterPromise, themePromise])
        .then(() => dispatch(initializeShowSuccess()));
    }
}