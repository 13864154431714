import { createSlice } from "@reduxjs/toolkit";
import { ShowState } from "../../@types";

const INITIAL_STATE:ShowState = {
    isLoading: false,
    isInitialized : false,
    selectedShow : null
};

const showSlice = createSlice({
    name: 'show',
    initialState: INITIAL_STATE,
    reducers: {
        initializeShow: (state) => {
            state.isLoading = true;
        },
        initializeShowSuccess: (state) => {
            state.isLoading = false;
            state.isInitialized = true;
        },
        updateSelectedShow: (state, {payload}) => {
            state.isInitialized = false;
            state.selectedShow = payload;
        }
    }
});

export const {initializeShow, initializeShowSuccess, updateSelectedShow} = showSlice.actions;
export default showSlice.reducer;