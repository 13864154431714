import React, { useEffect, useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import { ApiResponseHandler, Item, ItemSearchRequest, Note, Nullable } from "../../@types";
import { useAccessToken } from "../../hook/accessTokenHook";
import { useAppSelector } from "../../hook/appHook";
import ItemService from "../../service/item/itemService";
import Image from "../app/image";
import Loader from "../app/loader";
import MBIcon from "../app/mbIcon";
import MBLabelGroup from "../app/mbLabelGroup";
import NoteCard from "./noteCard";
import { Size } from "../../util/bootstrapUtil";

export interface NoteListProps {
    itemNumber : string,
    itemNote: Nullable<Note>,
    isLiked : boolean
}

const PresenterItemNote:React.FC<NoteListProps> = ({itemNumber, itemNote, isLiked}) => {
    const [item, setItem] = useState<Nullable<Item>>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    const accessToken = useAccessToken();

    const showId = useAppSelector((state) => state.ShowState.selectedShow?.showId ?? 0);

    useEffect(() => {
        if (!accessToken || (item != null) || isInitialized) {
            return;
        }

        const handler:ApiResponseHandler<Item[]> = {
            onSuccess : (items:Item[]) => {
                const foundItem = items.find((searchItem) => searchItem.itemNumber === itemNumber);
                if (foundItem) {
                    setItem(foundItem);
                }
            },
            onError : (error) => {},
            onComplete : () => {
                setIsLoading(false);
                setIsInitialized(true);
            }
        };

        setIsLoading(true);

        const searchRequest:ItemSearchRequest = {
            searchTerm : itemNumber,
            showId : showId
        };

        ItemService.searchItems(accessToken, searchRequest, handler);
    }, [accessToken, item, showId, itemNumber, isInitialized]);
    
    return (
        <Accordion className="mb-2" alwaysOpen={false}>
            <Accordion.Item eventKey={itemNumber} className="presenter-item">
                <Accordion.Header as="div" className={`${!!itemNote ? '' : 'hide-button'}`}>
                    {isLoading &&
                        <Loader />
                    }
                    <div className="text-center presenter-item-icons">
                        {isLiked &&
                            <MBIcon
                                icon="thumb_up"
                                color='text-primary'
                                size={Size.EXTRA_LARGE}
                                className="d-block thumbs-up" />
                        }
                    </div>
                    <Row className="m-0 align-items-center flex-grow-1">
                        <Col>
                            <Row className="align-items-center">
                                <Col xs={3} sm="auto">
                                    {!!item?.itemNumber?.length &&
                                        <Image 
                                            src={item.thumbnailUrl} 
                                            alt={item.description} 
                                            className="img-fluid"
                                            width="50" />
                                    }
                                </Col>
                                <Col xs={9} lg>
                                    <div className="item-title">{item?.description}</div>
                                    <div className="mb-label-data-row mt-2">
                                        <MBLabelGroup label="Number" id={`presenterNumber-${itemNumber}`} className="d-flex item-number">
                                            {itemNumber}
                                        </MBLabelGroup>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Accordion.Header>
                {!!itemNote &&
                    <Accordion.Body className="notes-list">
                        <NoteCard key={`ItemNote:${itemNumber}`} note={itemNote} />    
                    </Accordion.Body>
                }
            </Accordion.Item>
        </Accordion>
    )
}

export default PresenterItemNote;