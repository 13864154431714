import React, { useState } from "react";
import { Button, Container, FormControl, Nav, Navbar } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Image from '../component/app/image';
import * as AppRoutes from '../config/routes';
import { PermissionSet } from "../constants/PermissionSet";
import { useAppSelector, useIsEmployeeUser, useIsPermissionAuthorized } from "../hook/appHook";
import { SessionGroup } from "../service/session/sessionService";
import MBIcon from "./app/mbIcon";
import MBNavigationFlyout from "./flyout/mbNavigationFlyout";
import { Size } from "../util/bootstrapUtil";

const Header = () => {
    const { selectedShow } = useAppSelector((state) => state.ShowState);
    const { themes } = useAppSelector((state) => state.ThemeState);
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const navigate = useNavigate();
    const hasThemesLink = !!themes.length;
    const hasMap = !!selectedShow?.mapUrl;
    const isManageContentAuthorized = useIsPermissionAuthorized(PermissionSet.SHOW_PS_M_SITE_CONTENT);

    const hasSessions = !!selectedShow?.isSessionEnabled && (isManageContentAuthorized || !!selectedShow?.sessionCount);
    const isEmployee = useIsEmployeeUser();
    const hasAttendees = !!selectedShow?.isPublic && isEmployee;

    const showName = selectedShow?.name ?? 'Events';

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    }

    const onNavClick = () => {
        setIsNavOpen(false);
    }

    const onMenuClose = () => {
        setIsMenuOpen(false)
    };

    const onMenuOpen = () => {
        setIsMenuOpen(true);
    }

    const searchItems = () => {
        const path = `/item-list/${searchTerm}`;
        if (searchTerm?.length < 3) {
            return;
        }

        navigate(path);
    }

    const onSearchKey = (event:React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            searchItems();
        }
    }

    const onSearchModified = (event:React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    }
    
    return(
        <header className="sticky-top bg-dark">
            <Navbar bg="dark" variant="dark" expand="md" expanded={isNavOpen}>
            <Container fluid className="justify-content-between d-block d-md-flex">
                <Navbar.Brand className="d-flex">
                    <Link to="/">
                        <Image src="https://cdn.martinbros.com/content/m_badge_logo_300.png" style={{maxWidth: 30}} alt="MB Logo" className="me-2" />
                        {showName}
                    </Link>
                    <Navbar.Toggle aria-controls="navbar-nav" onClick={toggleNav}>
                        <MBIcon icon="search" size={Size.EXTRA_LARGE} color="text-white" />
                    </Navbar.Toggle>
                </Navbar.Brand>
                <div className="header-search px-3 ms-auto">
                    <FormControl
                        type="search"
                        placeholder="Search items"
                        aria-label="Search"
                        value={searchTerm}
                        onChange={onSearchModified}
                        onKeyPress={onSearchKey}
                    />
                    <Button onClick={searchItems} variant="link" className="ms-2 p-0">
                        <MBIcon icon="search" size={Size.EXTRA_LARGE} color="text-white" />
                    </Button>
                </div>
                <Nav className="ms-auto">
                    {hasSessions && 
                    <>
                        <Link to={AppRoutes.ROUTE_MY_AGENDA} onClick={onNavClick} className="nav-link header-link">My Agenda</Link>
                        <Link to={`${AppRoutes.ROUTE_SESSION_LIST}/${SessionGroup.EXPERIENCE}`} onClick={onNavClick} className="nav-link header-link">Chef Demos</Link>
                        <Link to={`${AppRoutes.ROUTE_SESSION_LIST}/${SessionGroup.SESSION}`} onClick={onNavClick} className="nav-link header-link">Sessions</Link>
                    </>
                    }
                    {hasThemesLink && 
                        <Link to={AppRoutes.ROUTE_THEME_LIST} onClick={onNavClick} className="nav-link header-link">Themes</Link>
                    }
                    <Link to={AppRoutes.ROUTE_PRESENTER_LIST} onClick={onNavClick} className="nav-link header-link">Presenters</Link>
                    {hasMap &&
                        <Link to={AppRoutes.ROUTE_MAP} onClick={onNavClick} className="nav-link header-link">Map</Link>
                    }
                    <Button onClick={onMenuOpen} variant="link" className="nav-link header-link">
                        <MBIcon icon="menu" size={Size.EXTRA_LARGE} color="text-white" />
                    </Button>
                </Nav>
                <div className="header-search-mobile">
                    <Navbar.Collapse>
                        <FormControl
                            type="search"
                            placeholder="Search items"
                            aria-label="Search"
                            value={searchTerm}
                            onChange={onSearchModified}
                            onKeyPress={onSearchKey}
                        />
                    </Navbar.Collapse>
                </div>
                {isMenuOpen &&
                    <MBNavigationFlyout onClose={onMenuClose}
                        isSessionPresent={hasSessions}
                        isThemePresent={hasThemesLink}
                        isMapPresent={hasMap}
                        isAttendeePresent={hasAttendees} />
                }
            </Container>
            </Navbar>
        </header>
    )
}

export default Header;