import React, {useCallback, useEffect, useState} from "react";
import ItemList from "../items/itemList";
import { ApiResponseHandler, Item, ItemSearchRequest, Nullable } from "../../@types";
import { Container, Col, Row, Form} from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { useAppSelector, useDebounce } from "../../hook/appHook";
import FeatureList from "../feature/featureList";
import { APP_ROOT_TITLE } from "../../App";
import ItemService from "../../service/item/itemService";
import { useAccessToken } from "../../hook/accessTokenHook";
import Loader from "../app/loader";
import ThemeService, { THEME_FEATURE_KEY } from "../../service/theme/themeService";
import IdentityDisplay from "../app/identityDisplay";
import ThemePresenterList from "./themePresenterList";
import { Session } from "../../@types/session";
import SessionSummary from "../session/sessionSummary";

const ThemeDetail = () => {
    const {id, searchTerm} = useParams();
    const themeId = parseInt(id ?? '0');

    const accessToken = useAccessToken();

    const [isLoading, setIsLoading] = useState(false);
    const [themeItems, setThemeItems] = useState<Nullable<Item[]>>(null);
    const [search, setSearch] = useState<string>(searchTerm ?? '');
    const [sessions, setSessions] = useState<Nullable<Session[]>>(null);
    
    const showId = useAppSelector((state) => state.ShowState.selectedShow?.showId ?? 0);
    const {themes} = useAppSelector((state) => state.ThemeState);
    const theme = themes.find(theme => theme.themeId === themeId);
    
    const debouncedSearch = useDebounce(search, 600);

    const searchItems = useCallback((search:string|undefined) => {
        setIsLoading(true);

        if (!theme?.themeId 
                || !showId 
                || !accessToken) {
            setIsLoading(false);
            return;
        }

        const request:ItemSearchRequest = {
            showId : showId,
            searchTerm : search ?? '',
            themeId : theme.themeId
        };

        const handler:ApiResponseHandler<Item[]> = {
            onSuccess : (items) => {
                setThemeItems(items);
            },
            onError : (error) => {
                setThemeItems([]);
            },
            onComplete : () => setIsLoading(false)
        };

        ItemService.searchItems(accessToken, request, handler);
    }, [accessToken, showId, theme]);

    useEffect(() => {
        document.title = `${APP_ROOT_TITLE} - ${theme?.name ?? 'Theme'}`;
    }, [theme]);

    useEffect(() => {
        if (!!accessToken && !!showId && !!theme && (sessions == null)) {
            const handler:ApiResponseHandler<Session[]> = {
                onSuccess: (sessions) => setSessions(sessions),
                onError: () => setSessions([])
            };

            ThemeService.getThemeSessions(accessToken, showId, theme.themeId, handler);
        }
    }, [theme, showId, accessToken, sessions]);

    useEffect(() => {
        searchItems(debouncedSearch);
    }, [searchItems, debouncedSearch])

    if (!theme) {
        return (
            <Container fluid>
                <Row>
                    <Col className="text-center">Theme could not be found</Col>
                </Row>
            </Container>
        );
    }

    const onSearchChanged = (event:React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value.toLowerCase();
        setSearch(value);
    }

    return (
        <div>
            <Container fluid className="px-3 mb-4">
                <Row className="align-items-start gl-0">
                    <Col xs="auto" className="mr-3">
                        <IdentityDisplay
                                entityId={themeId}
                                size="lg" 
                                logoUrl={theme.logoUrl}
                                iconColor={theme.color}
                                name={theme.name} />
                    </Col>
                    <Col className="mb-2 mb-sm-0 theme-name">
                        <h2 className="text-start mb-2">{theme.name}</h2>
                        <p className="theme-description">{theme.description}</p>
                        <ThemePresenterList themeItems={themeItems} />
                        {!!theme.extendedDescription &&
                        <div dangerouslySetInnerHTML={{__html: theme.extendedDescription}} />
                        }
                    </Col>
                </Row>
            </Container>

            {!!theme.features.length && 
                <Container fluid className="gx-5 mb-4">
                    <FeatureList features={theme.features} featureType={THEME_FEATURE_KEY} entityId={themeId} />
                </Container>
            }

            {!!sessions?.length &&
                <Container fluid className="gx-5 mb-4">
                    <Row xs={1} lg={2} xxl={3} className="mb-3 g-4 d-lg-flex">
                    {sessions.map((session) => 
                        <SessionSummary key={`themeSession-${session.sessionId}`} 
                                session={session}
                                time={0} />
                    )} 
                    </Row>
                </Container>
            }

            <Container fluid className="gx-4 gx-lg-5">
                <div className="position-relative">
                    <Row className="border-bottom gx-0 pb-2 pb-md-0 mb-3">
                        <Col>
                            <h2>Items</h2>
                        </Col>
                        <Col md="auto" className="justify-content-end" id="themeSearch">
                            <Form.Control type="text" defaultValue={searchTerm} onChange={onSearchChanged} placeholder={`Search theme items...`} />
                        </Col>
                    </Row>
                
                    {isLoading && 
                        <Loader />
                    }

                    {!!themeItems?.length && 
                        <ItemList isPresenterLinkDisplayed={true} items={themeItems} /> 
                    }
                    
                    {!themeItems?.length &&
                        <Row className="mt-4">
                            <Col className="text-center">
                                No Items found
                            </Col>
                        </Row>
                    }
                </div>
            </Container>
        </div>
    );
}

export default ThemeDetail;