import { AppConfig } from "../@types";

const LOCAL_CONFIG: AppConfig = {
  awsBaseUrl: "https://owgb7tr9xe.execute-api.us-east-2.amazonaws.com/api/",
  b2bBaseApiUrl: "http://localhost:8080/b2b/api/",
  cdnBaseUrl: "https://show.dev.martinbros.com/",
  userPoolId: "us-east-2_XtG4LGdfR",
  clientId: "l5ppfk7vmqpl1fegqk7ta66ci",
  employeeLoginBaseUrl: "https://authcust.dev.martinbros.com",
  b2bServerUrl: "http://localhost/",
  isProduction: false,
  isLocal: true,
  cookieDomain: 'localhost',
  environmentId: 'local',
  loginUrl: 'http://localhost:4173/',
};

const DEVELOPMENT_CONFIG: AppConfig = {
  awsBaseUrl: "https://owgb7tr9xe.execute-api.us-east-2.amazonaws.com/api/",
  b2bBaseApiUrl: "https://api.dev.martinbros.com/b2b/api/",
  cdnBaseUrl: "https://show.dev.martinbros.com/",
  userPoolId: "us-east-2_XtG4LGdfR",
  clientId: "l5ppfk7vmqpl1fegqk7ta66ci",
  employeeLoginBaseUrl: "https://authcust.dev.martinbros.com",
  b2bServerUrl: "https://customer.dev.martinbros.com/",
  isProduction: false,
  isLocal: false,
  cookieDomain: 'localhost',
  environmentId: 'dev',
  loginUrl: 'http://localhost:4173/',
};

const QA_CONFIG: AppConfig = {
  awsBaseUrl: "https://u68w56vqse.execute-api.us-east-2.amazonaws.com/api/",
  b2bBaseApiUrl: "https://api.qa.martinbros.com/b2b/api/",
  cdnBaseUrl: "https://show.qa.martinbros.com/",
  userPoolId: "us-east-2_lwIepAFPb",
  clientId: "6nn13f0au5q8ufhd5nuull46ao",
  employeeLoginBaseUrl: "https://authcust.qa.martinbros.com",
  b2bServerUrl: "https://customer.qa.martinbros.com/",
  isProduction: false,
  isLocal: false,
  cookieDomain: 'martinbros.com',
  environmentId: 'qa',
  loginUrl: 'https://login.qa.martinbros.com/',
};

const PRODUCTION_CONFIG: AppConfig = {
  awsBaseUrl: "https://50nkilh8w9.execute-api.us-east-2.amazonaws.com/api/",
  b2bBaseApiUrl: "https://api.martinbros.com/b2b/api/",
  cdnBaseUrl: "https://show.martinbros.com/",
  userPoolId: "us-east-2_am6ir3edR",
  clientId: "62j3n1rie7u4keeeb7e1ueeeil",
  employeeLoginBaseUrl: "https://authcust.martinbros.com",
  b2bServerUrl: "https://customer.martinbros.com/",
  isProduction: true,
  isLocal: false,
  cookieDomain: 'martinbros.com',
  environmentId: 'prod',
  loginUrl: 'https://login.martinbros.com/',
};

export function getConfiguration(): AppConfig {
  const environment = process.env.REACT_APP_FOOD_SHOW_ENV;
  switch (environment) {
    case "local":
      return { ...LOCAL_CONFIG };
    case "qa":
      return { ...QA_CONFIG };
    case "production":
      return { ...PRODUCTION_CONFIG };
    case "development":
    default:
      return { ...DEVELOPMENT_CONFIG };
  }
}

function constructApplicationRootUrl(): string {
  const { hostname, protocol, port } = window.location;
  const host = /\d+\./.test(hostname) ? "localhost" : hostname;

  const portValue = port && "80" !== port && "443" !== port ? `:${port}` : "";

  return `${protocol}//${host}${portValue}`;
}

export function buildLoginUrl(): string {
    const { loginUrl } = getConfiguration();
    return `${loginUrl}?redirectUrl=${constructApplicationRootUrl()}`;
}

export function buildLogoutUrl(): string {
    const { loginUrl } = getConfiguration();
    return `${loginUrl}logout?redirectUrl=${constructApplicationRootUrl()}`;
}
