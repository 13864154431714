import React, { useEffect, useState } from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { ApiResponseHandler, Nullable } from '../@types';
import { Agenda } from '../@types/session';
import Loader from '../component/app/loader';
import UserSessionWithStartList from '../component/session/UserSessionWithStartList';
import { useAccessToken } from '../hook/accessTokenHook';
import { useAppSelector } from '../hook/appHook';
import AttendeeService, { EMPTY_AGENDA, EMPTY_CEU_HOURS } from '../service/attendee/AttendeeService';
import AttendeeCeuCredit from '../component/attendee/AttendeeCeuCredit';

export interface MyAgendaProps {
    email?: string
}

const MyAgendaContainer: React.FC<MyAgendaProps> = ({email}) => {
    const accessToken = useAccessToken();
    const { selectedShow } = useAppSelector((state) => state.ShowState);
    const [isLoading, setIsLoading] = useState(false);
    const [agenda, setAgenda] = useState<Nullable<Agenda>>(null);
    const [error, setError] = useState<Nullable<string>>(null);
    const {emailAddress} = useAppSelector((state) => state.UserState);

    const showId = selectedShow?.showId ?? 0;
    const attendeeEmail = email || emailAddress;

    useEffect(() => {
        if ((agenda != null) || !accessToken || !showId) {
            return;
        }

        const handler:ApiResponseHandler<Agenda> = {
            onSuccess : (userAgenda) => setAgenda(userAgenda),
            onError : (error) => {
                setError(error); 
                setAgenda(EMPTY_AGENDA);
            },
            onComplete : () => setIsLoading(false)
        };

        setIsLoading(true);
        AttendeeService.getAgenda(accessToken, showId, attendeeEmail, handler);
    }, [accessToken, showId, agenda, attendeeEmail, setIsLoading, setError]);

    useEffect(() => {
        document.title = 'Martin Bros. - My Agenda';
    }, []);

    const sessions = agenda?.sessions ?? [];
    const attendedCredits = agenda?.attended ?? EMPTY_CEU_HOURS;

    return (
        <Container fluid={false} className="gx-5 container-my-agenda">
            {isLoading && <Loader />}
            {!!error && (
                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                    {error}
                </Alert>
            )}
            {!email && (
                <Row className="border-bottom border-light gx-0 pb-2 pb-md-0 mb-3">
                    <Col>
                        <h2>My Agenda</h2>
                    </Col>
                </Row>
            )}
            {!sessions?.length && (
                <Row className="gx-0 pb-2 pb-md-0 mb-3 no-user-sessions">
                    <Col>
                        <h2>You have not registered for any activities during this event.</h2>
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <AttendeeCeuCredit isAttended={true} creditHours={attendedCredits} />
                </Col>
            </Row>
            {!!sessions?.length && sessions.map((session) => 
                <React.Fragment key={`sessionFragment_${session.label}`}>
                    {session.hourlySessions.map((hourlySessions) => 
                        <Row className="mb-2"
                                key={`userSessionsHourGroup_${session.label}_${hourlySessions.label}`}>
                            <Col className="p-0">
                                <h3 className="p-2 user-session-timeslot-label">
                                    {session.label} - {hourlySessions.label}
                                </h3>
                                <UserSessionWithStartList sessionsWithStart={hourlySessions.sessions} />
                            </Col>
                        </Row>
                    )}
                </React.Fragment>
            )}
        </Container>
    );
};

export default MyAgendaContainer;
