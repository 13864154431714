import React from "react";
import { Container, Col, Row} from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { useAppSelector } from "../hook/appHook";
import { THEME_FEATURE_KEY } from "../service/theme/themeService";
import PageHeader from "../component/app/pageHeader";
import FeatureDetail from "../component/feature/featureDetail";

const ThemeFeatureDetail = () => {
    const {themeId, featureId} = useParams();
    const actualThemeId = parseInt(themeId ?? '0');
    const featId = parseInt(featureId ?? '0');

    const {themes} = useAppSelector((state) => state.ThemeState);
    const theme = themes.find(theme => theme.themeId === actualThemeId);
    const feature = theme?.features.find(themeFeature => themeFeature.featureId === featId);

    if (!theme || !feature) {
        return (
            <Container fluid>
                <Row>
                    <Col className="text-center">Theme or feature could not be found</Col>
                </Row>
            </Container>
        );
    }

    return (
        <div>
            <PageHeader link={`/theme-detail/${actualThemeId}`} title={`Theme Feature for ${feature.title}`} />
            <FeatureDetail feature={feature} featureType={THEME_FEATURE_KEY} />
        </div>
    );
};

export default ThemeFeatureDetail;