import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Show } from '../../@types'
import { useAppDispatch, useAppSelector } from '../../hook/appHook'
import { updateSelectedEvent } from '../../state/action/show/showAction'
import { useAccessToken } from '../../hook/accessTokenHook'

export interface ShowSummaryProps {
    show: Show,
    isNavigateOnChange : boolean
}

const ShowSummary:React.FC<ShowSummaryProps> = ({show, isNavigateOnChange}) => {
    const {selectedShow} = useAppSelector((state) => state.ShowState);
    
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const accessToken = useAccessToken();

    const selectedClass = (selectedShow?.showId === show.showId) ? 'selected' : '';
    const eventDate = (show.eventStartDate === show.eventEndDate) ? show.eventEndDate : `${show.eventStartDate} to ${show.eventEndDate}`;
    
    const eventStatus = !show.isActive
        ? 'Closed'
        : (show.isOpen ? 'Open' : 'Pending');

    const onShowChange = () => {
        dispatch(updateSelectedEvent(show, accessToken ?? ''));
        isNavigateOnChange && navigate('/');
    }

    return (
        <Row onClick={onShowChange} className={`${selectedClass} mx-0 show-event-row border-light border-bottom`}>
            <Col sm>
                {show.name}
            </Col>
            <Col sm>
                {eventDate}
            </Col>
            <Col sm>
                {eventStatus}
            </Col>
            <Col sm>
                {show.location}
            </Col>
        </Row>
    )
}

export default ShowSummary;