import { ApiResponse, ApiResponseHandler } from "../@types";


export function handleApiResponse(response:ApiResponse, handler:ApiResponseHandler<any>): void {
    if (response.data) {
        handler.onSuccess(response.data);
    }

    if (response.error) {
        handler.onError(response.error);
    }

    handler.onComplete?.();
}