import React from "react";
import MBModal, { MBModalProps } from "./mbModal";

export interface ConfirmationProps extends MBModalProps {
    message: string
}

const ConfirmationModal:React.FC<ConfirmationProps> = (props) => {
    
    const modalProps:MBModalProps = {...props};
    delete modalProps.message;

    return (
        <MBModal {...modalProps}>
            <div className="py-3">{props.message}</div>
        </MBModal>
    );
};

export default ConfirmationModal;