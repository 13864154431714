import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Nullable } from '../../@types';
import MBIcon from '../app/mbIcon';
import { Size } from '../../util/bootstrapUtil';

export interface LikeComponentProps {
    isLiked:Nullable<boolean>,
    isLikeLoading: boolean,
    onLikeChanged:(isLiked:Nullable<boolean>) => void
}

const LIKE = 'like';
const DISLIKE = 'dislike';

const LikeComponent:React.FC<LikeComponentProps> = ({isLiked, isLikeLoading, onLikeChanged}) => {
    const [clickedButton, setClickedButton] = useState<typeof LIKE | typeof DISLIKE>(LIKE);
    
    const onLikeButtonClick = () => {
        setClickedButton(LIKE);
        const isLike = !!isLiked 
            ? null
            : true;
        onLikeChanged(isLike);
    }

    const onDislikeButtonClick = () => {
        setClickedButton(DISLIKE);
        const isLike = (!!isLiked || (isLiked == null))
            ? false
            : null;

        onLikeChanged(isLike);
    }

    return (
        <span>
            <Button className="item-like-button p-2 like-button" onClick={onLikeButtonClick} variant="link" >
                {isLikeLoading && clickedButton === LIKE &&
                    <Spinner
                        className="text-charcoal-300"
                        as="span"
                        animation="border"
                        size="sm" />
                }
                {(!isLikeLoading || clickedButton !== LIKE) &&
                    <MBIcon 
                        icon='thumb_up' 
                        color={isLiked ? 'text-primary' : 'text-charcoal-400'}
                        size={Size.EXTRA_LARGE} />
                }
            </Button>
            <Button className="item-like-button p-2 dislike-button" onClick={onDislikeButtonClick} variant="link" >
                {isLikeLoading && clickedButton === DISLIKE &&
                    <Spinner
                        className="text-charcoal-300"
                        as="span"
                        animation="border"
                        size="sm" />
                }
                {(!isLikeLoading || clickedButton !== DISLIKE) &&
                    <MBIcon 
                        icon='thumb_down' 
                        color={(isLiked != null) && !isLiked ? 'text-primary' : 'text-charcoal-400'}
                        size={Size.EXTRA_LARGE} />
                }
            </Button>
        </span>
    );
}

export default LikeComponent;