import { ApiResponse, ApiResponseHandler, Nullable } from '../../@types';
import { Attendee, AttendeeCheckInRequest, AttendeeQueryParams } from '../../@types/attendee';
import { Agenda, CeuHours } from '../../@types/session';
import { REQUIRED_TOKEN_MESSAGE } from '../../api/api';
import AttendeeApi from '../../api/attendee/AttendeeApi';
import { handleApiResponse } from '../../util/apiUtil';

export const NOW_SHOWING_ALL = 'NOW_SHOWING_ALL';
export const NOW_SHOWING_CHECKED_IN = 'NOW_SHOWING_CHECKED_IN';
export const NOW_SHOWING_NOT_CHECKED_IN = 'NOW_SHOWING_NOT_CHECKED_IN';

export type NowShowing = typeof NOW_SHOWING_ALL | typeof NOW_SHOWING_CHECKED_IN | typeof NOW_SHOWING_NOT_CHECKED_IN;

export const EMPTY_CEU_HOURS:CeuHours = {
    general : 0,
    sanitation : 0,
    exhibit : 0
};

export const EMPTY_AGENDA:Agenda = {
    sessions : [],
    attended: {...EMPTY_CEU_HOURS},
    notAttended : {...EMPTY_CEU_HOURS}
};

class AttendeeService {
    async getCurrentUserAttendee(token:string, showId:number, handler:ApiResponseHandler<Attendee>):Promise<void> {
        if (!token || !showId) {
            handleApiResponse({data: {}, error: REQUIRED_TOKEN_MESSAGE}, handler);
            return;
        }

        const response = await AttendeeApi.fetchCurrentUserAttendee(token, showId);
        if ((response.data == null) && (response.error == null)) {
            // user is not an attendee, not api error
            response.data = {};
        }

        handleApiResponse(response, handler);
    }

    async getAgenda(token:string, showId:number, emailAddress:Nullable<string>, handler:ApiResponseHandler<Agenda>):Promise<void> {
        if (!token || !showId) {
            handleApiResponse({data: EMPTY_AGENDA, error : REQUIRED_TOKEN_MESSAGE}, handler);
            return;
        }

        const response = await AttendeeApi.fetchAgenda(token, showId, emailAddress);
        handleApiResponse(response, handler);
    }

    public async getAttendees(
        accessToken: string,
        showId: number,
        handler: ApiResponseHandler<Attendee[]>,
        params: AttendeeQueryParams = {}
    ): Promise<void> {
        if (!accessToken) {
            const error: ApiResponse = { data: null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const response = await AttendeeApi.fetchAttendees(accessToken, showId, params);
        handleApiResponse(response, handler);
    }

    public async modifyAttendeeCheckIn(
        accessToken: string,
        request: AttendeeCheckInRequest,
        handler: ApiResponseHandler<Attendee>
    ): Promise<void> {
        if (!accessToken) {
            const error: ApiResponse = { data: null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const validationError = this.validateCheckInRequest(request);
        if (validationError) {
            const error: ApiResponse = { data: null, error: validationError };
            handleApiResponse(error, handler);
            return;
        }

        const response = await AttendeeApi.putAttendeeCheckIn(accessToken, request);
        handleApiResponse(response, handler);
    }

    private validateCheckInRequest(request: AttendeeCheckInRequest): Nullable<string> {
        if (!request.showId) {
            return 'A show id is required';
        }

        if (!request.email) {
            return 'An email is required';
        }

        if (request.isCheckedIn == null) {
            return 'An isCheckedIn value is required';
        }

        return null;
    }
}

export default new AttendeeService();
