import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { ApiResponseHandler, Item, ItemSearchRequest, Nullable } from '../../@types';
import { APP_ROOT_TITLE } from '../../App';
import { useAccessToken } from '../../hook/accessTokenHook';
import { useAppSelector } from '../../hook/appHook';
import ItemService from '../../service/item/itemService';
import { PRESENTER_FEATURE_KEY } from '../../service/presenter/presenterService';
import IdentityDisplay from '../app/identityDisplay';
import Loader from '../app/loader';
import FeatureList from '../feature/featureList';
import ItemList from '../items/itemList';
import PresenterContacts from './presenterContacts';
import SessionService from '../../service/session/sessionService';
import { Session } from '../../@types/session';
import PresenterFollowup from "./presenterFollowup";
import PresenterLocations from './presenterLocations';

const PresenterDetail = () => {
    const { id, searchTerm } = useParams();
    const presenterId = parseInt(id ?? '0');

    const accessToken = useAccessToken();

    const [items, setItems] = useState<Nullable<Item[]>>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchTimer, setSearchTimer] = useState<Nullable<NodeJS.Timeout>>(null);
    const [sessions, setSessions] = useState<Nullable<Session[]>>(null);

    const { presenters } = useAppSelector((state) => state.PresenterState);
    const showId = useAppSelector((state) => state.ShowState.selectedShow?.showId ?? 0);
    const presenter = presenters.find((presenter) => presenter.presenterId === presenterId);

    useEffect(() => {
        if (isLoading || (sessions != null) || !accessToken) {
            return;
        }

        setIsLoading(true);
        const handler:ApiResponseHandler<Session[]> = {
            onSuccess : (sessions) => setSessions(sessions ?? []),
            onError : (error) => setSessions([]),
            onComplete : () => setIsLoading(false)
        };

        SessionService.getSessionList(accessToken, showId, false, handler);
    }, [isLoading, sessions, showId, accessToken]);

    const searchItems = useCallback((search: string | undefined) => {
        if (!showId || !accessToken || !presenter) {
            return;
        }

        const request: ItemSearchRequest = {
            showId: showId,
            presenterIds: [presenter.presenterId],
            searchTerm: search,
        };

        const handler: ApiResponseHandler<Item[]> = {
            onSuccess: (items) => setItems(items),
            onError: (error) => setItems([]),
            onComplete: () => setIsLoading(false)
        };

        setIsLoading(true);
        ItemService.searchItems(accessToken, request, handler);
    }, [accessToken, presenter, showId]);

    useEffect(() => {
        document.title = `${APP_ROOT_TITLE} - ${presenter ? presenter.name : 'Presenters'}`;

        if (!isLoading && presenter && items == null) {
            searchItems(searchTerm);
        }
    }, [items, presenter, isLoading, searchTerm, searchItems]);

    const presenterSessions = sessions?.filter((session) => 
        session.presenters?.find((sessionPresenterId) => sessionPresenterId === presenterId));
    
    let themeIds:number[] = [];
    items?.forEach((item) => themeIds = themeIds.concat(item.themes));

    if (!presenter) {
        return (
            <Container fluid>
                <Row>
                    <Col className="text-center">Presenter could not be found</Col>
                </Row>
            </Container>
        );
    }

    const onSearchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchTimer && clearTimeout(searchTimer);
        const search: string = event.currentTarget.value.toLowerCase();
        setSearchTimer(
            setTimeout(() => {
                searchItems(search.trim());
            }, 500)
        );
    };

    return (
        <div>
            <Container fluid className="gx-5 mb-4">
                <Row className="align-items-center gl-0">
                    <Col md={9}>
                        <Row>
                            <Col xs={'auto'} className="mr-3">
                                <IdentityDisplay entityId={presenter.presenterId}
                                        name={presenter.name}
                                        logoUrl={presenter.logoUrl}
                                        iconColor={presenter.color}
                                        size="lg" />
                                <PresenterFollowup className="mt-2" presenter={presenter}/>
                            </Col>
                            <Col xs={8} md className="mb-2 mb-sm-0">
                                <h2 className="text-start mb-2" id="presenterName">
                                    {presenter.name}
                                </h2>
                                <PresenterContacts contacts={presenter.contacts} />
                                <PresenterLocations presenterId={presenterId}
                                        boothAssignment={presenter.boothAssignment}
                                        themeIds={themeIds}
                                        sessions={presenterSessions} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            {!!presenter.features.length && (
                <Container fluid className="gx-5 mb-4">
                    <FeatureList
                        features={presenter.features}
                        featureType={PRESENTER_FEATURE_KEY}
                        entityId={presenterId}
                    />
                </Container>
            )}

            <Container fluid className="gx-4 gx-lg-5">
                <Row className="border-bottom gx-0 pb-2 pb-md-0 mb-3">
                    <Col>
                        <h2>Presenter Items</h2>
                    </Col>
                    <Col md="auto" className="justify-content-end">
                        <Form.Control
                            type="text"
                            defaultValue={searchTerm}
                            onChange={onSearchChanged}
                            placeholder={`Search presenter items...`}
                        />
                    </Col>
                </Row>

                {isLoading && <Loader />}

                {!!items?.length && <ItemList items={items} />}

                {!items?.length && 
                <Row className="mt-4">
                    <Col className="text-center">{!!isLoading ? 'Preparing Items...' : 'No Items found'}</Col>
                </Row>
                }
            </Container>
        </div>
    );
};

export default PresenterDetail;
