import React, { HTMLAttributes, ReactElement } from 'react';
import { Nullable } from '../../@types';

const FORM_INPUT_TYPES = ['input', 'select', 'textarea', 'fieldset', 'datalist', 'output', 'option', 'optgroup'];

interface LabelGroupProps extends HTMLAttributes<HTMLElement> {
    id: string;
    label: string;
    isBlock?: boolean;
    htmlFor?: string;
    isLabelColonOmitted?: boolean;
    labelClasses?: string;
}

const MBLabelGroup: React.FC<LabelGroupProps> = ({
    id,
    label,
    isBlock,
    htmlFor,
    isLabelColonOmitted,
    children,
    labelClasses = '',
    ...htmlProps
}) => {
    const classString = htmlProps.className ?? '';
    const inlineLabelClass = isBlock ? '' : '-inline';
    const baseClassString = `mb${inlineLabelClass}-label-group`;
    htmlProps.className = `${baseClassString} ${classString}`;

    const isChildValidElement = React.isValidElement(children);
    let elementType: Nullable<string> = null;
    if (isChildValidElement) {
        elementType = (React.Children.toArray(children)[0] as ReactElement).type as string;
    }

    const isLabelUsed = !!elementType && FORM_INPUT_TYPES.includes(elementType);
    const labelElementType = isLabelUsed ? 'label' : 'span';
    const labelClassName = `${!!isLabelColonOmitted ? 'check-box-label' : ''} ${
        !isLabelUsed ? 'label-group-label' : ''
    } ${labelClasses}`;
    const labelElementProps: any = { className: labelClassName };
    if (isLabelUsed) {
        labelElementProps.htmlFor = htmlFor;
    }

    const labelElement = React.createElement(labelElementType, labelElementProps, label);
    return (
        <div {...htmlProps} id={`labelGroupContainer-${id}`}>
            {labelElement}
            {isChildValidElement ? children : <div>{children}</div>}
        </div>
    );
};

export default MBLabelGroup;
