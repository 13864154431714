import React from "react";
import { Nullable } from "../../@types";

interface ProfileCircleProps extends React.HTMLAttributes<HTMLElement> {
    profileId: number,
    name: string,
    iconColor: Nullable<string>,
    size: 'sm' | 'lg'
};

const ICON_COLORS = [
    "#a348b4","#2f9415","#4fe276","#883139","#37c9f4",
    "#785ec9","#ce3986","#1d7c6f","#b92f12","#d6eca1",
    "#f6dc7d","#d05a41","#fe2a0b","#0f4179","#8389b8"
];

const ProfileCircle:React.FC<ProfileCircleProps> = (props) => {
    const {profileId, name} = props;
    let selectedColor = props.iconColor;
    if (!selectedColor) {
        selectedColor = (profileId < ICON_COLORS.length)
            ? ICON_COLORS[profileId - 1]
            : ICON_COLORS[profileId % ICON_COLORS.length];
    }

    const initials = name.split(/\s+/)
        .filter((word) => !!word.length)
        .map((word) => word.substring(0,1).toUpperCase())
        .join('');
        
    const circleSize = (props.size === 'lg') ? 70 : 35;
    const fontSize = (props.size === 'lg') ? 30 : 15;
    const sizeStyle = {
        height: circleSize, 
        width: circleSize
    };
    return (
        <div 
                className={`profile-circle ${props.className}`} 
                style={{backgroundColor: selectedColor, ...props.style, ...sizeStyle}} >
            <div className="profile-circle-initials" style={{fontSize}}>{initials}</div>
        </div>
    );
}
    
export default ProfileCircle;