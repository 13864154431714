import { ApiResponse, UserPresenterFollowupRequest } from '../../@types';
import Api from '../api';

const FOLLOW_UP_REQUEST_BASE = 'user-follow-up/presenter/';

class UserPresenterFollowupRequestApi {
    public async createFollowUpRequest(request: UserPresenterFollowupRequest): Promise<ApiResponse> {
        const { accessToken, presenterId, showId } = request;
        const response = await Api.performPOSTRequest(
            `${FOLLOW_UP_REQUEST_BASE}${presenterId}/${showId}`,
            {},
            accessToken
        );
        return response;
    }

    public async removeFollowupRequest(request: UserPresenterFollowupRequest): Promise<ApiResponse> {
        const { accessToken, presenterId, showId } = request;
        const response = await Api.performDELETERequest(
            `${FOLLOW_UP_REQUEST_BASE}${presenterId}/${showId}`,
            accessToken
        );
        return response;
    }
}

export default new UserPresenterFollowupRequestApi();
