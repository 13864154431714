import { combineReducers } from "redux";
import AppState from '../app/appState';
import ShowState from '../show/showState';
import UserState from "../user/userState";
import PresenterState from "../presenter/presenterState";
import ThemeState from "../theme/themeState";

const rootReducer = combineReducers({
    AppState, 
    ShowState, 
    ThemeState, 
    UserState, 
    PresenterState
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;