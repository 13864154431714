import { ApiResponse } from '../../@types';
import { LikedFeatureRequest, LikedItemRequest, LikedSessionRequest } from '../../service/user/userLikesService';
import Api from '../api';

const USER_LIKE_BASE = 'user-like';

class UserLikesApi {
    public async saveLikedItem(request:LikedItemRequest):Promise<ApiResponse> {
        const response = await Api.performPOSTRequest(`${USER_LIKE_BASE}/item/${request.showId}`, request.likedItem, request.accessToken);
        return response;
    }

    public async saveLikedPresenterFeature(request:LikedFeatureRequest):Promise<ApiResponse> {
        const response = await Api.performPOSTRequest(`${USER_LIKE_BASE}/presenter/feature/${request.showId}`, request.likedFeature, request.accessToken);
        return response;
    }

    public async saveLikedThemeFeature(request:LikedFeatureRequest):Promise<ApiResponse> {
        const response = await Api.performPOSTRequest(`${USER_LIKE_BASE}/theme/feature/${request.showId}`, request.likedFeature, request.accessToken);
        return response;
    }

    public async saveLikedSession(request:LikedSessionRequest):Promise<ApiResponse> {
        const response = await Api.performPOSTRequest(`${USER_LIKE_BASE}/session/${request.showId}`, request.likedSession, request.accessToken);
        return response;
    }

    public async saveLikedSessionFeature(request:LikedFeatureRequest):Promise<ApiResponse> {
        const response = await Api.performPOSTRequest(`${USER_LIKE_BASE}/session/${request.showId}/feature`, request.likedFeature, request.accessToken);
        return response;
    }
}

export default new UserLikesApi();