import React, { useState } from "react"
import {Button, Modal, ModalProps} from 'react-bootstrap';
import Loader from "../app/loader";

export interface MBModalProps extends ModalProps {
    mbModalProps: {
        title: string,
        isCancelButtonDisplayed? : boolean,
        isCloseButtonDisplayed? : boolean
        actionBtnText?: string,
        cancelBtnText?: string,
        size: "sm" | "lg" | "xl",
        onModalSubmit: () => Promise<boolean>,
        onModalCancel?: () => Promise<boolean>
        isActionBtnDisabled?: boolean;
    }
}

const MBModal:React.FC<MBModalProps> = (props) => {
    const [isModalLoading, setModalIsLoading] = useState(false);
    const submitModal = () => {
        setModalIsLoading(true);
        props.mbModalProps.onModalSubmit().then(isSuccess => {
            if (isSuccess) {
                props.onHide?.();
            }
        }).finally(() => setModalIsLoading(false))
    };

    const cancelModal = () => {
        setModalIsLoading(true);

        if (!props.mbModalProps.onModalCancel) {
            hideModal();
            return;
        }

        props.mbModalProps.onModalCancel().then(isSuccess => {
            if (isSuccess) {
                props.onHide?.();
            }
        }).finally(() => setModalIsLoading(false))
    };

    const hideModal = () => {
        setModalIsLoading(false);
        props.onHide?.();
    }
    
    const bootstrapProps:Partial<MBModalProps> = {...props};
    bootstrapProps.size = props.mbModalProps.size;
    delete bootstrapProps.mbModalProps;

    const isCancelBtnDisplayed = (props.mbModalProps.isCancelButtonDisplayed != null)
        ? props.mbModalProps.isCancelButtonDisplayed
        : true;

    const isCloseBtnDisplayed = (props.mbModalProps.isCloseButtonDisplayed != null)
        ? props.mbModalProps.isCloseButtonDisplayed
        : true;
    
    return (
        <Modal
                {...bootstrapProps}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static">
            <Modal.Header closeButton={isCloseBtnDisplayed}>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.mbModalProps.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isModalLoading && <Loader className="loader-top" />}
                {props.children}
                </Modal.Body>
            <Modal.Footer>
                <Button 
                        size="sm" 
                        className="note-add-button" 
                        variant="primary" 
                        onClick={submitModal}
                        disabled={!!props.mbModalProps.isActionBtnDisabled}
                        >
                    {props.mbModalProps.actionBtnText ?? 'Add'}
                </Button>
                {!!isCancelBtnDisplayed &&
                <Button 
                        size="sm" 
                        variant="" 
                        className="mb-button" 
                        onClick={cancelModal}>
                    {props.mbModalProps.cancelBtnText ?? 'Cancel'}
                </Button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default MBModal;