import React from "react";
import { Show } from "../../@types";
import ShowSummary from "./showSummary";

export interface ShowListProps {
    shows: Show[],
    isNavigateOnChange : boolean
}

const ShowList:React.FC<ShowListProps> = ({shows, isNavigateOnChange}) => {
    return(
        <>
            {shows.map(show => 
                <ShowSummary key={show.showId} show={show} isNavigateOnChange={isNavigateOnChange} />
            )}
        </>
    )
}

export default ShowList;