import React from 'react'
import { LikedFeature, Note, Theme } from '../../@types';
import ThemeNotes from '../note/themeNotes';

export interface ThemeNoteListProps {
    themes : Theme[],
    featureNotes : Note[],
    likedFeatures : LikedFeature[]
}

const ThemeNoteList:React.FC<ThemeNoteListProps> = ({themes, likedFeatures, featureNotes}) => {
    
    return (
        <>
            {themes.map((theme) => {
                const themeNotes = featureNotes.filter((featureNote) => !!theme.features.find((feature) => feature.featureId === featureNote.featureId));
                const likedThemeFeatures = likedFeatures.filter((likedFeature) => theme.features.find((feature) => (likedFeature.featureId === feature.featureId) && likedFeature.isLiked));
                return <ThemeNotes 
                                key={theme.themeId} 
                                theme={theme}
                                likedFeatures={likedThemeFeatures}
                                featureNotes={themeNotes} />
            })}
        </>
    );
}

export default ThemeNoteList;