import { ApiResponse, ApiResponseHandler, BaseApiRequest, LikedFeature, LikedItem, LikedSession, Nullable } from "../../@types";
import { REQUIRED_TOKEN_MESSAGE } from "../../api/api";
import UserLikesApi from "../../api/user/userLikesApi";
import { handleApiResponse } from "../../util/apiUtil";

export interface LikedItemRequest extends BaseApiRequest{
    likedItem:LikedItem
}

export interface LikedFeatureRequest extends BaseApiRequest {
    likedFeature : LikedFeature
}

export interface LikedSessionRequest extends BaseApiRequest {
    likedSession : LikedSession
}

class UserLikesService {
    public async saveLikedItem(request:LikedItemRequest, handler:ApiResponseHandler<LikedItem>):Promise<void> {
        if (!request.accessToken) {
            const error:ApiResponse = {data:null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        if (!request.showId || !request.likedItem?.itemNumber) {
            handleApiResponse({data: null, error: 'A Show id and item number are required'}, handler);
            return;
        }

        const response = await UserLikesApi.saveLikedItem(request);
        handleApiResponse(response, handler);  
    }

    public async saveLikedPresenterFeature(request:LikedFeatureRequest, handler:ApiResponseHandler<LikedFeature>):Promise<void> {
        const error = this.validateLikedFeature(request);
        if (error) {
            handleApiResponse({data: null, error: error}, handler);
            return;
        }

        const response = await UserLikesApi.saveLikedPresenterFeature(request);
        handleApiResponse(response, handler);  
    }

    public async saveLikedThemeFeature(request:LikedFeatureRequest, handler:ApiResponseHandler<LikedFeature>):Promise<void> {
        const error = this.validateLikedFeature(request);
        if (error) {
            handleApiResponse({data: null, error: error}, handler);
            return;
        }

        const response = await UserLikesApi.saveLikedThemeFeature(request);
        handleApiResponse(response, handler);  
    }

    public async saveLikedSession(request:LikedSessionRequest, handler:ApiResponseHandler<LikedSession>):Promise<void> {
        if (!request.accessToken) {
            const error:ApiResponse = {data:null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        if (!request.showId || !request.likedSession?.sessionId) {
            handleApiResponse({data: null, error: 'A Show id and session id are required'}, handler);
            return;
        }

        const response = await UserLikesApi.saveLikedSession(request);
        handleApiResponse(response, handler);  
    }

    async saveLikedSessionFeature(request:LikedFeatureRequest, handler:ApiResponseHandler<LikedFeature>):Promise<void> {
        const error = this.validateLikedFeature(request);
        if (error) {
            handleApiResponse({data: null, error: error}, handler);
            return;
        }

        const response = await UserLikesApi.saveLikedSessionFeature(request);
        handleApiResponse(response, handler);
    }

    private validateLikedFeature(request:LikedFeatureRequest):Nullable<string> {
        if (!request.accessToken) {
            return REQUIRED_TOKEN_MESSAGE;
        }

        if (!request.showId) {
            return 'A Show id is required';
        }

        if (!request.likedFeature?.featureId) {
            return 'A Feature id is required';
        }

        return null;
    }
}

export default new UserLikesService();