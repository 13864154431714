import { ApiResponseHandler, Item, ItemSearchRequest } from "../../@types";
import ItemApi from "../../api/item/itemApi";
import { handleApiResponse } from "../../util/apiUtil";

class ItemService {
    public async searchItems(token:string, request:ItemSearchRequest, handler:ApiResponseHandler<Item[]>):Promise<void> {
        const response = await ItemApi.searchItems(token, request);
        handleApiResponse(response, handler);  
    }
}

export default new ItemService();