import React, {useState} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Attendee } from '../../@types/attendee';
import { formatYYYYMMDDHMMA } from '../../util/DateTimeUtil';
import ContactInformation from './ContactInformation';
import MBModal from '../modal/mbModal';
import MyAgendaContainer from "../../container/MyAgendaContainer";
import { Size } from '../../util/bootstrapUtil';
import MBButtonLink from "../app/input/mbButtonLink";

interface AttendeeBlockProps {
    attendee: Attendee;
    onModifyCheckIn: (email: string, isChecked: boolean) => any;
    isCheckInModifiable: boolean;
}

const AttendeeBlock: React.FC<AttendeeBlockProps> = ({ attendee, onModifyCheckIn, isCheckInModifiable }) => {
    const {
        firstName,
        lastName,
        email,
        isCheckedIn,
        checkInDate,
        phoneNumber,
        customerName,
        customerNumber,
        dsrEmail,
        dsrName,
        dsrPhone,
        registrationCount
    } = attendee;

    const [isShowMyAgenda, setIsShowMyAgenda] = useState(false);

    const getFormattedCheckInDate = () => {
        if (!isCheckedIn || !checkInDate) {
            return '';
        }

        const parsedDate = new Date(checkInDate);
        if (!parsedDate) {
            return;
        }

        return formatYYYYMMDDHMMA(parsedDate);
    };

    const printModal = () => {
        const content = document.getElementById("myAgendaModal")?.innerHTML ?? '';

        const printWindow = window.open('', undefined, "height=375,width=825");
        
        if (!printWindow || !content) {
            return;
        }

        // removing icons since they print as just the string
        let printContent = content.replaceAll('<i class="material-icons fs-lg text-blue " title="Attended">done</i>', '');
        printContent = printContent.replaceAll('<i class="material-icons fs-lg text-blue " title="Scheduled">schedule</i>', '');

        printContent = printContent.replaceAll('<button type="button" class="note-add-button btn btn-primary btn-sm">Print</button>', '');
        printContent = printContent.replaceAll('<button type="button" class="mb-button btn btn-sm">Cancel</button>', '');

        printWindow.document.write('<html><head><title></title>');
        printWindow.document.write('</head><body><div class="printElement">');
        printWindow.document.write(printContent);
        printWindow.document.write('</div></body></html>');

        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    }

    return (
        <Container
            style={{ borderRadius: '0.4rem', margin: '0.5rem 0', padding: '0.5rem 1rem' }}
            className="border-light border"
        >
            <Row className="attendee-row" id={`attendeeRow_${email}`}>
                <Col sm={12} md={6} xxl={3} className="mb-2 mt-2">
                    <div className="d-flex">
                        {isCheckInModifiable && (
                            <input
                                id={`attendeeCheckIn_${attendee.email}`}
                                type="checkbox"
                                checked={!!attendee.isCheckedIn}
                                onChange={(event) => onModifyCheckIn(email, event.target.checked)}
                                className="mb-checkbox me-3"
                            />
                        )}
                        <ContactInformation name={`${firstName} ${lastName}`} email={email} phone={phoneNumber} />
                    </div>
                </Col>
                <Col sm={12} md={6} xxl={3} className="mb-2 mt-2 d-flex align-items-center attendee-customer-container">
                    <div>
                        <div className="attendee-customer-number">{customerNumber}</div>
                        <div className="attendee-customer-name">{customerName}</div>
                    </div>
                </Col>
                <Col sm={12} md={6} xxl={2} className="mb-2 mt-2 attendee-dsr-contact-container">
                    {!!dsrName && <ContactInformation name={dsrName} email={dsrEmail} phone={dsrPhone} />}
                </Col>
                <Col sm={12} md={6} xxl={2} className="mb-2 mt-2 attendee-check-in-info-container">
                    {!!isCheckedIn && (
                        <div>
                            <h4>Checked In</h4>
                            <p className="check-in-date">{getFormattedCheckInDate()}</p>
                        </div>
                    )}
                </Col>
                <Col sm={12} md={6} xxl={2} className="mb-2 mt-2 attendee-registration-count-container">
                    <div>
                        <h4 className="attendee-registration-count">Registration Count:
                            {registrationCount > 0 && (
                                <MBButtonLink size={Size.LARGE} className="ms-2 mb-2" style={{ fontSize: 21 }}
                                   onClick={() => setIsShowMyAgenda(true)}>{registrationCount}</MBButtonLink>
                            )}
                            {registrationCount === 0 && (
                                <span className="ms-2">{registrationCount}</span>
                            )}
                        </h4>

                    </div>
                </Col>
            </Row>
            {isShowMyAgenda &&
            <MBModal id={"myAgendaModal"}
                    show={true}
                    onHide={() => setIsShowMyAgenda(false)}
                    mbModalProps={{
                        title: `My Agenda`,
                        isCancelButtonDisplayed: true,
                        isCloseButtonDisplayed: false,
                        size: Size.EXTRA_LARGE,
                        actionBtnText: 'Print',
                        onModalSubmit: () => {
                            printModal();
                            return Promise.resolve(true);
                        },
                        onModalCancel: () => {
                            setIsShowMyAgenda(false);
                            return Promise.resolve(true);
                        }
                    }}
                    className="my-agenda-modal">
                <MyAgendaContainer email={attendee.email}/>
            </MBModal>
            }
        </Container>
    );
};

export default AttendeeBlock;
