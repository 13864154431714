import React from "react";
import { Likes, Note, Presenter } from "../../@types";
import { Accordion, Row, Col } from "react-bootstrap";
import PresenterItemNotes from "./presenterItemNote";
import PresenterContacts from "../presenter/presenterContacts";
import FeatureNote from "./featureNote";
import { PRESENTER_FEATURE_KEY } from "../../service/presenter/presenterService";
import IdentityDisplay from "../app/identityDisplay";

export interface PresenterNotesProp {
    presenter : Presenter,
    featureNotes : Note[],
    itemNotes : Note[],
    likes : Likes,
    hasFollowupRequest : boolean
}

const PresenterNotes:React.FC<PresenterNotesProp> = ({
        presenter, 
        featureNotes, 
        itemNotes, 
        likes,
        hasFollowupRequest
}) => {

    const itemsToDisplay = presenter.itemNumbers.filter((itemNumber) => 
        likes.likedItems.find((likedItem) => (likedItem.itemNumber === itemNumber) && likedItem.isLiked)
        || itemNotes.find((itemNote) => itemNote.itemNumber === itemNumber)
    );

    const featuresToDisplay = presenter.features.filter((feature) => 
        likes.likedPresenterFeatures.find((likedFeature) => (likedFeature.featureId === feature.featureId) && likedFeature.isLiked)
        || featureNotes.find((featureNote) => feature.featureId === featureNote.featureId)
    );

    const presenterClass = (!featuresToDisplay.length && !itemsToDisplay.length) 
        ? 'empty-accordion' 
        : '';

    return (
        <Accordion.Item eventKey={presenter.presenterId.toString()} className="border mb-2">
            <Accordion.Header as="div" className={`presenter ${presenterClass}`}>
                <Row className="m-0 align-items-center flex-grow-1">
                    <Col xs="auto">
                        <IdentityDisplay 
                                entityId={presenter.presenterId}
                                name={presenter.name}
                                logoUrl={presenter.logoUrl}
                                iconColor={presenter.color}
                                size="sm"
                                className="img-fluid"
                                style={{width : "80px"}} />
                    </Col>
                    <Col>
                        <h5 className="m-0 presenter-name">{presenter.name}</h5>
                        {!!presenter.contacts.length && 
                            <PresenterContacts contacts={presenter.contacts} />
                        }
                        {hasFollowupRequest &&
                            <p style={{color:"red"}}> Follow-Up Requested </p>
                        }
                    </Col>
                </Row>
            </Accordion.Header>
            {(!!featuresToDisplay.length || !!itemsToDisplay.length) &&
            <Accordion.Body className="presenter-items">
                {featuresToDisplay.map((feature) => {
                    const featureNote = featureNotes.find((note) => note.featureId === feature.featureId) ?? null;
                    const isLiked = !!likes.likedPresenterFeatures.find((likedFeature) => (likedFeature.featureId === feature.featureId) && likedFeature.isLiked);
                    return <FeatureNote 
                            key={feature.featureId.toString()} 
                            feature={feature}
                            note={featureNote} 
                            isLiked={isLiked}
                            featureType={PRESENTER_FEATURE_KEY} />
                })}
                {itemsToDisplay.map((itemNumber) => {
                    const itemNote = itemNotes.find((note) => note.itemNumber === itemNumber) ?? null;
                    const isLiked = !!likes.likedItems.find((likedItem) => (likedItem.itemNumber === itemNumber) && likedItem.isLiked);
                    return <PresenterItemNotes key={itemNumber} itemNumber={itemNumber} itemNote={itemNote} isLiked={isLiked} />
                })}
            </Accordion.Body>
            }
        </Accordion.Item>
    );
}

export default PresenterNotes;