import React from "react";

const Loader:React.FC<React.HTMLProps<string>> = (props) => {
    const classes = "loader " + (props?.className ?? '');

    return (
        <div className={classes}></div>
    );
}
    
export default Loader;