import React, {useCallback, useEffect, useState} from "react";
import { ApiResponseHandler, Item, ItemSearchRequest, Nullable } from "../@types";
import {Container, Col, Row} from 'react-bootstrap';
import { useAppSelector } from "../hook/appHook";
import ItemList from "../component/items/itemList";
import Loader from "../component/app/loader";
import { APP_ROOT_TITLE } from "../App";
import { useParams } from "react-router-dom";
import ItemService from "../service/item/itemService";
import { useAccessToken } from "../hook/accessTokenHook";

const ItemListContainer:React.FC<any> = () => {
    const searchTerm = useParams().searchTerm ?? '';

    const accessToken = useAccessToken();

    const [items, setItems] = useState<Nullable<Item[]>>(null);
    const [search, setSearch] = useState<string|undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const showId = useAppSelector((state) => state.ShowState.selectedShow?.showId ?? 0);
    
    const searchItems = useCallback((search:string|undefined) => {
        setIsLoading(true);

        if (!showId || !accessToken) {
            setIsLoading(false);
            return;
        }

        const request:ItemSearchRequest = {
            showId : showId,
            searchTerm : search
        };

        const handler:ApiResponseHandler<Item[]> = {
            onSuccess : (items) => {
                setItems(items);
            },
            onError : (error) => {
                setItems([]);
            },
            onComplete : () => setIsLoading(false)
        };

        ItemService.searchItems(accessToken, request, handler);
    }, [accessToken, showId]);

    useEffect(() => {
        document.title = `${APP_ROOT_TITLE} - Item Search`;

        if (!isLoading && ((search !== searchTerm) || (items == null))) {
            searchItems(searchTerm);
            setSearch(searchTerm);
        }
    }, [items, isLoading, searchTerm, search, accessToken, searchItems]);

    return (
        <Container fluid className="gx-5">
            <Row className="border-bottom gx-0 pb-2 pb-md-0 mb-3">
                <Col>
                    <h2>Item Search - "{search}"</h2>
                </Col>
            </Row>
            {isLoading && <Loader />}

            {!!items?.length && <ItemList items={items} isPresenterLinkDisplayed={true} /> }
            
            {!items?.length &&
                <Row className="mt-4">
                    <Col className="text-center">
                        {!!isLoading ? 'Preparing Items...' : 'No Items found'}
                    </Col>
                </Row>
            }
        </Container>
    );
}

export default ItemListContainer;