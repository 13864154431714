import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Item, Nullable } from '../../@types';
import { useAppSelector } from '../../hook/appHook';
import MBLabelGroup from '../app/mbLabelGroup';
import PresentedByLink from '../presenter/presentedByLink';

interface ThemePresenterListProps {
    themeItems: Nullable<Item[]>;
}

const ThemePresenterList: React.FC<ThemePresenterListProps> = ({ themeItems }) => {
    const { presenters } = useAppSelector((state) => state.PresenterState);

    const itemPresenterIds: number[] = [];
    if (themeItems?.length) {
        themeItems.forEach((item) => {
            if (item.presenterId && !itemPresenterIds.includes(item.presenterId)) {
                itemPresenterIds.push(item.presenterId);
            }
        });
    }

    const itemPresenters = presenters
        .filter((presenter) => itemPresenterIds.includes(presenter.presenterId))
        .sort((presenterA, presenterB) => {
            const nameA = presenterA.name;
            const nameB = presenterB.name;

            if (nameA < nameB) {
                return -1;
            }

            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });

    if (!itemPresenters.length) {
        return null;
    }

    return (
        <Row>
            <Col>
                <Card.Text as="p" className="presented-by-list">
                    <MBLabelGroup id="themePresenterList" label="Presented By" className="fw-600 d-flex" isBlock>
                        <div>
                            {itemPresenters.map((itemPresenter) => (
                                <div key={`themePresenterLink_${itemPresenter.presenterId}`}>
                                    <PresentedByLink presenter={itemPresenter} />
                                </div>
                            ))}
                        </div>
                    </MBLabelGroup>
                </Card.Text>
            </Col>
        </Row>
    );
};

export default ThemePresenterList;
