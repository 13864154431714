import React, { useEffect, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import PageHeader from '../component/app/pageHeader';
import FeatureDetail from '../component/feature/featureDetail';
import { SessionFeature } from '../@types/session';
import { ApiResponseHandler, Nullable } from '../@types';
import { useAccessToken } from '../hook/accessTokenHook';
import SessionService, { SESSION_FEATURE_KEY } from '../service/session/sessionService';
import { useAppSelector } from '../hook/appHook';
import { APP_ROOT_TITLE } from '../App';
import Loader from '../component/app/loader';
import { Variant } from '../util/bootstrapUtil';

const SessionFeatureContainer:React.FC<any> = () => {
    const params = useParams();
    const sessionId = parseInt(params.sessionId ?? '0');
    const featureId = parseInt(params.featureId ?? '0');
    
    const [feature, setFeature] = useState<Nullable<SessionFeature>>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Nullable<string>>(null);
    const showId = useAppSelector((state) => state.ShowState.selectedShow?.showId ?? 0);
    
    const accessToken = useAccessToken();

    useEffect(() => {
        document.title = `${APP_ROOT_TITLE} - Session Feature`;
    }, []);

    useEffect(() => {
        if (!accessToken || (feature != null)) {
            return;
        }

        const handler:ApiResponseHandler<SessionFeature> = {
            onSuccess : (sessionFeature) => setFeature(sessionFeature),
            onError : (error) => {
                setError(error);
                const emptyFeature:SessionFeature = {
                    showId: 0,
                    sessionId : sessionId,
                    featureId: 0,
                    title: '',
                    description: '',
                    url: '',
                    thumbnailUrl: ''
                };

                setFeature(emptyFeature);
            },
            onComplete : () => setIsLoading(false)
        };

        setIsLoading(true);
        SessionService.getSessionFeatureById(accessToken, showId, featureId, handler);
    }, [accessToken, featureId, feature, sessionId, showId])
    
    return (
        <div>
            {isLoading && <Loader />}
            {!!error && 
                <Alert variant={Variant.DANGER} 
                        onClose={() => setError(null)} 
                        dismissible/>
            }
            {(feature != null) && !feature.featureId &&
            <Row>
                <Col className="text-center">Session Feature could not be found</Col>
            </Row>
            }
            {!!feature?.featureId &&
            <>
                <PageHeader link={`/session-detail/${sessionId}`} title={`Session Feature for ${feature.title}`} />
                <FeatureDetail feature={feature} featureType={SESSION_FEATURE_KEY} />
            </>
            }
        </div>
    );
};

export default SessionFeatureContainer;