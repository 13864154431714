import React, {HTMLAttributes} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MBIcon from './mbIcon';
import { Size } from '../../util/bootstrapUtil';

interface PageHeaderProps extends HTMLAttributes<HTMLElement>{
    link: string,
    title: string
}

const PageHeader:React.FC<PageHeaderProps> = ({link, title}) => {

  return (
    <Container fluid className="page-header-container">
        <Row>
            <Col xs="auto" className="page-link-container">
                <Link to={link} className="return-link" title="Return to previous page">
                    <MBIcon icon="navigate_before" size={Size.EXTRA_LARGE} color="text-charcoal-700" />
                </Link>
            </Col>
            <Col className="theme-title">
                <h3>{title}</h3>
            </Col>
        </Row>
    </Container>
  );
};

export default PageHeader;