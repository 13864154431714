import { createSlice } from '@reduxjs/toolkit';
import { UserPresenterFollowup, UserDsrConsent, UserState } from '../../@types';

const INITIAL_STATE:UserState = {
    isLoading : false,
    isInitialized : false,
    emailAddress: "",
    likes : {
        likedItems : [],
        likedPresenterFeatures : [],
        likedThemeFeatures : [],
        likedSessions : [],
        likedSessionFeatures : []
    },
    notes: {
        itemNotes : [],
        presenterFeatureNotes : [],
        themeFeatureNotes : [],
        sessionNotes : [],
        sessionFeatureNotes : []
    },
    attendee : null,
    followupRequests: [],
    isShared: null,
    permissionSets: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState: INITIAL_STATE,
    reducers: {
        initializeEmployee: (state) => {
            state.isLoading = true;
        },
        initializeEmployeeSuccess: (state, {payload}) => {
            const {likes, emailAddress, notes, isShared, followupRequests} = payload;
            state.isLoading = false;
            state.isInitialized = true;
            state.likes = likes;
            state.emailAddress = emailAddress;
            state.notes = notes;
            state.followupRequests = followupRequests;
            state.isShared = isShared;
        },
        initializeEmployeeFailure: (state) => {
            state.isLoading = false;
            state.isInitialized = true;
            state.emailAddress = '';
            state.notes = {...state.notes};
        },
        fetchAttendee : (state) => {
            state.isLoading = true;
        },
        fetchAttendeeSuccess : (state, {payload}) => {
            state.isLoading = false;
            state.attendee = payload;
        },
        loadUserPermissionSetSuccess: (state, {payload}) => {
            state.permissionSets = payload;
        },
        loadUserPermissionSetFailure: (state) => {
            state.permissionSets = [];
        },
        userShowChange: (state) => {
            state.isInitialized = false;
        },
        updateLikedItemSuccess: (state, {payload}) => {
            const updatedItem = payload;
            let likedItems = [...state.likes.likedItems];
            if (updatedItem.isLiked == null) {
                likedItems = likedItems.filter((likedItem) => likedItem.itemNumber !== updatedItem.itemNumber);
            }
            else {
                const index = likedItems.findIndex(likedItem => likedItem.itemNumber === updatedItem.itemNumber);
                if (index > -1) {
                    likedItems[index] = updatedItem;
                }
                else {
                    likedItems.push(updatedItem);
                }
            }

            state.likes = {
                ...state.likes,
                likedItems: [...likedItems]
            }
        },
        updateLikedThemeFeatureSuccess: (state, {payload}) => {
            const updatedThemeFeature = payload;
            let likedThemeFeatures = [...state.likes.likedThemeFeatures];
            if (updatedThemeFeature.isLiked == null) {
                likedThemeFeatures = likedThemeFeatures.filter((likedFeature) => likedFeature.featureId !== updatedThemeFeature.featureId);
            }
            else {
                const index = likedThemeFeatures.findIndex(likedFeature => likedFeature.featureId === updatedThemeFeature.featureId);
                if (index > -1) {
                    likedThemeFeatures[index] = updatedThemeFeature;
                }
                else {
                    likedThemeFeatures.push(updatedThemeFeature);
                }
            }

            state.likes = {
                ...state.likes,
                likedThemeFeatures: [...likedThemeFeatures]
            }
        },
        updateLikedPresenterFeatureSuccess: (state, {payload}) => {
            const updatedPresenterFeature = payload;
            let likedPresenterFeatures = [...state.likes.likedPresenterFeatures];
            if (updatedPresenterFeature.isLiked == null) {
                likedPresenterFeatures = likedPresenterFeatures.filter((likedFeature) => likedFeature.featureId !== updatedPresenterFeature.featureId);
            }
            else {
                const index = likedPresenterFeatures.findIndex(likedFeature => likedFeature.featureId === updatedPresenterFeature.featureId);
                if (index > -1) {
                    likedPresenterFeatures[index] = updatedPresenterFeature;
                }
                else {
                    likedPresenterFeatures.push(updatedPresenterFeature);
                }
            }

            state.likes = {
                ...state.likes,
                likedPresenterFeatures : [...likedPresenterFeatures]
            }
        },
        updateLikedSessionFeatureSuccess : (state, {payload}) => {
            const likedFeature = payload;
            let likedSessionFeatures = [...state.likes.likedSessionFeatures];
            if (likedFeature.isLiked == null) {
                likedSessionFeatures = likedSessionFeatures.filter((feature) => feature.featureId !== likedFeature.featureId);
            }
            else {
                const existing = likedSessionFeatures.find((feature) => feature.featureId === likedFeature.featureId);
                if (existing != null) {
                    existing.isLiked = likedFeature.isLiked;
                }
                else {
                    likedSessionFeatures.push(likedFeature);
                }
            }

            state.likes.likedSessionFeatures = likedSessionFeatures;
        },
        updateLikedSessionSuccess: (state, {payload}) => {
            const updatedSession = payload;
            let likedSessions = [...state.likes.likedSessions];
            if (updatedSession.isLiked == null) {
                likedSessions = likedSessions.filter((likedSession) => likedSession.sessionId !== updatedSession.sessionId);
            }
            else {
                const index = likedSessions.findIndex(likedSession => likedSession.sessionId === updatedSession.sessionId);
                if (index > -1) {
                    likedSessions[index] = updatedSession;
                }
                else {
                    likedSessions.push(updatedSession);
                }
            }

            state.likes = {
                ...state.likes,
                likedSessions : [...likedSessions]
            }
        },
        updateItemNoteSuccess: (state, {payload}) => {
            let itemNotes = [...state.notes.itemNotes];
            const isItemNoteRemoved = Object.keys(payload).length === 1;
            const itemNumber = payload.itemNumber;
            if (isItemNoteRemoved) {    
                itemNotes = itemNotes.filter((itemNote) => itemNote.itemNumber !== itemNumber);
            }
            else {
                const index = itemNotes.findIndex((itemNote) => itemNote.itemNumber === itemNumber);
                if (index > -1) {
                    itemNotes[index] = payload;
                }
                else {
                    itemNotes.push(payload);
                }
            }

            state.notes = {
                ...state.notes,
                itemNotes : itemNotes
            }
        },
        updatePresenterFeatureNoteSuccess: (state, {payload}) => {
            const presenterFeatureId = payload.featureId;    
            const isPresenterFeatureNoteRemoved = Object.keys(payload).length === 2;

            let presenterFeatureNotes = [...state.notes.presenterFeatureNotes];
            
            if (isPresenterFeatureNoteRemoved) {    
                presenterFeatureNotes = presenterFeatureNotes.filter((featureNote) => featureNote.featureId !== presenterFeatureId);
            }
            else {
                const index = presenterFeatureNotes.findIndex((featureNote) => featureNote.featureId === presenterFeatureId);
                if (index > -1) {
                    presenterFeatureNotes[index] = payload;
                }
                else {
                    presenterFeatureNotes.push(payload);
                }
            }

            state.notes = {
                ...state.notes,
                presenterFeatureNotes : [
                    ...presenterFeatureNotes
                ]
            }
        },
        updateThemeFeatureNoteSuccess: (state, {payload}) => {
            const themeFeatureId = payload.featureId;    
            const isThemeFeatureNoteRemoved = Object.keys(payload).length === 1;

            let themeFeatureNotes = [...state.notes.themeFeatureNotes];
            
            if (isThemeFeatureNoteRemoved) {    
                themeFeatureNotes = themeFeatureNotes.filter((featureNote) => featureNote.featureId !== themeFeatureId);
            }
            else {
                const index = themeFeatureNotes.findIndex((featureNote) => featureNote.featureId === themeFeatureId);
                if (index > -1) {
                    themeFeatureNotes[index] = payload;
                }
                else {
                    themeFeatureNotes.push(payload);
                }
            }

            state.notes = {
                ...state.notes,
                themeFeatureNotes : [
                    ...themeFeatureNotes
                ]
            }
        },
        updateSessionNoteSuccess: (state, {payload}) => {
            const sessionId = payload.sessionId;    
            const isSessionNoteRemoved = Object.keys(payload).length === 1;

            let sessionNotes = [...state.notes.sessionNotes];
            
            if (isSessionNoteRemoved) {    
                sessionNotes = sessionNotes.filter((sessionNote) => sessionNote.sessionId !== sessionId);
            }
            else {
                const index = sessionNotes.findIndex((sessionNote) => sessionNote.sessionId === sessionId);
                if (index > -1) {
                    sessionNotes[index] = payload;
                }
                else {
                    sessionNotes.push(payload);
                }
            }

            state.notes = {
                ...state.notes,
                sessionNotes : [
                    ...sessionNotes
                ]
            }
        },
        updateSessionFeatureNoteSuccess : (state, {payload}) => {
            const featureId = payload.featureId;
            const isRemoved = Object.keys(payload).length === 1;

            let sessionFeatureNotes = [...state.notes.sessionFeatureNotes];
            if (isRemoved) {
                sessionFeatureNotes = sessionFeatureNotes.filter((featureNote) => featureId !== featureNote.featureId);
            }
            else {
                const index = sessionFeatureNotes.findIndex((featureNote) => featureId === featureNote.featureId);
                if (index > -1) {
                    sessionFeatureNotes[index] = payload;
                }
                else {
                    sessionFeatureNotes.push(payload);
                }
            }

            state.notes = {
                ...state.notes,
                sessionFeatureNotes : sessionFeatureNotes
            }
        },
        createPresenterFollowupRequestSuccess:  (state, {payload}) => {
            const followupRequest: UserPresenterFollowup = payload.followupRequest;
            const existingRequest = state.followupRequests.find(
                (request) => request.presenterId === followupRequest.presenterId
            );

            if (!existingRequest) {
                state.followupRequests = [...state.followupRequests, followupRequest];
            }
        },
        cancelPresenterFollowupRequestSuccess: (state, {payload}) => {
            const presenterId: number = payload.presenterId;

            state.followupRequests = state.followupRequests.filter(
                (request) => request.presenterId !== presenterId
            );
        },
        createDsrConsentRequestSuccess:  (state, {payload}) => {
            const dsrConsent: UserDsrConsent = payload.dsrConsent;
            state.isShared = dsrConsent.isShared;
        },
    }
});

export const {
    initializeEmployee,
    initializeEmployeeSuccess,
    initializeEmployeeFailure,
    fetchAttendee,
    fetchAttendeeSuccess,
    loadUserPermissionSetSuccess,
    loadUserPermissionSetFailure,
    userShowChange,
    updateLikedItemSuccess,
    updateLikedThemeFeatureSuccess,
    updateLikedPresenterFeatureSuccess,
    updateLikedSessionSuccess,
    updateLikedSessionFeatureSuccess,
    updateItemNoteSuccess,
    updatePresenterFeatureNoteSuccess,
    updateThemeFeatureNoteSuccess,
    updateSessionNoteSuccess,
    updateSessionFeatureNoteSuccess,
    createPresenterFollowupRequestSuccess,
    cancelPresenterFollowupRequestSuccess,
    createDsrConsentRequestSuccess
} = userSlice.actions;
export default userSlice.reducer;