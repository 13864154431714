import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ApiResponseHandler, SelectableCustomer } from '../../@types';
import { useAccessToken } from '../../hook/accessTokenHook';
import CustomerService from '../../service/customer/b2bCustomerService';
import MBIcon from '../app/mbIcon';
import MBLabelGroup from '../app/mbLabelGroup';

export interface CustomerSelectSearchProps {
    selectedCustomerIds:number[],
    onCustomerSelected : (customerId:number) => void,
    onCustomerAdded : () => void
}

const CustomerSelectAutoComplete:React.FC<CustomerSelectSearchProps> = 
        ({selectedCustomerIds, onCustomerSelected, onCustomerAdded}) => {
    const token = useAccessToken();

    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [autoCompleteCustomers, setAutoCompleteCustomers] = useState<SelectableCustomer[]>([]);

    const customerSelected = (customer:SelectableCustomer) => {
        setSearch(customer.customerName);
        setAutoCompleteCustomers([]);
        onCustomerSelected(customer.customerId);
    }

    const searchCustomers = (event:React.ChangeEvent<HTMLInputElement>) => {
        let searchString = event.currentTarget.value;
        setSearch(searchString);

        searchString = searchString.trim();
        if (searchString.length < 3) {
            setAutoCompleteCustomers([]);
            return;
        }

        const handler:ApiResponseHandler<any> = {
            onSuccess : (searchCustomers) => {
                let customerResults:SelectableCustomer[] = [];
                if (Array.isArray(searchCustomers) && searchCustomers.length) {
                    customerResults = searchCustomers
                        .filter((customer) => !selectedCustomerIds.includes(parseInt(customer.id)))
                        .map((customer) => {
                            return {
                                customerId : parseInt(customer.id),
                                customerName : customer.accountDesc,
                                isBillToOnly : customer.billToOnly,
                                isActive : 'Y' === customer.active
                            }
                        });
                }

                setAutoCompleteCustomers(customerResults);
            },
            onError : (error) => {},
            onComplete : () => setIsLoading(false)
        };

        setIsLoading(true);
        CustomerService.searchCustomerAccounts(token, searchString, handler);
    }

    const customerAdded = () => {
        setSearch('');
        setAutoCompleteCustomers([]);
        onCustomerAdded();
    }

    return (
        <Row className="align-items-end">
            <Col>
                <MBLabelGroup label="Customer" id="customerAutoSelectContainer" htmlFor="customerSelectSearchInput" className="mt-2" isBlock={true}>
                    <div className="d-flex">
                        <div className="w-100 me-2">
                            <input id="customerSelectSearchInput" className="form-control" value={search} onChange={searchCustomers} />
                            {(isLoading || !!autoCompleteCustomers.length) &&
                            <div className="position-relative">
                                {isLoading && <div style={{position: 'absolute', top: 0}}>Searching Customers...</div>}
                                {!!autoCompleteCustomers.length &&
                                    <ul className="bg-white pt-2 shadow rounded list-group w-100" style={{position: 'absolute', top: 0, maxHeight: 200, overflowY: 'auto'}}>
                                        {autoCompleteCustomers.map((customer) => 
                                            <li className="list-group-item cursor-pointer mb-group-item" key={customer.customerId} onClick={() => customerSelected(customer)}>
                                                {customer.customerName}
                                            </li>
                                        )}
                                    </ul>
                                }
                            </div>
                            }
                        </div>
                        <Button className="mb-button pb-0 customer-add-button" variant="" size="sm" onClick={customerAdded}><MBIcon color="text-charcoal-700" icon="add" /></Button>
                    </div>
                </MBLabelGroup>
            </Col>
        </Row>
    );
}

export default CustomerSelectAutoComplete;