import React, { HTMLProps } from 'react';
import { Link } from 'react-router-dom';
import { Theme } from '../../@types';

interface ThemeLinkProps extends HTMLProps<HTMLAnchorElement> {
    theme: Theme;
    searchTerm?: string;
}

const ThemeLink: React.FC<ThemeLinkProps> = (props) => {
    const {theme, searchTerm = ''} = props;

    return (
        <Link className={`item-theme-link text-nowrap ${props.className ?? ''}`} to={`/theme-detail/${theme.themeId}/${searchTerm}`}>
            <span className="d-inline-block">{theme.name}</span>
        </Link>
    );
};

export default ThemeLink;