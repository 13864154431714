import { ApiResponse, ApiResponseHandler, BaseApiRequest, FeatureType, Note, Nullable } from "../../@types";
import { REQUIRED_TOKEN_MESSAGE } from "../../api/api";
import userNoteApi from "../../api/user/userNoteApi";
import { handleApiResponse } from "../../util/apiUtil";
import { PRESENTER_FEATURE_KEY } from "../presenter/presenterService";
import { SESSION_FEATURE_KEY } from "../session/sessionService";
import { THEME_FEATURE_KEY } from "../theme/themeService";


export interface NoteRequest extends BaseApiRequest {
    note : Note
}

class UserNoteService {
    public async saveNote(request:NoteRequest, featureType:Nullable<FeatureType>, handler:ApiResponseHandler<Note>):Promise<void> {
        if (!request.accessToken) {
            const error:ApiResponse = {data:null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const validationError = this.validateNote(request.note);
        if (validationError) {
            const error:ApiResponse = {data: null, error: validationError};
            handleApiResponse(error, handler);
            return;
        }

        let response = null;
        const {note} = request;

        if (featureType != null) {
            switch (featureType) {
                case PRESENTER_FEATURE_KEY :
                    response = await userNoteApi.updatePresenterFeatureNote(request);
                    break;
                case THEME_FEATURE_KEY : 
                    response = await userNoteApi.updateThemeFeatureNote(request);
                    break;
                case SESSION_FEATURE_KEY :
                    response = await userNoteApi.updateSessionFeatureNote(request);
            }
        }
        else  {
            if (note.itemNumber) {
                response = await userNoteApi.updateItemNote(request);
            } 
            else if (note.sessionId) {
                response = await userNoteApi.updateSessionNote(request);
            }
        }

        if (response == null) {
            response = {
                data : null, 
                  error: 'Unknown operation'
              };
        }

        handleApiResponse(response, handler);  
    }

    private validateNote(note:Note):Nullable<string> {
        if (!note) {
            return 'A note is required';
        }

        if (!note.itemNumber && !note.presenterId && !note.featureId && !note.sessionId) {
          return 'A note must be for an Item, Presenter, Feature, or Session';
        }

        return null;
    }
}

export default new UserNoteService();