import React, {useState} from "react";
import {Alert, Button, Card, Col, Row} from 'react-bootstrap';
import NoteEditor from "./noteEditor";
import ConfirmationModal, { ConfirmationProps } from "../modal/mbModalConfirmation";
import { ApiResponseHandler, FeatureType, Note, Nullable, Presenter } from "../../@types";
import { MBModalProps } from "../modal/mbModal";
import { useAccessToken } from "../../hook/accessTokenHook";
import { useAppDispatch, useAppSelector } from "../../hook/appHook";
import { saveNote } from "../../state/action/user/userAction";
import { PRESENTER_FEATURE_KEY } from "../../service/presenter/presenterService";

export interface NoteProps {
    note: Note,
    featureType? : FeatureType
};

const NoteCard:React.FC<NoteProps> = ({note, featureType}) => {
    const accessToken = useAccessToken();
    const dispatch = useAppDispatch();

    const [isShowNoteEditor, setIsShowNoteEditor] = useState(false);
    const [isRemoveNoteConfirmation, setIsRemoveNoteConfirmation] = useState(false);
    const [error, setError] = useState<Nullable<string>>(null);
    
    const {presenters} = useAppSelector((state) => state.PresenterState);

    let notePresenter:Nullable<Presenter> = null;
    if (PRESENTER_FEATURE_KEY === featureType) {
        
        notePresenter = presenters.find((presenter) => !!presenter.features.find((feature) => feature.featureId === note.featureId)) ?? null;
    }

    const customerIdsClone = note.customerIds?.length ? [...note.customerIds] : [];
    const customerIdsString = customerIdsClone?.length ? customerIdsClone.join(", ") : "";

    const noteEditorModalProps = {
        note: note,
        featureType : featureType,
        onModalClose: () => setIsShowNoteEditor(false)
    }

    const removeModalProps:MBModalProps = {
        mbModalProps: {
            actionBtnText:"Remove",
            title:`Remove Note`,
            size: "sm",
            onModalSubmit: () => {
                return new Promise(resolve => {
                    const handler:ApiResponseHandler<any> = {
                        onSuccess: () => resolve(true),
                        onError: (error:string) => {
                            resolve(false);
                        }
                    }

                    const noteToRemove:Note = {
                        ...note,
                        note : '',
                        customerIds : []
                    };

                    if (notePresenter) {
                        noteToRemove.presenterId = notePresenter.presenterId;
                    }

                    dispatch(saveNote(accessToken, noteToRemove, featureType, handler));
                });
            }
        }
    }

    const removeNoteModalProps:ConfirmationProps = {
        title: 'Remove Note',
        message:`Are you sure you want to remove this note?`,
        mbModalProps: removeModalProps.mbModalProps,
        show: true,
        onHide: () => setIsRemoveNoteConfirmation(false)
    }

    return (
        <div className="position-relative border-bottom note-row">
            <Card className="border-0 ">
                <Card.Body className="px-0">
                    <Row className="mb-1 align-items-center">
                        <Col>
                            {!!error &&
                                <Alert 
                                        variant="danger" 
                                        onClose={() => setError(null)} 
                                        dismissible>
                                    {error}
                                </Alert>
                            }
                            <Card.Text as="div" className="mb-0">
                                {!!customerIdsString?.length &&
                                    <div className="mb-inline-label-group">
                                        <label>Customers</label>
                                        <div>
                                            {customerIdsString}
                                        </div>
                                    </div>
                                }
                                {!!note.note &&
                                    <div className="mb-inline-label-group align-items-start">
                                        <label>Note</label>
                                        <div>{note.note}</div>
                                    </div>
                                }
                            </Card.Text>
                        </Col>
                        <Col xs="12" sm="auto" className="mt-2 mt-md-0 d-sm-flex d-grid">
                            <Button 
                                    className="mb-button note-edit-button me-sm-2 mb-2 mb-sm-0" 
                                    variant="" 
                                    size="sm" 
                                    onClick={() => setIsShowNoteEditor(true)}>
                                Edit
                            </Button>
                            <Button 
                                    className="mb-button note-remove-button" 
                                    variant="" 
                                    size="sm" 
                                    onClick={() => setIsRemoveNoteConfirmation(true)}>
                                Remove
                            </Button>
                            {isShowNoteEditor &&
                                <NoteEditor {...noteEditorModalProps}  />
                            }
                            {isRemoveNoteConfirmation && 
                                <ConfirmationModal {...removeNoteModalProps} /> 
                            }
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    )
};

export default NoteCard;