import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import Image from '../component/app/image';

const ShowLoading:React.FC<{}> = () => {
    
    return (
        <Container style={{height: '90vh'}}>
            <Row className="text-center d-flex align-items-center h-100">
                <Col>
                    <Image 
                        src="https://cdn.martinbros.com/content/m_badge_logo_300.png" 
                        alt="MB Logo" />
                    <div className="mt-4">
                        <div className="my-3">Preparing the Show... Please Wait</div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default ShowLoading;