export const TEXT_INPUT_TYPE = {
    TEXT : 'text',
    NUMBER : 'number',
    PHONE_NUMBER : 'tel',
    EMAIL : 'email',
    PASSWORD : 'password'
};

export type TextInputType = typeof TEXT_INPUT_TYPE.TEXT 
    | typeof TEXT_INPUT_TYPE.NUMBER
    | typeof TEXT_INPUT_TYPE.PHONE_NUMBER
    | typeof TEXT_INPUT_TYPE.EMAIL
    | typeof TEXT_INPUT_TYPE.PASSWORD;

export const FORM_INPUT_ELEMENT_TYPES = [
    'input',    'select',   'textarea', 
    'fieldset', 'datalist', 'output',
    'option',   'optgroup'
];