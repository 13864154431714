import { ApiResponse } from "../../@types";
import Api from "../api";

const USER_BASE = 'user';

class UserApi {
    public async fetchUserDetails(token:string, showId:number):Promise<ApiResponse> {
        const response = await Api.performAwsGETRequest(`${USER_BASE}/${showId}`, token);
        return response;
    }
}

export default new UserApi();