import {
    ApiResponse,
    ApiResponseHandler,
    Nullable,
    UserPresenterFollowup,
    UserPresenterFollowupRequest,
} from '../../@types';
import { REQUIRED_TOKEN_MESSAGE } from '../../api/api';
import userPresenterFollowupRequestApi from '../../api/user/userPresenterFollowupRequestApi';
import { handleApiResponse } from '../../util/apiUtil';

class UserPresenterFollowupRequestService {
    public async createFollowupRequest(
        request: UserPresenterFollowupRequest,
        handler: ApiResponseHandler<UserPresenterFollowup>
    ): Promise<void> {
        if (!request.accessToken) {
            const error: ApiResponse = { data: null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const validationError = this.validateFollowupRequest(request);
        if (validationError) {
            const error: ApiResponse = { data: null, error: validationError };
            handleApiResponse(error, handler);
            return;
        }

        const response = await userPresenterFollowupRequestApi.createFollowUpRequest(request);
        handleApiResponse(response, handler);
    }

    public async removeFollowupRequest(
        request: UserPresenterFollowupRequest,
        handler: ApiResponseHandler<UserPresenterFollowup>
    ): Promise<void> {
        if (!request.accessToken) {
            const error: ApiResponse = { data: null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const validationError = this.validateFollowupRequest(request);
        if (validationError) {
            const error: ApiResponse = { data: null, error: validationError };
            handleApiResponse(error, handler);
            return;
        }

        const response = await userPresenterFollowupRequestApi.removeFollowupRequest(request);
        handleApiResponse(response, handler);
    }

    private validateFollowupRequest(request: UserPresenterFollowupRequest): Nullable<string> {
        if (!request.showId) {
            return 'A show id is required';
        }

        if (!request.presenterId) {
            return 'A presenter id is required';
        }

        return null;
    }
}

export default new UserPresenterFollowupRequestService();
