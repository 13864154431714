import React, { useState } from 'react';
import ToggleSelect from '../app/input/toggleSelect';
import { doubleDigitNumber, parseDateYYYYMMDD } from '../../util/DateTimeUtil';
import { Button } from 'react-bootstrap';
import MBIcon from '../app/mbIcon';
import { Variant } from '../../util/bootstrapUtil';

export interface TimeSlotInputProps {
    dates:string[],
    onTimeSlotAdded : (date:Date) => void
}

enum Meridiem {
    AM = "AM",
    PM = "PM"
}

const TimeSlotInput:React.FC<TimeSlotInputProps> = (props) => {
    
    const [selectedDate, setSelectedDate] = useState<string>(props.dates[0]);
    const [hour, setHour] = useState(1);
    const [minute, setMinute] = useState(0);
    const [meridiem, setMeridiem] = useState(Meridiem.AM);

    const dateOptions = props.dates.map((date) => {
        return {
            value : date, 
            text: date
        };
    });

    const hours = Array.from({length : 12})
        .map((item, index) => index + 1)
        .map((hour) => {
            return {
                value : String(hour), 
                text : doubleDigitNumber(hour)
            }
        });

    const minutes = Array.from({length : 60})
        .map((item, index) => index)
        .map((minute) => {
            return {
                value : String(minute), 
                text : doubleDigitNumber(minute)
            }
        });

    function addTimeSlot() {
        const date = parseDateYYYYMMDD(selectedDate, '-');
        if (!date) {
            return;
        }

        let adjustedHour = hour;
        if (Meridiem.PM === meridiem) {
            if (adjustedHour !== 12) {
                adjustedHour += 12;
            }
        }
        else if (adjustedHour === 12) {
            adjustedHour = 0;
        }

        date.setHours(adjustedHour);
        date.setMinutes(minute);
        
        props.onTimeSlotAdded(date);
    }

    return (
        <div className="d-flex mb-2">
            {(props.dates.length > 1) &&
            <ToggleSelect buttonVariant={Variant.PLAIN}
                    buttonClass='p-1'
                    identifier="dateSelect"
                    defaultText=""
                    value={selectedDate}
                    options={dateOptions}
                    onValueChanged={(timeSlotDate) => setSelectedDate(timeSlotDate)}
                    isEditValid={(timeSlotDate) => props.dates.includes(timeSlotDate)}/>
            }
            {(props.dates.length === 1) && <span>{props.dates[0]}</span>}
            <span className="px-1">@</span>
            <ToggleSelect buttonVariant={Variant.PLAIN}
                    buttonClass='p-1'
                    identifier="hourSelect"
                    defaultText="1"
                    value={String(hour)}
                    options={hours}
                    onValueChanged={(timeSlotHour) => setHour(parseInt(timeSlotHour))}
                    isEditValid={(hour) => !!parseInt(hour)}/>
            <span>:</span>
            <ToggleSelect buttonVariant={Variant.PLAIN}
                    buttonClass='p-1'
                    identifier="minuteSelect"
                    defaultText="00"
                    value={String(minute)}
                    options={minutes}
                    onValueChanged={(timeSlotMinute) => setMinute(parseInt(timeSlotMinute))}                    
                    isEditValid={(minute) => !!parseInt(minute)} />
            <ToggleSelect buttonVariant={Variant.PLAIN}
                    buttonClass='p-1'
                    identifier="meridiemSelect"
                    defaultText={Meridiem.AM}
                    value={meridiem}
                    options={[
                        {value: Meridiem.AM, text: Meridiem.AM},
                        {value: Meridiem.PM, text: Meridiem.PM},
                    ]}
                    onValueChanged={(timeSlotAMPM) => setMeridiem(timeSlotAMPM as Meridiem)}
                    isEditValid={(slotMeridiem) => (Meridiem.AM === slotMeridiem) || (Meridiem.PM === slotMeridiem)}/>
            <span className="me-1">Central</span>
            <Button className="mb-button pb-0" variant="" size="sm" onClick={addTimeSlot}>
                <MBIcon color="text-charcoal-700" icon="add" />
            </Button>
        </div>
    );
};

export default TimeSlotInput;