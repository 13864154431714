import React from "react";
import { Size } from "../../util/bootstrapUtil";

export interface IconProps extends React.HTMLAttributes<HTMLElement> {
    color?: string,
    size?: Size,
    icon: string,
    iconType?: 'outlined' | 'border' | 'round' | 'sharp' | 'two-tone'
}

const MBIcon:React.FC<IconProps> = (props) => {
    const {className, color, size, icon} = props;
    const classString = className ?? '';
    const colorString = color ?? "text-charcoal-500";
    const sizeString = size ?? "md";
    let baseIconClass = 'material-icons';
    if (props.iconType) {
        baseIconClass += `-${props.iconType}`;
    }

    const iconClassString = `${baseIconClass} fs-${sizeString} ${colorString} ${classString}`;

    return <i className={iconClassString} title={props.title}>{icon}</i>
}
    
export default MBIcon;