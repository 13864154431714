import {
    ApiResponse,
    ApiResponseHandler,
    Nullable,
    UserDsrConsent,
    UserDsrConsentRequest,
} from '../../@types';
import {REQUIRED_TOKEN_MESSAGE} from '../../api/api';
import userDsrConsentRequestApi from '../../api/user/userDsrConsentRequestApi';
import {handleApiResponse} from '../../util/apiUtil';

class UserDsrConsentService {
    public async createDsrConsentRequest(
        request: UserDsrConsentRequest,
        accessToken : string,
        handler: ApiResponseHandler<UserDsrConsent>
    ): Promise<void> {
        if (!accessToken) {
            const error: ApiResponse = {data: null, error: REQUIRED_TOKEN_MESSAGE};
            handleApiResponse(error, handler);
            return;
        }

        const validationError = this.validateDsrConsentRequest(request);
        if (validationError) {
            const error: ApiResponse = {data: null, error: validationError};
            handleApiResponse(error, handler);
            return;
        }

        const response = await userDsrConsentRequestApi.createDsrConsentRequest(request, accessToken);
        handleApiResponse(response, handler);
    }

    private validateDsrConsentRequest(request: UserDsrConsentRequest): Nullable<string> {
        if (!request.showId) {
            return 'A show id is required';
        }

        if (request.isShared == null) {
            return 'Is Shared is required';
        }

        return null;
    }
}

export default new UserDsrConsentService();