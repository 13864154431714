import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import reducers from './reducer'; 

const store = configureStore({
    reducer: reducers,
    middleware: getDefMiddleware => getDefMiddleware({serializableCheck: false}).concat(thunk)
})

export type AppDispatch = typeof store.dispatch;

export default store;