import React, {useState} from "react";
import { ImgHTMLAttributes } from "react";
const IMAGE_NOT_FOUND = require("../../images/noimage.png");

const DEFAULT_PROPS = {
    width: "100"
};

const Image:React.FC<ImgHTMLAttributes<HTMLImageElement>> = (imageProps) => {
    const props = {
        ...DEFAULT_PROPS,
        ...imageProps
    };

    const [source, setSource] = useState(props.src ?? IMAGE_NOT_FOUND);
    const [isFailed, setIsFailed] = useState(false);

    const errorHandler = () => {
        if (!isFailed) {
            setIsFailed(true);
            setSource(IMAGE_NOT_FOUND);
        }
    }

    return(
        <img 
            {...imageProps}
            src={source} 
            alt={props.alt} 
            className={props.className} 
            width={props.width}
            onError={errorHandler}
         />
    )
};

export default Image;