import React, { useState, useEffect, PropsWithChildren } from "react";
import { Button, Offcanvas } from 'react-bootstrap';
import MBIcon from "../app/mbIcon";
import { Size } from "../../util/bootstrapUtil";

export interface MBFlyoutProps extends PropsWithChildren {
    title: string,
    onClose: () => void
}

const MBFlyout:React.FC<MBFlyoutProps> = (props) => {
    const { title, children, onClose } = props;
    const [isShown, setIsShown] = useState(true);

    useEffect(() => {
        if(!isShown) {
            const timerInterval = setTimeout(() => onClose(), 1000);
            return () => clearTimeout(timerInterval);
        }
    }, [isShown, onClose]);

    const onFlyoutClose = () => {
        setIsShown(false);
    }

    return (
        <Offcanvas show={isShown} onHide={onFlyoutClose} className="mb-flyout" placement="end">
            <Offcanvas.Header className="mb-flyout-header">
                <Offcanvas.Title className="mb-flyout-header-title">
                    {title}
                </Offcanvas.Title>
                <Button onClick={onFlyoutClose}>
                    <MBIcon icon="close" size={Size.EXTRA_LARGE} color="text-white" />
                </Button>
            </Offcanvas.Header>
            <Offcanvas.Body className="mb-flyout-body">
                {children}
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default MBFlyout;