import React from 'react';
import MBLabelGroup from '../app/mbLabelGroup';
import { Col, Row } from 'react-bootstrap';
import { Nullable } from '../../@types';
import { Session } from '../../@types/session';
import PresenterThemeList from './presenterThemeList';
import PresenterSessionList from './presenterSessionList';

export interface PresenterLocationsProps {
    presenterId:number,
    boothAssignment:Nullable<string>,
    sessions:Nullable<Session[]>,
    themeIds:Nullable<number[]>
}

const PresenterLocations:React.FC<PresenterLocationsProps> = (props) => {
    const {boothAssignment, sessions, themeIds} = props;

    const isBoothAssigned = !!boothAssignment?.length;
    const isSessionPresent = !!sessions?.length;
    const isThemePresent = !!themeIds?.length;

    if (!isBoothAssigned && !isSessionPresent && !isThemePresent) {
        return null;
    }

    const columnStyle = {minWidth: 310};

    return (
        <div>
            <h5 className="fw-600">Where to find us</h5>
            <Row>
                {isBoothAssigned && 
                <Col sm={2} style={columnStyle}>  
                    <MBLabelGroup label="Booth"
                            id={`booth-${props.presenterId}`}
                            className="d-flex align-self-end">
                        <span className="text-nowrap">{boothAssignment}</span>
                    </MBLabelGroup>
                </Col>
                }
                {isThemePresent && 
                <Col md={4} sm={2} style={columnStyle}>
                    <PresenterThemeList themeIds={themeIds} />
                </Col>
                }
                {isSessionPresent && 
                <Col md={4} sm={2} style={columnStyle}>
                    <PresenterSessionList presenterSessions={sessions}/>
                </Col>
                }
            </Row>
        </div>
    );
};

export default PresenterLocations;