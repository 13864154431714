import { useEffect } from 'react';
import { Nullable } from '../@types';
import { refreshToken } from '../state/action/appAction/appAction';
import { useAppDispatch, useAppSelector } from './appHook';

export function useAccessToken(): Nullable<string> {
    const { appUser, isRefreshing } = useAppSelector((state) => state.AppState);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (appUser && !isRefreshing) {
            dispatch(refreshToken(appUser));
        }
    }, [appUser, isRefreshing, dispatch]);

    return appUser?.tokens.accessToken.getJwtToken() || null;
}
