import React from 'react';
import { Nullable } from '../../@types';
import { useAppSelector } from '../../hook/appHook';
import MBLabelGroup from '../app/mbLabelGroup';
import ThemeLink from '../theme/themeLink';

interface PresenterItemListProps {
    themeIds:Nullable<number[]>;
}

const PresenterThemeList: React.FC<PresenterItemListProps> = ({ themeIds }) => {
    const { themes } = useAppSelector((state) => state.ThemeState);

    if (!themeIds?.length) {
        return null;
    }

    const itemThemes = themes
        .filter((theme) => themeIds.includes(theme.themeId))
        .sort((themeA, themeB) => {
            const nameA = themeA.name;
            const nameB = themeB.name;

            if (nameA < nameB) {
                return -1;
            }

            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });

    if (!itemThemes.length) {
        return null;
    }

    return (
        <MBLabelGroup id="themePresenterList" label="Themes" className="fw-600">
            {itemThemes.map((itemTheme) => 
                <div key={`themePresenterLink_${itemTheme.themeId}`}>
                    <ThemeLink className="me-2" theme={itemTheme} />
                </div>
            )}
        </MBLabelGroup>
    );
}

export default PresenterThemeList;