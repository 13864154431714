import React from 'react';
import { Nullable } from '../../@types';
import { AttendanceStatus } from '../../service/session/sessionService';
import MBIcon from '../app/mbIcon';
import { Size } from '../../util/bootstrapUtil';

interface SessionAttendanceIconProps {
    attendance: Nullable<AttendanceStatus>;
    color?: string;
    size?: Size;
    className?: string;
}

const SessionAttendanceIcon: React.FC<SessionAttendanceIconProps> = ({ attendance, color, size, className }) => {
    const isAttended = attendance === AttendanceStatus.ATTENDED;

    return (
        <MBIcon
            icon={isAttended ? 'done' : 'schedule'}
            title={isAttended ? 'Attended' : 'Scheduled'}
            color={color}
            size={size}
            className={className}
        />
    );
};

export default SessionAttendanceIcon;
