import React from "react";
import {Container, Col, Row} from 'react-bootstrap';
import Image from '../../component/app/image'
import { useAppSelector } from "../../hook/appHook";

const MapDetail = () => {
    const { selectedShow } = useAppSelector((state) => state.ShowState);
    const mapUrl = selectedShow?.mapUrl ?? '';

    return (
        <Container fluid className="gx-5">
            <Row className="border-bottom mb-2 gx-0">
                <Col>
                    <h2>Map</h2>
                </Col>
            </Row>
            <div className="text-center">
                <Image src={mapUrl} alt="Map" style={{width: '100%', maxWidth: 800}} className="me-2" />
            </div>
        </Container>
    );
}

export default MapDetail;