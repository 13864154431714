import React, { useState } from "react";
import * as AppRoutes from '../config/routes';
import { PermissionSet } from "../constants/PermissionSet";
import { useAppSelector, useIsEmployeeUser, useIsPermissionAuthorized } from "../hook/appHook";
import MBNavigationFlyout from "./flyout/mbNavigationFlyout";
import { SessionGroup } from "../service/session/sessionService";
import MobileFooterLink from "./footer/mobileFooterLink";
import { Container, Navbar, Row } from "react-bootstrap";

const Footer = () => {
    const { selectedShow } = useAppSelector((state) => state.ShowState);
    const { themes } = useAppSelector((state) => state.ThemeState);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const hasThemesLink = !!themes.length;
    const hasMap = !!selectedShow?.mapUrl;
    const isEmployee = useIsEmployeeUser();
    const isManageContentAuthorized = useIsPermissionAuthorized(PermissionSet.SHOW_PS_M_SITE_CONTENT);
    const hasSessions = !!selectedShow?.isSessionEnabled && (isManageContentAuthorized || !!selectedShow?.sessionCount);
    const hasAttendees = !!selectedShow?.isPublic && isEmployee;

    const onMenuClose = () => {
        setIsMenuOpen(false)
    };

    const onMenuOpen = () => {
        setIsMenuOpen(true);
    }

    const iconClass = 'text-center m-0 p-0';

    const menuOptions:JSX.Element[] = [];
    if (hasSessions) {
        menuOptions.push(
            <MobileFooterLink key="mobileFooterAgendaLink"
                    className={iconClass}
                    route={AppRoutes.ROUTE_MY_AGENDA}
                    icon="calendar_today"
                    label="My Agenda" />
        );
        menuOptions.push(
            <MobileFooterLink key="mobileFooterChefDemoLink"
                    className={iconClass}
                    route={`${AppRoutes.ROUTE_SESSION_LIST}/${SessionGroup.EXPERIENCE}`}
                    icon="redeem"
                    label="Chef Demos" />
        );
        menuOptions.push(
            <MobileFooterLink key="mobileFooterSessionLink"
                    className={iconClass}
                    route={`${AppRoutes.ROUTE_SESSION_LIST}/${SessionGroup.SESSION}`}
                    icon="schedule"
                    label="Sessions" />
        );
    }

    if (hasThemesLink) {
        menuOptions.push(
            <MobileFooterLink key="mobileFooterThemeLink"
                    className={iconClass}
                    route={AppRoutes.ROUTE_THEME_LIST}
                    icon="lightbulb"
                    label="Themes" />
        );
    }

    menuOptions.push(
        <MobileFooterLink key="mobileFooterPresenterLink"
                className={iconClass}
                route={AppRoutes.ROUTE_PRESENTER_LIST}
                icon="co_present"
                label="Presenters" />
    );

    menuOptions.push(
        <MobileFooterLink key="mobileFooterNoteLink"
                className={iconClass}
                route={AppRoutes.ROUTE_NOTE_LIST}
                icon="edit_note"
                label="Notes" />
    );

    const menuLinks = menuOptions.slice(0, 4);
    menuLinks.push(
        <MobileFooterLink key="mobileFooterMenuLink"
                className={iconClass}
                route="#"
                onClick={onMenuOpen}
                icon="menu"
                label="Menu" />
    );

    return(
        <>
            <div className="app-footer d-print-none">
                    Copyright &copy; 2023 - <a href="https://www.martinbros.com">Martin Bros Distributing Co.</a>
            </div>
            <Navbar bg="dark" variant="dark" fixed="bottom" className="d-md-none d-print-none mobile-nav-bar">
                <Container fluid className="p-0">
                    <Row className="w-100 m-0 p-0">
                        {menuLinks}
                    </Row>
                    {isMenuOpen &&
                        <MBNavigationFlyout onClose={onMenuClose}
                            isSessionPresent={hasSessions}
                            isThemePresent={hasThemesLink}
                            isMapPresent={hasMap}
                            isAttendeePresent={hasAttendees} />
                    }
                </Container>
            </Navbar>
        </>
    );
};

export default Footer;