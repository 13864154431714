import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

interface MBButtonProps extends ButtonProps {
    isPrimary?: boolean
}

const MBButton:React.FC<MBButtonProps> = ({isPrimary, children, ...otherButtonProps}) => {
    const baseClassString = isPrimary ? 'mb-button-blue' : 'mb-button'; 
    otherButtonProps.className = `${baseClassString} ${otherButtonProps.className ?? ''}`;
    otherButtonProps.variant = "";

    return (
        <Button {...otherButtonProps}>
            {children}
        </Button>
    );
};

export default MBButton;