import { ApiResponse } from '../../@types';
import { 
    Session, 
    SessionByDateRequest, 
    SessionItemRequest, 
    SessionPresenterRequest, 
    SessionTimeSlotChangeRequest, 
    SessionUserAttendanceRequest, 
    SessionUserPresenterSharing, 
    SessionUserRegistrationRequest 
} from "../../@types/session";
import { formatISOAtUtc } from "../../util/DateTimeUtil";
import Api from "../api";

const BASE_URL = 'session/';

class SessionApi {
    async fetchSessionsByDate(token: string, request: SessionByDateRequest): Promise<ApiResponse> {
        const minDate = formatISOAtUtc(request.minDate);

        const url = `session-by-date/${request.showId}`;
        return await Api.performAwsGETRequest(url, token, {
            minDate: minDate == null ? '' : encodeURIComponent(minDate),
            sessionGroup: request.sessionGroup ?? '',
            email: request.email,
        });
    }

    async fetchSessionFeatures(token:string, showId:number, sessionId:number) {
        const url = `${BASE_URL}feature/all/${sessionId}/${showId}`;
        return await Api.performAwsGETRequest(url, token);
    }

    async fetchSessionFeatureById(token:string, showId:number, featureId:number):Promise<ApiResponse> {
        const url = `${BASE_URL}feature/${featureId}/${showId}`;
        return await Api.performAwsGETRequest(url, token);
    }

    public async fetchSessionList(token:string, showId:number, isFeatureIncluded:boolean):Promise<ApiResponse> {
        return await Api.performAwsGETRequest(`${BASE_URL}${showId}`, token, {isFeatureIncluded});
    }

    public async fetchSession(token:string, showId: number, sessionId: number):Promise<ApiResponse> {
        const response = await Api.performAwsGETRequest(`${BASE_URL}${sessionId}/${showId}`, token);
        return response;
    }

    public async saveSession(token:string, showId: number, session:Session):Promise<ApiResponse> {
        const response = await Api.performPOSTRequest(`${BASE_URL}${showId}`, session, token);
        return response;
    }

    public async saveSessionItem(request:SessionItemRequest):Promise<ApiResponse> {
        const response = await Api.performPOSTRequest(`${BASE_URL}item/${request.showId}`, request, request.accessToken);
        return response;
    }

    public async saveSessionPresenter(request:SessionPresenterRequest):Promise<ApiResponse> {
        const response = await Api.performPOSTRequest(`${BASE_URL}presenter/${request.showId}`, request, request.accessToken);
        return response;
    }

    public async saveSessionTimeSlot(request:SessionTimeSlotChangeRequest):Promise<ApiResponse> {
        const response = await Api.performPOSTRequest(`${BASE_URL}time-slot/${request.showId}`, request, request.accessToken);
        return response;
    }

    public async saveSessionUserRegistration(request:SessionUserRegistrationRequest):Promise<ApiResponse> {
        const response = await Api.performPOSTRequest(`${BASE_URL}register/${request.showId}`, request, request.accessToken);
        return response;
    }

    public async saveSessionUserPresenterSharing(request:SessionUserPresenterSharing):Promise<ApiResponse> {
        const response = await Api.performPOSTRequest(`${BASE_URL}user/presenter/sharing/${request.showId}`, request, request.accessToken);
        return response;
    }

    public async saveSessionUserAttendance(request:SessionUserAttendanceRequest):Promise<ApiResponse> {
        const response = await Api.performPOSTRequest(`${BASE_URL}attendance/${request.showId}`, request, request.accessToken);
        return response;
    }

    public async deleteSession(token:string, showId: number, sessionId: number):Promise<ApiResponse> {
        const response = await Api.performDELETERequest(`${BASE_URL}${showId}/${sessionId}`, token);
        return response;
    }

    public async deleteSessionItem(request: SessionItemRequest):Promise<ApiResponse> {
        const response = await Api.performDELETERequest(`${BASE_URL}${request.showId}/${request.sessionId}/item/${request.itemNumber}`, request.accessToken);
        return response;
    }

    public async deleteSessionPresenter(request: SessionPresenterRequest):Promise<ApiResponse> {
        const response = await Api.performDELETERequest(`${BASE_URL}${request.showId}/${request.sessionId}/presenter/${request.presenterId}`, request.accessToken);
        return response;
    }

    public async deleteSessionTimeSlot(request: SessionTimeSlotChangeRequest):Promise<ApiResponse> {
        const response = await Api.performDELETERequest(`${BASE_URL}${request.showId}/${request.sessionId}/time-slot/${request.time}`, request.accessToken);
        return response;
    }
    
    public async deleteSessionUserRegistration(request:SessionUserRegistrationRequest):Promise<ApiResponse> {
        const url = `${BASE_URL}${request.showId}/${request.sessionId}/register/${request.time}`;
        const params = !!request.email 
            ? `?email=${encodeURIComponent(request.email)}`
            : '';
        const response = await Api.performDELETERequest(`${url}${params}`, request.accessToken);
        return response;
    }
}

export default new SessionApi();
