import React from 'react';
import { Container } from 'react-bootstrap';
import { SessionWithStart } from '../../@types/session';
import UserSessionSummary from './UserSessionSummary';

interface UserSessionWithStartListProps {
    sessionsWithStart: SessionWithStart[];
}

const UserSessionWithStartList: React.FC<UserSessionWithStartListProps> = ({ sessionsWithStart }) => {
    return (
        <Container className="user-session-hour-group-container p-0">
            {sessionsWithStart.map((session) => (
                <UserSessionSummary
                    key={`userSessionSummary_${session.startLabel}_${session.session.sessionId}`}
                    sessionWithStart={session}
                />
            ))}
        </Container>
    );
};

export default UserSessionWithStartList;
