import React from "react";
import { Feature, FeatureType, Note, Nullable } from "../../@types";
import NoteCard from "./noteCard";
import { Row, Col, Card } from "react-bootstrap";
import MBIcon from "../app/mbIcon";
import { Size } from "../../util/bootstrapUtil";

export const EMPTY_FEATURE:Feature = {
    featureId : 0,
    title : '',
    description : '',
    thumbnailUrl : '',
    url : ''
};

export interface FeatureNoteProps {
    feature: Feature,
    note : Nullable<Note>,
    isLiked : boolean,
    featureType : FeatureType
}

const FeatureNote:React.FC<FeatureNoteProps> = ({feature, note, isLiked, featureType}) => {
    return (
        <Card className="mb-2">
            <Card.Header className="border-0">
                <Row className="align-items-center">
                    {isLiked &&
                        <Col xs="auto">
                            <MBIcon 
                            icon="thumb_up" 
                            color='text-primary'
                            size={Size.EXTRA_LARGE}
                            className="d-block" />
                        </Col>
                    }
                    <Col>
                        <div className="item-title">
                            Feature Note
                            <span className="text-charcoal-600"> ({feature.title})</span>
                        </div>
                    </Col>
                </Row>
            </Card.Header>
            {!!note &&
                <Card.Body className="notes-list">
                    <NoteCard note={note} featureType={featureType} />
                </Card.Body>
            }
        </Card>
    )
}

export default FeatureNote;