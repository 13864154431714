import React from 'react';
import { Nullable } from '../../@types';

interface ContactInformationProps {
    name: string;
    email: Nullable<string>;
    phone: Nullable<string>;
}

const ContactInformation: React.FC<ContactInformationProps> = ({ name, email = '', phone = '' }) => {
    const hasEmail = !!email;
    const hasPhone = !!phone;

    return (
        <div className="contact-information-container ">
            <h4>{name}</h4>
            <div className="d-flex flex-wrap">
                {hasEmail && (
                    <a className="contact-email" href={`mailto:${email}`}>
                        {email}
                    </a>
                )}
                {hasEmail && hasPhone && <span className="mx-1">|</span>}
                {hasPhone && (
                    <a className="contact-phone" href={`tel:${phone}`}>
                        {phone}
                    </a>
                )}
            </div>
        </div>
    );
};

export default ContactInformation;
