import React from "react";
import { Theme } from "../../@types";
import { Link } from "react-router-dom";
import {Card, Col, Row} from 'react-bootstrap';
import MBIcon from "../app/mbIcon";
import { useAppSelector } from "../../hook/appHook";
import IdentityDisplay from "../app/identityDisplay";
import { Size } from "../../util/bootstrapUtil";

export interface ThemeProps {
    theme: Theme
};

const ThemeSummary:React.FC<ThemeProps> = ({theme}) => {
    const { likes } = useAppSelector((state) => state.UserState);
    
    const themeItems = theme.itemNumbers;
    const likedItemCount = likes.likedItems.filter((likedItem) => themeItems.includes(likedItem.itemNumber))
            .filter((likedItem) => likedItem.isLiked).length
        + likes.likedThemeFeatures.filter((likedFeature) => !!theme.features.find((feature) => feature.featureId === likedFeature.featureId))
            .filter((likedFeature) => likedFeature.isLiked).length;

    return (
        <Col className="d-flex">
            <Card className="d-flex1 theme-card">
                <Link to={`/theme-detail/${theme.themeId}`} className="card-link h-100">
                    <Row className="h-100 g-0">
                        <Col sm="auto" xs={12} className="card-image-container d-none d-sm-flex">
                            <div className="logo-container">
                                <IdentityDisplay 
                                        entityId={theme.themeId} 
                                        name={theme.name} 
                                        size="lg" 
                                        iconColor={theme.color} 
                                        logoUrl={theme.logoUrl} />
                            </div>
                        </Col>
                        <Col>
                            <Card.Body className="p-0">
                                <Card.Header as="h4" className="d-flex align-items-center">
                                    <span className="me-auto theme-name">{theme.name}</span>
                                    <span className="d-flex">
                                        {!!likedItemCount && 
                                            <MBIcon 
                                                    title={`${likedItemCount} likes`}
                                                    icon="thumb_up" 
                                                    color='text-primary'
                                                    size={Size.EXTRA_LARGE}
                                                    className="ms-2" />
                                        }
                                    </span>
                                </Card.Header>
                                <Card.Text as="div" className="d-flex p-2 mb-0 align-items-center">
                                    <div className="logo-container d-sm-none">
                                        <IdentityDisplay
                                                size="sm"
                                                entityId={theme.themeId} 
                                                name={theme.name} 
                                                iconColor={theme.color}
                                                logoUrl={theme.logoUrl} />

                                    </div>
                                    <div>
                                        <p>{theme.description}</p>
                                        <p className="mb-0">
                                            {!!themeItems.length && 
                                                <span className="fw-500 theme-item-count">
                                                    Items: {themeItems.length}
                                                </span> 
                                            }
                                        </p>
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </Col>
                    </Row>
                </Link>
            </Card> 
        </Col>
    )
};

export default ThemeSummary;