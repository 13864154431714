import React from "react";
import { LikedFeature, Note, Theme } from "../../@types";
import { Accordion, Row, Col } from "react-bootstrap";
import FeatureNote from "./featureNote";
import { THEME_FEATURE_KEY } from "../../service/theme/themeService";
import IdentityDisplay from "../app/identityDisplay";

export interface PresenterNotesProp {
    theme : Theme,
    likedFeatures : LikedFeature[],
    featureNotes : Note[]
}

const ThemeNotes:React.FC<PresenterNotesProp> = ({theme, likedFeatures, featureNotes}) => {
    const features = theme.features.filter((feature) => 
        likedFeatures.find((likedFeature) => likedFeature.featureId === feature.featureId)
        || featureNotes.find((featureNote) => feature.featureId === featureNote.featureId));

    return (
        <Accordion.Item eventKey={theme.themeId.toString()} className="border mb-2">
            <Accordion.Header as="div" className="theme-note-header">
                <Row className="m-0 align-items-center flex-grow-1">
                    <Col xs="auto">
                        <IdentityDisplay 
                                entityId={theme.themeId} 
                                size="sm" 
                                logoUrl={theme.logoUrl} 
                                name={theme.name} 
                                iconColor={theme.color} />
                    </Col>
                    <Col>
                        <h5 className="m-0 theme-name">{theme.name}</h5>
                    </Col>
                </Row>
            </Accordion.Header>
            <Accordion.Body className="theme-note-list">
                {features.map((feature) => {
                    const featureNote = featureNotes.find((featureNote) => feature.featureId === featureNote.featureId) ?? null;
                    const isLiked = !!likedFeatures.find((likedFeature) => likedFeature.featureId === feature.featureId);
                    return <FeatureNote 
                            key={feature.featureId.toString()} 
                            feature={feature} 
                            note={featureNote} 
                            isLiked={isLiked}
                            featureType={THEME_FEATURE_KEY} />
                })}
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default ThemeNotes;