import React, { useEffect, useState } from "react";
import { Item } from "../../@types";
import ItemComponent from "./item";

export interface ItemListProps {
    items: Item[],
    isPresenterLinkDisplayed?: boolean,
    isRemoveButtonDisplayed?: boolean,
    onItemRemoved?: (itemNumber:string) => void
}

const ItemList:React.FC<ItemListProps> = ({items, isPresenterLinkDisplayed, isRemoveButtonDisplayed, onItemRemoved}) => {
    const [itemList, setItemList] = useState<Item[]>(items);

    useEffect(() => {
        setItemList(items);
    }, [items]);

    const onItemRemovedFromList = (itemNumber:string) => {
        setItemList(itemList.filter(item => item.itemNumber !== itemNumber));

        if(onItemRemoved) {
            onItemRemoved(itemNumber);
        }
    }

    return (
        <div id="itemList">
            {itemList?.map((item) => 
                <ItemComponent 
                        key={item.itemNumber} 
                        isPresenterLinkDisplayed={isPresenterLinkDisplayed}
                        isRemoveButtonDisplayed={isRemoveButtonDisplayed} 
                        onItemRemoved={onItemRemovedFromList}
                        item={item} />
            )}
        </div>
    )
}

export default ItemList;