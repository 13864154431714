import React from 'react'
import { Row } from 'react-bootstrap'
import { Theme } from '../../@types'
import ThemeSummary from './themeSummary'

export interface ThemeListProps {
    themes: Theme[]
}

const ThemeList:React.FC<ThemeListProps> = ({themes}) => {
    return (
        <Row xs={1} md={2} xl={3} className="g-4" id="themeList">
            {themes.map(theme => <ThemeSummary key={`theme-${theme.themeId}`} theme={theme} />)}
        </Row>
    )
}

export default ThemeList