import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Nullable } from '../../@types';
import ItemSelectAutoComplete from './itemSelectAutoComplete';

export interface ItemSelectorProps {
    selectedItems : string[],
    presenterIds : number[],
    onItemSelected : (itemNumber:string) => void
}

const ItemSelector:React.FC<ItemSelectorProps> = ({selectedItems, presenterIds, onItemSelected}) => {
    const [selectedItemNumbers, setSelectedItemNumbers] = useState(selectedItems);
    const [selectedItemNumber, setSelectedItemNumber] = useState<Nullable<string>>(null);

    if (selectedItemNumbers !== selectedItems) {
        setSelectedItemNumbers(selectedItems);
    }

    const addSelectedItem = () => {
        if (selectedItemNumber) {
            onItemSelected(selectedItemNumber);
            setSelectedItemNumbers([...selectedItemNumbers, selectedItemNumber]);
            setSelectedItemNumber(null);
        }
    }

    return (
        <div className="position-relative">
            <Row className="mt-2">
                <Col>
                    <ItemSelectAutoComplete 
                        selectedItemIds={selectedItemNumbers}
                        presenterIds={presenterIds}
                        onItemAdded={addSelectedItem}
                        onItemSelected={(itemNumber) => setSelectedItemNumber(itemNumber)} />
                </Col>
            </Row>
        </div>
    );
}

export default ItemSelector;