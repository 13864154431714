import React from "react";
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import MBIcon from "../app/mbIcon";
import { Size } from "../../util/bootstrapUtil";

const NewSessionSummary = () => {
    return (
        <Col>
            <Card>
                <Link to={`/session-detail/0`} className="card-link">
                    <Row className="g-0">
                        <Col md="auto" sm={12} className="card-image-container d-none d-sm-flex">
                            <div className="logo-container margin-auto text-center">
                                <MBIcon 
                                    title="Create New Session"
                                    icon="add" 
                                    color='text-black'
                                    size={Size.MEGA} />
                            </div>
                        </Col>
                        <Col sm>
                            <Card.Body className="p-0">
                                <Card.Header as="h4" className="d-flex align-items-center">
                                    <span className="me-auto presenter-summary-name">New Session</span>
                                </Card.Header>
                                <Card.Text as="div" className="p-2 mb-0 d-flex align-items-center">
                                    <div className="logo-container d-sm-none margin-auto text-center">
                                        <MBIcon 
                                            title="Create New Session"
                                            icon="add" 
                                            color='text-black'
                                            size={Size.MEGA} />
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </Col>
                    </Row>
                </Link>
            </Card>
        </Col>
    )
};

export default NewSessionSummary;