import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Row } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { ApiResponseHandler, Item, LikedItem, Note, Nullable } from "../../@types";
import { getConfiguration } from '../../config/configuration';
import { useAccessToken } from "../../hook/accessTokenHook";
import { useAppDispatch, useAppSelector, useIsEmployeeUser } from "../../hook/appHook";
import { updateLikedItem } from '../../state/action/user/userAction';
import Image from '../app/image';
import LikeComponent from "../like/likeComponent";
import MBLabelGroup from "../app/mbLabelGroup";
import NoteEditor, { NoteEditorProps } from "../note/noteEditor";
import PresentedByLink from "../presenter/presentedByLink";
import ExternalLink from "./externalLink";
import { MBModalProps } from "../modal/mbModal";
import ConfirmationModal, { ConfirmationProps } from "../modal/mbModalConfirmation";

const {b2bServerUrl} = getConfiguration();

export interface ItemProps {
    item: Item,
    isPresenterLinkDisplayed?: boolean,
    isRemoveButtonDisplayed?: boolean,
    onItemRemoved?: (itemNumber:string) => void
};

const ItemComponent:React.FC<ItemProps> = ({item, isPresenterLinkDisplayed, isRemoveButtonDisplayed, onItemRemoved}) => {
    const search = useParams().searchTerm ?? '';
    const accessToken = useAccessToken();
    const dispatch = useAppDispatch();

    const [isShowNoteEditor, setIsShowNoteEditor] = useState(false);
    const [itemNote, setItemNote] = useState<Nullable<Note>>(null);
    const [isLiked, setIsLiked] = useState<Nullable<boolean>>(null);
    const [isLikeLoading, setIsLikeLoading] = useState(false);
    const [isRemoveItemModalConfirmation, setIsRemoveItemModalConfirmation] = useState<boolean>(false);

    const presenter = useAppSelector((state) => state.PresenterState.presenters.find((presenter) => presenter.presenterId === item.presenterId));
    const isEmployee = useIsEmployeeUser();

    const hasFlags = item.isSpecial || item.isVirtual;
    const specialFlagClass = `specialFlag${!!item.isVirtual ? ' mr-2' : ''}`;

    const authFlow = isEmployee ? '?authFlow=employee' : '';
    const itemDetailsUrl = `${b2bServerUrl}#!/itemdetails/${item.itemNumber}${authFlow}`;
    
    const {notes, likes } = useAppSelector((state) => state.UserState);
    
    useEffect(() => {
        const isItemLiked:Nullable<boolean> = likes.likedItems.find((likedItem) => likedItem.itemNumber === item.itemNumber)?.isLiked ?? null;
        const noteForItem = notes.itemNotes.find((userItemNote) => userItemNote.itemNumber === item.itemNumber) ?? {itemNumber: item.itemNumber};

        if ((JSON.stringify(itemNote) !== JSON.stringify(noteForItem)) 
                || (isItemLiked !== isLiked)) {
            
            setItemNote(noteForItem);    
            setIsLiked(isItemLiked);
        }
    }, [item, notes, itemNote, isLiked, likes]);

    const noteEditorModalProps:NoteEditorProps = {
        title: "Item Note",
        note: {
            ...itemNote
        },
        onModalClose: () => setIsShowNoteEditor(false)
    }

    const onLikeChanged = (isLike:Nullable<boolean>) => {
        const likedItem:LikedItem = {
            itemNumber : item.itemNumber,
            isLiked : isLike
        };

        const handler:ApiResponseHandler<any> = {
            onSuccess : () => {},
            onError : () => {},
            onComplete : () => setIsLikeLoading(false)
        };

        setIsLikeLoading(true);
        dispatch(updateLikedItem(accessToken, likedItem, handler));
    }

    const itemRemoved = () => {
        setIsRemoveItemModalConfirmation(true);
    }

    const removeModalProps:MBModalProps = {
        mbModalProps: {
            actionBtnText:"Remove",
            title:`Remove Item`,
            size: "sm",
            onModalSubmit: () => {
                return new Promise(resolve => {
                    if(onItemRemoved) {
                        onItemRemoved(item.itemNumber);
                        resolve(true);
                    }
                });
            }
        }
    }

    const removeItemModalProps:ConfirmationProps = {
        title: 'Remove Item',
        message:`Are you sure you want to remove this item?`,
        mbModalProps: removeModalProps.mbModalProps,
        show: true,
        onHide: () => setIsRemoveItemModalConfirmation(false)
    }

    return (
        <Row className="g-0 border-0 border-bottom border-1 mt-2">
            <Col>
                <Card className="border-0">
                    {hasFlags &&
                        <Row className="flag-container">
                            <Col className="text-end">
                                {item.isSpecial && 
                                    <Badge bg="" className={specialFlagClass}>Special Order</Badge>
                                }
                                {item.isVirtual &&
                                    <Badge bg="" className="virtualFlag">2-day</Badge>
                                }
                            </Col>
                        </Row>
                    }
                    <Card.Body className="px-0">
                        <Row className="m-0">
                            <Col sm="auto" className="mb-3 mb-md-0 d-none d-sm-block">
                                <Image 
                                    src={item.thumbnailUrl} 
                                    alt={item.description} 
                                    className="img-fluid" 
                                />
                            </Col>
                            <Col>
                                <Row className="mb-3 mb-sm-0">
                                    <Col xs={3} className="d-block d-sm-none">
                                        <Image 
                                            src={item.thumbnailUrl} 
                                            alt={item.description} 
                                            className="img-fluid" 
                                        />
                                    </Col>
                                    <Col>
                                        <Card.Title className="mb-1 fw-600">
                                            {item.description}
                                        </Card.Title>
                                    </Col>
                                </Row>
                                <Card.Text as="div" className="mb-0 mb-label-data-row">
                                    <div className="mb-label-flex-columns">
                                        <MBLabelGroup label="Number" id={`number-${item.itemNumber}`}>
                                            <div>{item.itemNumber}</div>
                                        </MBLabelGroup>
                                        
                                        {!!item.packSize && 
                                            <MBLabelGroup label="Pack/Size" id={`packSize-${item.itemNumber}`}>
                                                {item.packSize}
                                            </MBLabelGroup>
                                        }
                                    </div>


                                    {!!item.manufacturer && 
                                        <MBLabelGroup label="MFR" id={`mfr-${item.itemNumber}`}>
                                            {item.manufacturer}
                                        </MBLabelGroup>
                                    }

                                    {!!item.keyFeatures && 
                                        <MBLabelGroup label="Features" id={`features-${item.itemNumber}`}>
                                            {item.keyFeatures}
                                        </MBLabelGroup>
                                    }
                                </Card.Text>
                                <Card.Text className="marketing-description">
                                    {item.marketingDescription}
                                </Card.Text>
                                <Card.Text as="p" className="presented-by">
                                    {!!isPresenterLinkDisplayed && !!presenter &&
                                        <MBLabelGroup id={`itemPresentedBy-${item.itemNumber}`} className="fw-600 d-flex" isBlock label="Presented By">
                                            <PresentedByLink presenter={presenter} searchTerm={search} />
                                        </MBLabelGroup>
                                    }
                                </Card.Text>
                                <Card.Text as="p" className="b2b-link">
                                    <ExternalLink link={itemDetailsUrl} content="See full item details in MB B2B site" />
                                </Card.Text>
                            </Col>
                            <Col className="action-buttons-col" sm="3" md="2">
                                <div className="action-buttons-group">
                                    {isRemoveButtonDisplayed && 
                                        <div>
                                            <Button 
                                                className="mb-button mb-2"
                                                variant="" 
                                                size="sm" 
                                                onClick={() => itemRemoved()}>
                                                    Remove
                                            </Button>
                                        </div>
                                    }
                                    <Button 
                                            className="mb-button item-note-button mb-2"
                                            variant="" 
                                            size="sm" 
                                            onClick={() => setIsShowNoteEditor(true)}>
                                        Note
                                    </Button>
                                    <div className="d-flex align-items-center justify-content-sm-end">
                                        <LikeComponent isLiked={isLiked} onLikeChanged={onLikeChanged} isLikeLoading={isLikeLoading} />
                                    </div>
                                </div>
                                {isShowNoteEditor && 
                                    <NoteEditor {...noteEditorModalProps}  />
                                }
                                {isRemoveItemModalConfirmation && 
                                    <ConfirmationModal {...removeItemModalProps} /> 
                                }
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default ItemComponent;