import React, { useState } from "react";
import {ApiResponseHandler, Feature, FeatureType, LikedFeature, Note, Nullable } from "../../@types";
import {Button, Card, Col, Row} from 'react-bootstrap';
import NoteEditor, { NoteEditorProps } from "../note/noteEditor";
import LikeComponent from "../like/likeComponent";
import { useAppDispatch, useAppSelector } from "../../hook/appHook";
import { updateLikedPresenterFeature, updateLikedSessionFeature, updateLikedThemeFeature } from "../../state/action/user/userAction";
import { useAccessToken } from "../../hook/accessTokenHook";
import { THEME_FEATURE_KEY } from "../../service/theme/themeService";
import Image from "../app/image";
import MBToast from "../toast/mbToast";
import { Link } from "react-router-dom";
import { PRESENTER_FEATURE_KEY } from "../../service/presenter/presenterService";
import { SESSION_FEATURE_KEY } from "../../service/session/sessionService";

export type FeatureProps = {
    feature: Feature,
    featureType : FeatureType,
    entityId: number
};

const FeatureComponent:React.FC<FeatureProps> = ({feature, featureType, entityId}) => {
    const accessToken = useAccessToken();
    const dispatch = useAppDispatch();

    const [isShowNoteEditor, setIsShowNoteEditor] = useState(false);
    const [error, setError] = useState<Nullable<string>>(null);
    const [isLikeLoading, setIsLikeLoading] = useState(false);

    const {likes, notes} = useAppSelector((state) => state.UserState);
    
    let featureNotes:Note[] = [];
    let featureLikes:LikedFeature[] = [];

    switch (featureType) {
        case PRESENTER_FEATURE_KEY :
            featureNotes = notes.presenterFeatureNotes;
            featureLikes = likes.likedPresenterFeatures;
            break;
        case THEME_FEATURE_KEY : 
            featureNotes = notes.themeFeatureNotes;
            featureLikes = likes.likedThemeFeatures;
            break;
        case SESSION_FEATURE_KEY :
            featureNotes = notes.sessionFeatureNotes;
            featureLikes = likes.likedSessionFeatures;
            break;
    }

    const featureNote = featureNotes.find((note) => note.featureId === feature.featureId);
    
    const noteContent = featureNote ?? {};
    const noteEditorModalProps:NoteEditorProps = {
        title: "Feature Note",
        featureType : featureType,
        note: {
            ...noteContent,
            featureId: feature.featureId
        },
        onModalClose: () => setIsShowNoteEditor(false)
    }

    const isLiked:Nullable<boolean> = featureLikes.find((likedFeature) => 
        likedFeature.featureId === feature.featureId)?.isLiked ?? null;
    
    const onLikeChanged = (isLike:Nullable<boolean>) => {
        const likedFeature:LikedFeature = {
            featureId : feature.featureId,
            isLiked : isLike
        };

        const handler:ApiResponseHandler<LikedFeature> = {
            onSuccess : (response:LikedFeature) => {},
            onError : (error:string) => setError(error),
            onComplete : () => setIsLikeLoading(false)
        };

        setIsLikeLoading(true);
        switch  (featureType) {
            case THEME_FEATURE_KEY :
                dispatch(updateLikedThemeFeature(accessToken, likedFeature, handler));
                break;
            case PRESENTER_FEATURE_KEY :
                dispatch(updateLikedPresenterFeature(accessToken, likedFeature, handler));
                break;
            case SESSION_FEATURE_KEY :
                dispatch(updateLikedSessionFeature(accessToken, likedFeature, handler));
                break;
        }
    }

    return (
        <Col className="d-flex">
            <Card className="d-flex1">
                <Row className="g-0 d-flex align-items-center h-100">
                    {!!feature.thumbnailUrl &&
                        <Col md="auto" sm={12} className="align-self-start card-image-container d-none d-sm-flex">
                            <div className="logo-container">
                                <Image 
                                    src={feature.thumbnailUrl} 
                                    alt={`${feature.title} photo`} 
                                />
                            </div>
                        </Col>
                    }
                    <Col sm className="d-flex align-self-stretch">
                        <Card.Body className="p-0 d-flex flex-column">
                            <Card.Header as="h4" className="d-flex align-items-center">
                                <span className="me-auto feature-link">
                                    <Link 
                                        to={`/${featureType}/${entityId}/feature/${feature.featureId}`} 
                                        title={feature.title}>{feature.title}
                                    </Link>
                                </span>
                            </Card.Header>
                            <Row className="p-2 align-items-center d-flex1">
                                {!!feature.thumbnailUrl &&
                                    <Col xs="auto" className="d-sm-none card-image-container-mobile">
                                        <Image 
                                            src={feature.thumbnailUrl}
                                            alt={`${feature.title} photo`} 
                                         />
                                    </Col>
                                }
                                <Col>
                                    <div className="card-description-container mb-2">
                                        <p>{feature.description}</p>
                                    </div>
                                </Col>
                                <Col md="auto" className="text-end text-md-center mt-2 mt-md-0">
                                    <div className="d-inline-block d-md-block">
                                        <LikeComponent isLiked={isLiked} onLikeChanged={onLikeChanged} isLikeLoading={isLikeLoading} />
                                    </div>
                                    <div className="d-inline-block d-md-block">
                                        <Button className="mb-button item-note-button mx-2 feature-note-button" title="Update Note" variant="" size="sm" onClick={() => setIsShowNoteEditor(true)}>Note</Button>
                                    </div>
                                    {isShowNoteEditor &&
                                        <NoteEditor {...noteEditorModalProps} />
                                    }
                                </Col>
                            </Row>
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
            {!!error &&
                <MBToast mbToastProps={{ title: 'Failed to save like setting', message: error }} />
            }
        </Col>
    );
};

export default FeatureComponent;