import React, { HTMLProps, useState } from 'react'
import MBModal from "../modal/mbModal";
import Image from './image'
import { Alert, Col, Row } from 'react-bootstrap';
import { ApiResponseHandler, FileUploadRequest, Nullable } from '../../@types';
import MediaService from '../../service/media/mediaService';
import { useAccessToken } from '../../hook/accessTokenHook';
import { getConfiguration } from '../../config/configuration';

interface ImageUploadModalProps extends HTMLProps<HTMLDivElement> {
    currentImageUrl: string,
    uploadDirectory: string,
    onImageUploaded: (itemNumber:string) => void,
    isDisabled?: boolean,
}

const ImageUploader: React.FC<ImageUploadModalProps> = ({currentImageUrl, uploadDirectory, onImageUploaded, isDisabled}) => {
    const accessToken = useAccessToken();
    const { cdnBaseUrl } = getConfiguration();
    
    const [isShown, setIsShown] = useState(false);
    const [imageUrl, setImageUrl] = useState(currentImageUrl);
    const [error, setError] = useState<Nullable<string>>(null);
    const [uploadedFile, setUploadedFile] = useState<Nullable<File>>(null);

    const uploadFile = async (): Promise<boolean> => {
        return new Promise((resolve) => {
            if(!uploadedFile) {
                resolve(false);
                setError('Please select an image to upload.');
                return;
            }

            const fileKey = createFileKey(uploadedFile.name);
            const filePath = cdnBaseUrl + fileKey;

            generateFileData(uploadedFile).then(fileData => {
                const contentType = fileData.substring(fileData.indexOf(':') + 1, fileData.indexOf(';'));
                const fileContents = fileData.substring(fileData.indexOf(',') + 1);

                const fileUploadRequest:FileUploadRequest = {
                    fileKey: fileKey,
                    fileData: fileContents,
                    contentType: contentType
                };

                const handler:ApiResponseHandler<boolean> = {
                    onSuccess : (isSuccess) => {
                        if(isSuccess) {
                            onImageUploaded(filePath);
                            setImageUrl(filePath);
                            setIsShown(false);
                        }

                        resolve(isSuccess);
                    },
                    onError : (error) => {
                        setError(error);
                        resolve(false);
                    }
                };

                MediaService.uploadFile(accessToken, fileUploadRequest, handler);
            })
            .catch((error) => setError(error));
        });
    }

    const onFileUpload = (event:React.ChangeEvent<HTMLInputElement>) => {
        let file = event.currentTarget.files?.[0];

        if(!file) {
            return;
        }

        if(!validateFileName(file.name)) {
            return;
        }

        setUploadedFile(file);
    }
    
    const validateFileName = (fileName:string):boolean => {
        if (!fileName) {
            return false;
        }

        if (fileName.indexOf('+') > -1) {
            setError('File name may not contain the "+" character');
            return false;
        }

        const fileNameFormat = fileName?.split('.')?.pop()?.toLowerCase() ?? '';

        return isValidImageFileName(fileNameFormat);
    }

    const isValidImageFileName = (fileNameFormat:string):boolean => {
        if ((fileNameFormat === "jpg")
            || (fileNameFormat === "jpeg")
            || (fileNameFormat === "png")
            || (fileNameFormat === "gif")) {
            return true;
        }

        setError('Invalid file format. Please select a jpg, jpeg, png, or gif.')
        return false;
    }

    const createFileKey = (fileName:string):string => {
        let directory = uploadDirectory;
        if (directory.length && (directory.substring(directory.length - 1) !== '/')) {
            directory += '/';
        }

        const extensionIndex = fileName.lastIndexOf('.');
        const fileNameWithSuffix = extensionIndex > -1
            ? fileName.substring(0, extensionIndex) + fileName.substring(extensionIndex)
            : fileName;

        return directory + fileNameWithSuffix;
    }

    const generateFileData = async (file:File):Promise<any> => {
        if(!file) {
            return Promise.resolve(null);
        }

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    return (
        <div>
            <Image 
                key={imageUrl} 
                src={imageUrl} 
                onClick={() => setIsShown(!isDisabled && true)} 
                className={isDisabled ? '' : 'cursor-pointer'} 
            />
            <MBModal
                show={isShown}
                onHide={() => setIsShown(false)}
                mbModalProps={{
                    title: 'Upload File',
                    isCancelButtonDisplayed: true,
                    isCloseButtonDisplayed: true,
                    size: 'lg',
                    actionBtnText: 'Upload',
                    onModalSubmit: () => uploadFile()
                }}
            >
                {!!error &&
                    <Alert variant="danger" onClose={() => setError(null)} dismissible>
                        {error}
                    </Alert>
                }
                <div className="border p-3 mb-3">
                    <Row>
                        <Col>
                            <div className="text-charcoal-500 mb-2">New Image:</div>
                            <input type="file" name="file" accept=".jpg,.jpeg,.png,.gif" onChange={onFileUpload}/>
                        </Col>
                    </Row>
                </div>
            </MBModal>
        </div>
    );
}

export default ImageUploader;