import { ApiResponse } from "../../@types";
import API from "../api";

const THEME_BASE = 'themes/'

class ThemeApi {
    public async fetchThemeList(token:string, showId:number):Promise<ApiResponse> {
        return await API.performAwsGETRequest(`${THEME_BASE}${showId}`, token);
    }

    async fetchThemeSessions(token:string, showId:number, themeId:number):Promise<ApiResponse> {
        return await API.performAwsGETRequest(`${THEME_BASE}${showId}/${themeId}/sessions`, token);
    }
}

export default new ThemeApi();