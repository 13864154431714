import { ApiResponse } from "../../@types";
import Api from "../api";

class ShowApi {
    public async fetchShowList(token:string):Promise<ApiResponse> {
        const response = await Api.performAwsGETRequest('events', token);
        return response;
    }

    async selectShow(showId:number, token:string):Promise<ApiResponse> {
        return await Api.performPOSTRequest(`event/${showId}`, null, token);
    }
}

export default new ShowApi();