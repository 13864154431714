import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ApiResponseHandler, Item, ItemSearchRequest } from '../../@types';
import { useAccessToken } from '../../hook/accessTokenHook';
import ItemService from '../../service/item/itemService';
import MBIcon from '../app/mbIcon';
import { useAppSelector, useDebounce } from '../../hook/appHook';

export interface ItemSelectSearchProps {
    selectedItemIds:string[],
    presenterIds:number[],
    onItemSelected : (itemNumber:string) => void,
    onItemAdded : () => void
}

const ItemSelectAutoComplete:React.FC<ItemSelectSearchProps> = 
        ({selectedItemIds, presenterIds, onItemSelected, onItemAdded}) => {
    const token = useAccessToken();

    const showId = useAppSelector((state) => state.ShowState.selectedShow?.showId ?? 0);
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [autoCompleteItems, setAutoCompleteItems] = useState<Item[]>([]);
    const debouncedSearchText = useDebounce(search, 750);

    const itemSelected = (item:Item) => {
        setSearch(item.description);
        setAutoCompleteItems([]);
        onItemSelected(item.itemNumber);
    }

    const searchItems = useCallback((searchString?:string) => {
        searchString = searchString?.trim() ?? '';
        if (searchString.length < 3) {
            setAutoCompleteItems([]);
            return;
        }

        if (!showId || !token) {
            return;
        }

        const request: ItemSearchRequest = {
            showId: showId,
            presenterIds: presenterIds,
            searchTerm: searchString,
        };

        const handler: ApiResponseHandler<Item[]> = {
            onSuccess: (items) => {
                setAutoCompleteItems(items?.filter(item => !selectedItemIds.includes(item.itemNumber)));
            },
            onError: (error) => {
                setAutoCompleteItems([]);
            },
            onComplete: () => setIsLoading(false),
        };

        setIsLoading(true);
        ItemService.searchItems(token, request, handler);
    }, [presenterIds, selectedItemIds, showId, token]);

    useEffect(() => {
        searchItems(debouncedSearchText);
    }, [debouncedSearchText, searchItems]);

    const itemAdded = () => {
        setSearch('');
        setAutoCompleteItems([]);
        onItemAdded();
    }

    return (
        <Row className="align-items-end">
            <Col>
                <div className="d-flex">
                    <div className="w-100 me-2">
                        <input id="itemSelectSearchInput" className="form-control" value={search} onChange={(event) => setSearch(event.target.value)}  />
                        {(isLoading || !!autoCompleteItems.length) &&
                        <div className="position-relative">
                            {isLoading && <div style={{position: 'absolute', top: 0}}>Searching Items...</div>}
                            {!!autoCompleteItems.length &&
                                <ul className="bg-white mt-2 shadow rounded list-group w-100" style={{position: 'absolute', top: 0, maxHeight: 200, overflowY: 'auto', zIndex:1000}}>
                                    {autoCompleteItems.map((item) => 
                                        <li className="list-group-item cursor-pointer mb-group-item" key={item.itemNumber} onClick={() => itemSelected(item)}>
                                            {item.itemNumber} - {item.description}
                                        </li>
                                    )}
                                </ul>
                            }
                        </div>
                        }
                    </div>
                    <Button className="mb-button pb-0 item-add-button" variant="" size="sm" onClick={itemAdded}>
                        <MBIcon color="text-charcoal-700" icon="add" />
                    </Button>
                </div>
            </Col>
        </Row>
    );
}

export default ItemSelectAutoComplete;