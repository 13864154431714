import React, {ChangeEvent, HTMLProps, LegacyRef, useRef, useState } from 'react';
import MBButtonLink from './mbButtonLink';
import { TextInputType } from '../../../util/inputUtil';
import { Variant } from '../../../util/bootstrapUtil';

interface ToggleInputTextProps extends HTMLProps<HTMLInputElement> {
    inputType:TextInputType,
    inputClass?:string,
    buttonClass?:string,
    buttonVariant?:Variant,
    identifier: string,
    placeholderText:string,
    isRequired?: boolean,
    isDisabled?: boolean,
    maxLength?: number,
    validationMessage?: string,
    onValueChanged: (value:string) => void,
    isEditValid : (value:string) => boolean,
    onEditFieldChanged?: (isInFocus:boolean) => void
}

const ToggleInputText:React.FC<ToggleInputTextProps> = (props) => {
    
    const [isInputDisplayed, setIsInputDisplayed] = useState(false);
    const [displayText, setDisplayText] = useState(props.value || (props.placeholderText ?? ''));
    const [value, setValue] = useState(props.value);
    const [isValid, setIsValid] = useState(false);
    const textInput:LegacyRef<HTMLInputElement> = useRef(null);

    React.useEffect(() => {
        if (isInputDisplayed) {
            textInput?.current?.focus();
        }
    }, [isInputDisplayed]);

    function enableEditing() {
        if (!props.isDisabled) {
            setIsInputDisplayed(true);
        }
    }

    function onValueChanged(event:ChangeEvent<HTMLInputElement>) {
        const newValue = event.currentTarget.value;
        setValue(newValue);
        const isValid = props.isEditValid?.(newValue) ?? true;
        setIsValid(isValid);

        if (isValid) {
            setDisplayText(newValue);
        }
    }

    function onInputBlur() {
        setIsInputDisplayed(false);
        props.onEditFieldChanged?.(false);

        const currentValue = value as string ?? '';
        const isValid = props.isEditValid?.(currentValue) ?? true;
        if (isValid) {
            props.onValueChanged(currentValue);
        }
    }

    function onInputFocus() {
        props.onEditFieldChanged?.(true);
    }

    const buttonClasses:string[] = [props.buttonClass ?? ''];
    if (isInputDisplayed) {
        buttonClasses.push('d-none');
    }

    if (!!props.isRequired) {
        buttonClasses.push('required-field');
    }
    
    const inputClasses:string[] = [props.inputClass ?? ''];
    if (!isInputDisplayed) {
        inputClasses.push('d-none');
    }

    const toggleText = displayText || props.placeholderText;

    return (
        <>
            {!isInputDisplayed &&
                <div className={`mb-plaintext ${props.className ?? ''}`}>
                    <MBButtonLink id={`${props.identifier}-toggleButton`}
                            variant={props.buttonVariant}
                            className={`text-start ${buttonClasses.join(' ')}`}
                            onClick={enableEditing}>
                        {toggleText}
                    </MBButtonLink>
                </div>
            }
            <input ref={textInput}
                    type={props.inputType}
                    value={value}
                    onChange={onValueChanged}
                    onFocus={onInputFocus}
                    onBlur={onInputBlur}
                    id={props.identifier} 
                    className={`mb-form-control ${inputClasses.join(' ')} ${props.className ?? ''}`}
                    placeholder={props.placeholderText ?? ''}
                    maxLength={props.maxLength}
                    tabIndex={props.tabIndex}
                    disabled={props.isDisabled}/>
            {!isValid && !!props.validationMessage &&
                <div className="error-message">
                    {props.validationMessage}
                </div>
            }
        </>
    );
};

export default ToggleInputText