import { Nullable } from '../@types';

const LOCALE = 'en-US';
const CENTRAL_TIMEZONE = 'America/Chicago';

export function doubleDigitNumber(value:number):string {
    return `${value < 10 ? '0' : ''}${value}`;
}

const formatMonthString = (date: Date): string => {
    const adjustedMonth = date.getMonth() + 1;
    return doubleDigitNumber(adjustedMonth);
};

export function formatISOAtUtc(date: Nullable<Date>): string {
    return date?.toISOString() ?? '';
}

export const formatDateYYYYMMDD = (date: Date): string =>
    `${date.getFullYear()}-${formatMonthString(date)}-${doubleDigitNumber(date.getDate())}`;

export const isValidDate = (date:Nullable<Date>):boolean => {
    return (!!date?.getDate) && !isNaN(date.valueOf());
}    

export const parseDateYYYYMMDD = (dateString:Nullable<string>, delimiter:string):Nullable<Date> => {
    if (!dateString) {
        return null;
    }

    const parts = dateString.split(delimiter);
    if (parts.length < 3) {
        return null;
    }

    const year = parseInt(parts[0]);
    const month = parseInt(parts[1]);
    const day = parseInt(parts[2]);

    if (!year || isNaN(month) || !day) {
        return null;
    }

    const date = new Date(year, (month - 1), day);
    if (!isValidDate(date)) {
        return null;
    }

    return date;
}

export const formatYYYYMMDDHMMA = (date: Date): string => {
    let hours = date.getHours();
    const isPM = hours >= 12;

    hours = hours % 12;

    if (hours === 0) {
        hours = 12;
    }
    
    return `${formatDateYYYYMMDD(date)} ${hours}:${doubleDigitNumber(date.getMinutes())} ${isPM ? 'PM' : 'AM'}`;
};

export const formatYYYYMMDDATHHMMA = (date: Date): string => {
    let hours = date.getHours();
    const isPM = hours >= 12;

    hours = hours % 12;

    if (hours === 0) {
        hours = 12;
    }

    return `${formatDateYYYYMMDD(date)} @ ${doubleDigitNumber(hours)}:${doubleDigitNumber(date.getMinutes())} ${isPM ? 'PM' : 'AM'}`;
};

export function convertFromUtcToCentral(date:Date) {
    return convertCentralDate(date, false);
}

export function convertToCentralFromLocal(date:Date) {
    return convertCentralDate(date, true);
}

function convertCentralDate(date:Date, isFromLocal:boolean):Nullable<Date> {
    if (!isValidDate(date)) {
        return null;
    }

    const options:Intl.DateTimeFormatOptions = {
        hour: 'numeric', 
        hour12: false
    };

    const localTime = date.toLocaleString(LOCALE, options);
    const centralTime = date.toLocaleString(LOCALE, {...options, timeZone: CENTRAL_TIMEZONE});

    const localHour = parseInt(localTime.split(/\s+/)[0]);
    const centralHour = parseInt(centralTime.split(/\s+/)[0]);

    if (isNaN(localHour) || isNaN(centralHour)) {
        return null;
    }

    const difference = (localHour - centralHour);
    if (difference === 0) {
        return date;
    }

    const adjustedDate = new Date(date);
    adjustedDate.setHours(date.getHours() + (difference * (isFromLocal ? 1 : -1)));
    return adjustedDate;
}

export function formatAtCentralTime(utcDate:Date) {
    if (!isValidDate(utcDate)) {
        return '';
    }

    const converted = convertFromUtcToCentral(utcDate);
    if (!converted) {
        return '';
    }

    return formatYYYYMMDDATHHMMA(converted);
}