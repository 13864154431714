import React from "react"
import {Toast, ToastContainer, ToastProps} from 'react-bootstrap';

export interface MBToastProps extends ToastProps {
    mbToastProps: {
        title?: string,
        message: string
    }
}

const MBToast:React.FC<MBToastProps> = (props) => {
    const bootstrapProps:Partial<MBToastProps> = {...props};
    const background = props.bg ?? 'danger';
    const delay = props.delay ?? 5000;
    delete bootstrapProps.mbToastProps;
    
    return (
        <ToastContainer className="p-3 mb-toast-container">
        <Toast 
            {...bootstrapProps} 
            bg={background}
            delay={delay}
            autohide
        >
            {!!props.mbToastProps.title?.length &&
            <Toast.Header className="mb-toast-header bg-red-light-1" closeVariant="white">
                <strong className="me-auto">{props.mbToastProps.title}</strong>
            </Toast.Header>
            }
          <Toast.Body className="mb-toast-body bg-red-light-2">{props.mbToastProps.message}</Toast.Body>
        </Toast>
      </ToastContainer>
    )
}

export default MBToast ;