import React from 'react';
import { SessionWithStart } from '../../@types/session';
import SessionSummary from './sessionSummary';
import { Row } from 'react-bootstrap';

export interface SessionWithStartListProps {
    sessions:SessionWithStart[]
}

const SessionWithStartList:React.FC<any> = ({sessions}) => {
    
    return (
        <Row xs={1} lg={2} xxl={3} className="mb-3 g-4 d-lg-flex session-in-hour-session-list">
            {sessions.map((session:SessionWithStart) => 
                <SessionSummary key={`sessionsWithStart-${session.session.sessionId}`}
                        startLabel={session.startLabel} 
                        remainingSeats={session.remainingSeats}
                        attendanceStatus={session.attendanceStatus}
                        time={session.sort}
                        session={session.session} />
            )}
        </Row>
    );
};

export default SessionWithStartList;