import React from "react";
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { Presenter } from "../../@types";
import { useAppSelector } from "../../hook/appHook";
import IdentityDisplay from "../app/identityDisplay";
import MBIcon from "../app/mbIcon";
import MBLabelGroup from "../app/mbLabelGroup";
import { Size } from "../../util/bootstrapUtil";

export interface PresenterProps {
    presenter: Presenter
};

const PresenterSummary:React.FC<PresenterProps> = ({presenter}) => {
    const { likes } = useAppSelector((state) => state.UserState);
    
    const presenterItems = presenter.itemNumbers;
    const likedItemCount = likes.likedItems.filter((likedItem) => presenterItems.includes(likedItem.itemNumber))
            .filter((likedItem) => likedItem.isLiked).length
        + likes.likedPresenterFeatures.filter((likedFeature) => presenter.features.find((feature) => feature.featureId === likedFeature.featureId))
            .filter((likedFeature) => likedFeature.isLiked).length;

    return (
        <Col className="d-flex">
            <Card className="d-flex1">
                <Link to={`/presenter-detail/${presenter.presenterId}`} className="card-link h-100">
                    <Row className="g-0 h-100">
                        <Col md="auto" sm={12} className="card-image-container d-none d-sm-flex">
                            <div className="logo-container">
                                <IdentityDisplay
                                        entityId={presenter.presenterId}
                                        name={presenter.name}
                                        logoUrl={presenter.logoUrl}
                                        iconColor={presenter.color}
                                        size="lg" />
                            </div>
                        </Col>
                        <Col sm>
                            <Card.Body className="p-0">
                                <Card.Header as="h4" className="d-flex align-items-center">
                                    <span className="me-auto presenter-summary-name">{presenter.name}</span>
                                    <span className="d-flex">
                                        {!!likedItemCount && 
                                            <MBIcon 
                                                    title={`${likedItemCount} likes`}
                                                    icon="thumb_up" 
                                                    color='text-primary'
                                                    size={Size.EXTRA_LARGE}
                                                    className="ms-2" />
                                        }
                                    </span>
                                </Card.Header>
                                <Card.Text as="div" className="p-2 mb-0 d-flex align-items-center">
                                    <div className="logo-container d-sm-none">
                                        <IdentityDisplay
                                                entityId={presenter.presenterId}
                                                name={presenter.name}
                                                logoUrl={presenter.logoUrl}
                                                iconColor={presenter.color}
                                                size="sm" />
                                    </div>
                                    <div className="ps-2 ps-sm-0">
                                        {!!presenter.boothAssignment &&
                                            <p className="mb-label-data-row">
                                                <MBLabelGroup id="presenterBoothAssignment" label="Booth" className="booth-assignment d-flex">
                                                    {presenter.boothAssignment}
                                                </MBLabelGroup>
                                            </p>
                                        }
                                        <p className="mb-0 mb-label-data-row">
                                            <MBLabelGroup id="presenterItemsCount" label="Items" className="item-count d-flex">
                                                {presenterItems.length}
                                            </MBLabelGroup>
                                        </p> 
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </Col>
                    </Row>
                </Link>
            </Card>
        </Col>
    )
};

export default PresenterSummary;