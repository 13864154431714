import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Container } from 'react-bootstrap';
import { ApiResponseHandler, Nullable } from '../@types';
import { Attendee } from '../@types/attendee';
import { APP_ROOT_TITLE } from '../App';
import Loader from '../component/app/loader';
import AttendeeList from '../component/attendee/AttendeeList';
import { PermissionSet } from '../constants/PermissionSet';
import { useAccessToken } from '../hook/accessTokenHook';
import { useAppSelector, useDebounce, useIsEmployeeUser, useIsPermissionAuthorized } from '../hook/appHook';
import AttendeeService, { NOW_SHOWING_ALL, NowShowing } from '../service/attendee/AttendeeService';
import { formatDateYYYYMMDD } from '../util/DateTimeUtil';

const AttendeeListContainer: React.FC = () => {
    const accessToken = useAccessToken();
    const { selectedShow } = useAppSelector((state) => state.ShowState);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [attendees, setAttendees] = useState<Nullable<Attendee[]>>(null);
    const [error, setError] = useState<Nullable<string>>(null);
    const [filterText, setFilterText] = useState('');
    const [nowShowing, setNowShowing] = useState<NowShowing>(NOW_SHOWING_ALL);
    const isEmployee = useIsEmployeeUser();
    const hasManagePermission = useIsPermissionAuthorized(PermissionSet.SHOW_PS_M_ATTENDANCE);
    const debouncedFilterText = useDebounce(filterText, 750);

    const showId = selectedShow?.showId ?? 0;

    const performSearch = useCallback(
        (filterText?: string, nowShowing?: NowShowing) => {
            if (!accessToken) {
                return;
            }

            const handler: ApiResponseHandler<Attendee[]> = {
                onSuccess: (attendees) => setAttendees(attendees ?? []),
                onError: (error) => {
                    setError(error);
                    setAttendees([]);
                },
                onComplete: () => setIsLoading(false),
            };

            setIsLoading(true);
            AttendeeService.getAttendees(accessToken, showId, handler, { filterText: filterText?.trim(), nowShowing });
        },
        [accessToken, setAttendees, setError, setIsLoading, showId]
    );

    useEffect(() => {
        document.title = `${APP_ROOT_TITLE} - Attendees`;

        if (isLoading || attendees != null || !accessToken || !isEmployee) {
            return;
        }

        performSearch();
    }, [isLoading, attendees, accessToken, isEmployee, performSearch]);

    useEffect(() => {
        performSearch(debouncedFilterText, nowShowing);
    }, [debouncedFilterText, nowShowing, performSearch]);

    if (!isEmployee || !selectedShow || !accessToken) {
        return null;
    }

    let isCheckInModifiable: boolean = hasManagePermission;
    if (isCheckInModifiable) {
        const { eventStartDate, eventEndDate } = selectedShow;
        const currentDate = formatDateYYYYMMDD(new Date());
        isCheckInModifiable = currentDate >= eventStartDate && currentDate <= eventEndDate;
    }

    const modifyAttendeeCheckIn = (email: string, isCheckedIn: boolean) => {
        if (!attendees || !isCheckInModifiable) {
            return;
        }
        setIsLoading(true);

        const handler: ApiResponseHandler<Attendee> = {
            onSuccess: (updatedAttendee) => {
                setAttendees(
                    attendees
                        .map((attendee) => {
                            if (attendee.email === updatedAttendee.email) {
                                return {...attendee, ...updatedAttendee};
                            }

                            return attendee;
                        })
                );
            },
            onError: (error) => {
                setError(error);
            },
            onComplete: () => setIsLoading(false),
        };

        AttendeeService.modifyAttendeeCheckIn(
            accessToken,
            {
                showId,
                email,
                isCheckedIn,
            },
            handler
        );
    };

    return (
        <Container fluid={false} className="px-3 container-attendees" style={{ paddingBottom: '2rem' }}>
            {isLoading && <Loader />}
            {!!error && (
                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                    {error}
                </Alert>
            )}
            <AttendeeList
                isLoading={isLoading}
                attendees={attendees ?? []}
                onModifyCheckIn={modifyAttendeeCheckIn}
                isCheckInModifiable={isCheckInModifiable}
                filterText={filterText}
                onFilterTextChange={setFilterText}
                nowShowing={nowShowing}
                onNowShowingChange={setNowShowing}
            />
        </Container>
    );
};

export default AttendeeListContainer;
