import React from "react";
import { PresenterContact } from "../../@types";

export type ContactProps = {
    contact: PresenterContact
};

const Contact:React.FC<ContactProps> = ({contact}) => {
    const mailTo = `mailto:${contact.email}`;
    const phoneNumber = `tel:${contact.phoneNumber}`;

    return(
        <div className="d-md-flex flex-wrap presenter-contact">
            <div className="presenter-contact-meta align-items-center">
                <span>{contact.name}, {contact.title}</span>
                <a href={mailTo} className="ms-2 presenter-email-link">{contact.email}</a>
            </div>
            <div><a href={phoneNumber} className="presenter-phonenumber-link">{contact.phoneNumber}</a></div>
        </div>
    )
};

export default Contact;