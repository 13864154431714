import React from 'react';
import { SessionsInHour } from '../../@types/session';
import SessionWithStartList from './sessionWithStartList';

export interface SessionsInHourProps {
    sessionsInHour:SessionsInHour
}

const HourlySessionsHour:React.FC<SessionsInHourProps> = ({sessionsInHour}) => {
    
    return (
        <div className="mb-2 session-in-hour">
            <h3 className="p-2 session-in-hour-title">
                {sessionsInHour.label}
            </h3>
            <SessionWithStartList sessions={sessionsInHour.sessions} />
        </div>
    );
};

export default HourlySessionsHour;