import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ApiResponseHandler, Nullable, SelectableCustomer } from '../../@types';
import { useAccessToken } from '../../hook/accessTokenHook';
import B2BUserService from '../../service/user/b2bUserService';
import Loader from '../app/loader';
import MBLabelGroup from '../app/mbLabelGroup';
import CustomerSelectAutoComplete from './customerSelectAutoComplete';
import { useAppSelector, useIsEmployeeUser } from '../../hook/appHook';

export interface CustomerSelectorProps {
    selectedCustomers : number[],
    onCustomerSelected : (customerId:number) => void
}

const CustomerSelector:React.FC<CustomerSelectorProps> = ({selectedCustomers, onCustomerSelected}) => {
    const token = useAccessToken();
    const [selectedCustomerIds, setSelectedCustomerIds] = useState(selectedCustomers);
    const [isLoading, setIsLoading] = useState(false);
    const [customers, setCustomers] = useState<SelectableCustomer[]>([]);
    const [isSelectUsed, setIsSelectUsed] = useState<Nullable<boolean>>(null);
    const [selectedCustomerId, setSelectedCustomerId] = useState<Nullable<number>>(null);
    const customerId = useAppSelector((state) => parseInt(state.UserState.attendee?.customerNumber ?? '500'));
    const isEmployee = useIsEmployeeUser();

    if (selectedCustomerIds !== selectedCustomers) {
        setSelectedCustomerIds(selectedCustomers);
    }

    useEffect(() => {
        if (!token) {
            return;
        }
        
        if (!isLoading && (isSelectUsed == null)) {
            const handler:ApiResponseHandler<any> = {
                onSuccess : (data) => {
                    setIsSelectUsed(!!data?.useSelect);

                    if (data?.useSelect && data?.accounts?.length) {
                        if (!Array.isArray(data.accounts) || !data.accounts.length) {
                            return [];
                        }
                
                        const availableCustomers = data.accounts.map((customer:any) => {
                            return {
                                customerId : parseInt(customer.value),
                                customerName : customer.text,
                                isBillToOnly : customer.group === 'Y',
                                isActive : customer.attributes === 'Y'
                            };
                        });

                        setCustomers(availableCustomers);
                    }
                },
                onError : (error) => {
                    setIsSelectUsed(false);
                },
                onComplete : () => {
                    setIsLoading(false);
                }
            }

            setIsLoading(true);
            B2BUserService.getUserCustomers(token, customerId, isEmployee, handler);
        }
    }, [token, isLoading, isSelectUsed, customerId, isEmployee]);

    const onCustomerSelectChange = (event:React.ChangeEvent<HTMLSelectElement>) => {
        const select = event.currentTarget;
        const customerId = parseInt(select.options[select.selectedIndex].value);
        if (customerId) {
            setSelectedCustomerId(customerId);
        }
    }

    const addSelectedCustomer = () => {
        if (selectedCustomerId) {
            onCustomerSelected(selectedCustomerId);
            setSelectedCustomerIds([...selectedCustomerIds, selectedCustomerId]);
            setSelectedCustomerId(null);
            
        }
    }

    const availableCustomers = !isSelectUsed
        ? []
        : customers.filter((customer) => !selectedCustomerIds.includes(customer.customerId));

    return (
        <div className="position-relative">
            {isLoading && <Loader className="postion-absolute" />}
            {isSelectUsed && 
                 <Row className="mt-2 align-items-end">
                    <Col>
                        <MBLabelGroup label="Customer" id="customerSelectContainer" htmlFor="customerSelector" className="mt-2" isBlock={true}>
                            <select onChange={onCustomerSelectChange}>
                                <option value="0">Select A Customer</option>
                                {availableCustomers.map((customer) => 
                                    <option 
                                            key={customer.customerId} 
                                            value={customer.customerId}>
                                        {customer.customerName}
                                    </option>
                                )}
                            </select>
                        </MBLabelGroup>
                    </Col>
                    <Col sm="auto">
                        <Button className="mb-button customer-add-button" variant="" size="sm" onClick={addSelectedCustomer}>Add</Button>
                    </Col>
                 </Row>
            }
            
            {!isSelectUsed &&
                <Row className="mt-2">
                    <Col>
                        <CustomerSelectAutoComplete 
                            selectedCustomerIds={selectedCustomerIds}
                            onCustomerAdded={addSelectedCustomer}
                            onCustomerSelected={(customerId) => setSelectedCustomerId(customerId)} />
                    </Col>
                </Row>
            }
        </div>
    );
}

export default CustomerSelector;