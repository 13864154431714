import { createSlice } from '@reduxjs/toolkit';
import { PresenterState } from '../../@types';

const INITIAL_STATE:PresenterState = {
    isLoading : false,
    presenters : []
};

const presenterSlice = createSlice({
    name: "presenter",
    initialState: INITIAL_STATE,
    reducers: {
        fetchPresenterList: (state) => {
            state.isLoading = true;
        },
        fetchPresenterListSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.presenters = payload;
        },
        fetchPresenterListFailure: (state) => {
            state.isLoading = false;
            state.presenters = [];
        }
    }
})

export const {fetchPresenterList, fetchPresenterListSuccess, fetchPresenterListFailure} = presenterSlice.actions;
export default presenterSlice.reducer;