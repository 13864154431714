import React from 'react'
import { Nullable } from '../../@types';
import Image from './image';
import ProfileCircle from './profileCircle';

export interface IdentityDisplayProps extends React.HTMLAttributes<HTMLElement> {
    entityId : number,
    name : string,
    logoUrl? : Nullable<string>,
    iconColor : Nullable<string>,
    size : 'sm' | 'lg'
}

const IdentityDisplay:React.FC<IdentityDisplayProps> = (props) => {
    let cleanProps:Partial<IdentityDisplayProps> = {...props};
    delete cleanProps.entityId;
    delete cleanProps.name;
    delete cleanProps.logoUrl;
    delete cleanProps.iconColor;
    delete cleanProps.size;

    if (props.logoUrl) {
        return (<Image {...cleanProps} src={props.logoUrl} alt={props.name} />);
    }

    return (
        <ProfileCircle 
                {...cleanProps}
                profileId={props.entityId} 
                name={props.name} 
                size={props.size} 
                iconColor={props.iconColor} />
    );
}

export default IdentityDisplay;