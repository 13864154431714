import React from 'react';
import { Session } from '../../@types/session';
import { LikedFeature, LikedSession, Note } from '../../@types';
import SessionNote from './sessionNote';

export interface SessionNoteListProps {
    sessions:Session[],
    likedSessions:LikedSession[],
    sessionNotes:Note[],
    likedFeatures:LikedFeature[],
    featureNotes:Note[]
}

const SessionNoteList:React.FC<SessionNoteListProps> = (props) => {
    return (
        <>
            {props.sessions.map((session) => 
                <SessionNote key={`sessionNote-${session.sessionId}`}
                        session={session} 
                        likedSessions={props.likedSessions} 
                        likedSessionFeatures={props.likedFeatures} 
                        sessionNotes={props.sessionNotes} 
                        sessionFeatureNotes={props.featureNotes} />
            )}
        </>
    );
};

export default SessionNoteList;