import React, { useEffect, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { ApiResponseHandler, Nullable } from '../../@types';
import { Attendee } from '../../@types/attendee';
import { TimeSlotUser } from '../../@types/session';
import { PermissionSet } from '../../constants/PermissionSet';
import { useAccessToken } from '../../hook/accessTokenHook';
import { useAppSelector, useIsPermissionAuthorized } from '../../hook/appHook';
import sessionService from '../../service/session/sessionService';
import { Variant } from '../../util/bootstrapUtil';
import MBButton from '../app/input/mbButton';
import AttendeeAutocomplete from '../attendee/AttendeeAutocomplete';
import MBModal from '../modal/mbModal';

enum RegistrationOption {
    REGISTER_ONLY = 'registerOnly',
    REGISTER_AND_ATTEND = 'registerAndAttend',
}

interface WalkInRegistrationButtonProps {
    sessionId: number;
    sessionTime: string;
    onRegistration?: (updatedTimeSlots: TimeSlotUser[]) => any;
}

const WalkInRegistrationButton: React.FC<WalkInRegistrationButtonProps> = ({
    sessionId,
    sessionTime,
    onRegistration,
}) => {
    const accessToken = useAccessToken();
    const { selectedShow } = useAppSelector((state) => state.ShowState);
    const isAuthorized = useIsPermissionAuthorized(PermissionSet.SHOW_PS_M_ATTENDANCE);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedAttendee, setSelectedAttendee] = useState<Nullable<Attendee>>(null);
    const [error, setError] = useState<Nullable<string>>(null);
    const [registrationType, setRegistrationType] = useState<RegistrationOption>(RegistrationOption.REGISTER_ONLY);

    useEffect(() => {
        setSelectedAttendee(null);
    }, [setSelectedAttendee, isModalVisible]);

    const showId = selectedShow?.showId ?? 0;

    if (!isAuthorized || !showId || !accessToken) {
        return null;
    }

    const handleSavePress = async (): Promise<boolean> => {
        return new Promise((resolve) => {
            if (!selectedAttendee) {
                return resolve(false);
            }

            const handler: ApiResponseHandler<TimeSlotUser[]> = {
                onSuccess: (updatedTimeSlotUsers) => {
                    onRegistration?.(updatedTimeSlotUsers);
                    return resolve(true);
                },
                onError: (error) => {
                    setError(error);
                    resolve(false);
                },
            };

            sessionService.saveSessionUserRegistration(
                {
                    showId,
                    accessToken,
                    name: `${selectedAttendee.firstName} ${selectedAttendee.lastName}`,
                    sessionId,
                    time: sessionTime,
                    email: selectedAttendee.email,
                    isAttended: registrationType === RegistrationOption.REGISTER_AND_ATTEND,
                },
                handler
            );
        });
    };

    return (
        <>
            <MBButton className="attendee-registration-button" id={`attendeeRegistrationButton_${sessionId}`} onClick={() => setIsModalVisible(true)}>Register</MBButton>
            <MBModal
                show={isModalVisible}
                mbModalProps={{
                    title: 'Register Attendee for Session',
                    isCancelButtonDisplayed: true,
                    isCloseButtonDisplayed: true,
                    actionBtnText: 'Save',
                    size: 'lg',
                    onModalSubmit: handleSavePress,
                    isActionBtnDisabled: !selectedAttendee,
                }}
                onHide={() => setIsModalVisible(false)}
            >
                {!!error && (
                    <Alert variant={Variant.DANGER} onClose={() => setError(null)} dismissible>
                        {error}
                    </Alert>
                )}
                <AttendeeAutocomplete onSelect={setSelectedAttendee} />
                <div className="mt-2">
                    <Form.Check
                        id={RegistrationOption.REGISTER_ONLY}
                        name="registrationStatus"
                        type="radio"
                        value={RegistrationOption.REGISTER_ONLY}
                        checked={registrationType === RegistrationOption.REGISTER_ONLY}
                        onChange={(event) => {
                            if (event.target.checked) {
                                setRegistrationType(RegistrationOption.REGISTER_ONLY);
                            }
                        }}
                        label="Register only"
                        className="mt-3 mb-3"
                    />
                    <Form.Check
                        id={RegistrationOption.REGISTER_AND_ATTEND}
                        name="registrationStatus"
                        type="radio"
                        value={RegistrationOption.REGISTER_AND_ATTEND}
                        checked={registrationType === RegistrationOption.REGISTER_AND_ATTEND}
                        onChange={(event) => {
                            if (event.target.checked) {
                                setRegistrationType(RegistrationOption.REGISTER_AND_ATTEND);
                            }
                        }}
                        label="Register and Mark as Attended"
                        className="mt-3 mb-3"
                    />
                </div>
            </MBModal>
        </>
    );
};

export default WalkInRegistrationButton;
