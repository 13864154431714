import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as AppRoutes from '../../config/routes';
import MBFlyout from './mbFlyout';
import { SessionGroup } from '../../service/session/sessionService';
import MBIcon from '../app/mbIcon';
import { Size } from '../../util/bootstrapUtil';
import { buildLogoutUrl } from '../../config/configuration';

const LOGOUT_URL = buildLogoutUrl();

export interface MBNavigationProps {
    isSessionPresent: boolean;
    isThemePresent: boolean;
    isMapPresent: boolean;
    isAttendeePresent: boolean;
    onClose: () => void;
}

const MBNavigationFlyout: React.FC<MBNavigationProps> = (props) => {
    const { onClose } = props;

    return (
        <MBFlyout title="Menu Options" onClose={onClose}>
            <Nav className="flyout-navigation">
                {props.isSessionPresent && 
                <>
                    <Link to={AppRoutes.ROUTE_MY_AGENDA} className="nav-link d-flex align-items-center" onClick={onClose}>
                        <MBIcon icon="calendar_today" size={Size.EXTRA_LARGE} className="me-2" />
                        My Agenda
                    </Link>
                    <Link to={`${AppRoutes.ROUTE_SESSION_LIST}/${SessionGroup.EXPERIENCE}`} 
                            className="nav-link d-flex align-items-center"
                            onClick={onClose} >
                        <MBIcon icon="redeem" size={Size.EXTRA_LARGE} className="me-2" />
                        Chef Demos
                    </Link>
                    <Link to={`${AppRoutes.ROUTE_SESSION_LIST}/${SessionGroup.SESSION}`} 
                            className="nav-link d-flex align-items-center"
                            onClick={onClose}>
                        <MBIcon icon="schedule" size={Size.EXTRA_LARGE} className="me-2" />
                        Sessions
                    </Link>
                </>
                }
                {props.isThemePresent &&
                    <Link to={AppRoutes.ROUTE_THEME_LIST} 
                            className="nav-link d-flex align-items-center"
                            onClick={onClose}>
                        <MBIcon icon="lightbulb" size={Size.EXTRA_LARGE}  className="me-2" />
                        Themes
                    </Link>
                }
                <Link to={AppRoutes.ROUTE_PRESENTER_LIST} 
                        className="nav-link d-flex align-items-center"
                        onClick={onClose}>
                    <MBIcon icon="co_present" size={Size.EXTRA_LARGE}  className="me-2" />
                    Presenters
                </Link>
                <Link to={AppRoutes.ROUTE_NOTE_LIST} 
                        className="nav-link d-flex align-items-center"
                        onClick={onClose} >
                    <MBIcon icon="edit_note" size={Size.EXTRA_LARGE}  className="me-2" />
                    Notes
                </Link>
                {props.isMapPresent && 
                    <Link to={AppRoutes.ROUTE_MAP} 
                            className="nav-link d-flex align-items-center"
                            onClick={onClose}>
                        <MBIcon icon="place" size={Size.EXTRA_LARGE}  className="me-2" />
                        Map
                    </Link>
                }
                {props.isAttendeePresent && 
                    <Link to={AppRoutes.ROUTE_ATTENDEE_LIST} 
                            className="nav-link d-flex align-items-center"
                            onClick={onClose}>
                        <MBIcon icon="people" size={Size.EXTRA_LARGE}  className="me-2" />
                        Attendees
                    </Link>
                }
                <Link to={AppRoutes.ROUTE_SHOW_LIST} 
                        className="nav-link d-flex align-items-center"
                        onClick={onClose}>
                    <MBIcon icon="event" size={Size.EXTRA_LARGE}  className="me-2" />
                    Events
                </Link>
                <a href={LOGOUT_URL} 
                        className="nav-link d-flex align-items-center"
                        onClick={onClose}>
                    <MBIcon icon="logout" size={Size.EXTRA_LARGE}  className="me-2" />
                    Logout
                </a>
            </Nav>
        </MBFlyout>
    );
};

export default MBNavigationFlyout;
