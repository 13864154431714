import React, { ChangeEvent, useEffect, useState } from 'react'
import { Alert, Col, Container, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { ApiResponseHandler, Nullable } from '../@types';
import { APP_ROOT_TITLE } from '../App';
import Loader from '../component/app/loader';
import { useAccessToken } from '../hook/accessTokenHook';
import { useAppSelector, useIsPermissionAuthorized } from '../hook/appHook';
import SessionService, { SessionGroup } from '../service/session/sessionService';
import { useParams } from 'react-router-dom';
import { SessionByDate, SessionByDateRequest } from '../@types/session';
import NewSessionSummary from '../component/session/newSessionSummary';
import SessionsInHour from '../component/session/sessionsInHour';
import { PermissionSet } from '../constants/PermissionSet';

const SessionListContainer  = () => {
    const accessToken = useAccessToken();
    const {selectedShow} = useAppSelector((state) => state.ShowState);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [sessionsByDate, setSessionsByDate] = useState<Nullable<SessionByDate[]>>(null);
    const [error, setError] = useState<Nullable<string>>(null);
    const [sessionGroup, setSessionGroup] = useState<Nullable<string>>(null);
    const [selectedDate, setSelectedDate] = useState<Nullable<string>>(null);
    const isManageContentAuthorized = useIsPermissionAuthorized(PermissionSet.SHOW_PS_M_SITE_CONTENT);

    const group = useParams().group;

    useEffect(() => {
        if ((SessionGroup.EXPERIENCE === group) || (SessionGroup.SESSION === group)) {
            setSessionsByDate(null);
            setSessionGroup(group as SessionGroup);
        }
    }, [group, setSessionGroup, setSessionsByDate]);

    useEffect(() => {
        document.title = `${APP_ROOT_TITLE} - Sessions` 

        if (isLoading || (sessionsByDate != null) || !accessToken) {
            return;
        }

        const handler:ApiResponseHandler<SessionByDate[]> = {
            onSuccess : (sessions) => {
                setSessionsByDate(sessions ?? []);
                // default selection should not be Always Available
                let selectedLabel = sessions?.[0]?.label;
                if ((sessions?.length > 1) && (sessions[0].sort === 0)) {
                    selectedLabel = sessions[1].label;
                }

                setSelectedDate(selectedLabel);
            },
            onError : (error) => {
                setError(error);
                setSessionsByDate([]);
            },
            onComplete : () => setIsLoading(false)
        };

        const currentDate = new Date();
        const request:SessionByDateRequest = {
            showId : selectedShow?.showId ?? 0,
            minDate : currentDate,
        };

        if (sessionGroup) {
            request.sessionGroup = sessionGroup;
        }

        setIsLoading(true);
        SessionService.getSessionsByDate(accessToken, request, handler);
    }, [isLoading, sessionsByDate, accessToken, selectedShow, sessionGroup]);

    function onSessionDateSelected(event:ChangeEvent<HTMLSelectElement>) {
        const select = event.currentTarget;
        const value = select.options[select.selectedIndex].value;
        setSelectedDate(value);
    }

    const title = (SessionGroup.EXPERIENCE === sessionGroup) 
        ? 'Chef Demos'
        : 'Sessions';

    const sessionByDate = sessionsByDate?.find((session) => session.label === selectedDate);
    
    return (
        <Container fluid className="gx-5 container-events">
            {isLoading && <Loader />}
            {!!error &&
                <Alert 
                        variant="danger" 
                        onClose={() => setError(null)} 
                        dismissible>
                    {error}
                </Alert>
            }
            {!sessionsByDate?.length && 
                <Row className="gx-0 pb-2 pb-md-0 mb-3">
                    <Col>
                        <h2>No {title} are currently available</h2>
                    </Col>
                </Row>
            }
            {!!sessionsByDate?.length && 
                <div className="session-grouping">
                    <h2 className="border-bottom border-light session-grouping-title">
                        {title}
                    </h2>
                    <div className="d-md-block d-none session-groups-lg">
                        <Tabs activeKey={selectedDate ?? ''} onSelect={(key) => setSelectedDate(key)}>
                        {sessionsByDate.map((sessionByDate) => 
                            <Tab key={`sessionLabel-${sessionByDate.label}`} 
                                    className="mb-2 p-1"
                                    eventKey={sessionByDate.label} 
                                    title={sessionByDate.label}>
                                {sessionByDate.hourlySessions.map((hourlySessions) => 
                                <SessionsInHour key={`sessionsInHour-${hourlySessions.label}`} 
                                        sessionsInHour={hourlySessions} />
                                )}
                            </Tab>
                        )}
                        </Tabs>
                    </div>
                    <div className="d-sm-block d-md-none session-groups-mobile">
                        <Row>
                            <Col className="mb-2">
                                <Form.Select id="selectSessionGroupDate" 
                                        className="form-control" 
                                        value={selectedDate ?? ''} 
                                        onChange={onSessionDateSelected}>
                                    {sessionsByDate.map((session) => 
                                    <option key={`sessionByDate-${session.sort}`} value={session.label}>
                                        {session.label}
                                    </option>
                                    )}
                                </Form.Select>
                            </Col>
                            <Col />
                        </Row>
                        <div key={`sessionByDateContainer-${sessionByDate?.label ?? ''}`}>
                            {!!sessionByDate && sessionByDate.hourlySessions.map((hourlySessions) => 
                                <SessionsInHour key={`mobileSessionInHour-${hourlySessions.label}`}
                                    sessionsInHour={hourlySessions} />
                            )}
                        </div>
                    </div>
                </div>
                
            }
            {isManageContentAuthorized && (
                <Row>
                    <Col sm={4}>
                        <NewSessionSummary />
                    </Col>
                    <Col />
                </Row>
            )}
        </Container>
    );
};

export default SessionListContainer;