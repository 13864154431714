import React, {useState} from "react";
import { ApiResponseHandler, Feature, FeatureType, Note, Nullable, Presenter, Theme } from "../../@types";
import { Session } from "../../@types/session";
import { Alert, Form } from "react-bootstrap";
import MBModal, { MBModalProps } from "../modal/mbModal";
import { useAccessToken } from "../../hook/accessTokenHook";
import CustomerSelector from "../customer/customerSelector";
import { Badge, CloseButton } from "react-bootstrap";
import { saveNote } from "../../state/action/user/userAction";
import { useAppDispatch, useAppSelector } from "../../hook/appHook";
import { THEME_FEATURE_KEY } from "../../service/theme/themeService";
import { PRESENTER_FEATURE_KEY } from "../../service/presenter/presenterService";

export interface NoteEditorProps {
    title?: string,
    note: Note,
    featureType?:FeatureType,
    session?:Session,
    onModalClose: () => void
};

const NoteEditor:React.FC<NoteEditorProps> = ({title, note, featureType, session, onModalClose}) => {
    const token = useAccessToken();
    const dispatch = useAppDispatch();

    const [editedNote, setEditedNote] = useState<Note>(note);
    const [error, setError] = useState<Nullable<string>>(null);
    const [isModified, setIsModified] = useState<boolean>(false);

    const {themes} = useAppSelector((state) => state.ThemeState);
    const {presenters} = useAppSelector((state) => state.PresenterState);
    let theme:Nullable<Theme> = null;
    if (THEME_FEATURE_KEY === featureType) {
        theme = themes.find((theme) => !!theme.features.find((themeFeature) => themeFeature.featureId === note.featureId)) ?? null;
    }

    let noteFeature:Nullable<Feature> = !!theme
        ? theme.features.find((feature) => feature.featureId === note.featureId) ?? null
        : null;

    let presenter:Nullable<Presenter> = !note.presenterId 
        ? null 
        : presenters.find((presenter) => presenter.presenterId === note.presenterId) ?? null;

    if (!presenter && (PRESENTER_FEATURE_KEY === featureType)) {
        presenter = presenters.find((presenterByFeature) => !!presenterByFeature.features.find((presenterFeature) => presenterFeature.featureId === note.featureId)) ?? null;
        noteFeature = !!presenter
            ? presenter.features.find((presenterFeature) => presenterFeature.featureId === note.featureId) ?? null
            : null;
    }

    const onNoteSave = ():Promise<boolean> => {
        if (!isModified) {
            return Promise.resolve(true);
        }

        return new Promise(resolve => {
            const handler:ApiResponseHandler<Note> = {
                onSuccess: (data) => {
                    resolve(true);
                },
                onError: (apiError) => {
                    setError(apiError);
                    resolve(false);
                }
            }

            const noteToSave:Note = {
                ...editedNote, 
                presenterId : presenter?.presenterId
            };

            dispatch(saveNote(token, noteToSave, featureType, handler));
        });
    }

    const noteTextChanged = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
        const noteText = event.currentTarget.value;
        const updateNote:Note = {
            ...editedNote,
            note: noteText
        };

        setEditedNote(updateNote);
        setIsModified(true);
    };

    const noteModalProps:MBModalProps = {
        mbModalProps: {
            title: title ? title : 'Note',
            size: 'lg',
            actionBtnText: 'Save',
            isCancelButtonDisplayed : false,
            isCloseButtonDisplayed : !!error || !isModified,
            onModalSubmit: () => onNoteSave()
        },
        show: true,
        onHide: () => {
            // if the user is closing the modal with an error
            // Do NOT attempt to save
            const promise = !!error 
                ? Promise.resolve(true)
                : onNoteSave();

            promise.then((isSuccess) => isSuccess && onModalClose());
        }
    }

    const customerSelected = (customerId:number) => {
        let customerIds:number[] = [];
        if (editedNote.customerIds?.length) {
            customerIds = [...editedNote.customerIds];
        }

        customerIds.push(customerId);
        setEditedNote({...editedNote, customerIds});
        setIsModified(true);
    }

    const removeSelectedCustomer = (customerId:number) => {
        let customerIds:number[] = [];
        if (editedNote.customerIds?.length) {
            customerIds = [...editedNote.customerIds];
        }

        const remainingCustomerIds = customerIds.filter(noteCustomerId => customerId !== noteCustomerId);
        setEditedNote({...editedNote, customerIds: remainingCustomerIds});
        setIsModified(true);
    }

    return(
        <MBModal {...noteModalProps}>
            {!!error &&
                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                    {error}
                </Alert>
            }
            
            <div className="mb-label-data-row mb-3">
                {!!presenter &&
                    <div className="mb-inline-label-group">
                        <label>Presenter</label>
                        <div className="presenter-name">{presenter.name}</div>
                    </div>
                }
                {!!editedNote.itemNumber &&
                    <div className="mb-inline-label-group">
                        <label>Item</label>
                        <div className="item-number">{editedNote.itemNumber}</div>
                    </div>
                }
                {!!theme &&
                    <div className="mb-inline-label-group">
                        <label>Theme</label>
                        <div className="theme-name">{theme.name}</div>
                    </div>
                }
                {!!noteFeature &&
                    <div className="mb-inline-label-group">
                        <label>Feature</label>
                        <div className="feature-name">{noteFeature.title}</div>
                    </div>
                }
                {!!session &&
                    <div className="mb-inline-label-group">
                        <label>Session</label>
                        <div className="feature-name">{session.name}</div>
                    </div>
                }
            </div>

            <Form.Group className="mb-2" controlId="note.ControlTextarea1">
                <Form.Label className="fw-600">Note</Form.Label>
                <Form.Control as="textarea" rows={3} value={editedNote.note} onChange={noteTextChanged} />
            </Form.Group>

            {!!editedNote.customerIds?.length &&
                <div>
                    {editedNote.customerIds.map((customerId) =>
                        <Badge key={customerId} bg="" text="dark" className="customer-badge me-2" onClick={() => removeSelectedCustomer(customerId)}>
                            <span className="me-2">{customerId}</span>
                            <CloseButton />
                        </Badge>
                    )}
                </div>
            }
            <div className="my-3">
                <CustomerSelector selectedCustomers={editedNote.customerIds ?? []} onCustomerSelected={customerSelected} />
            </div>
        </MBModal>
    )
}

export default NoteEditor;