import React from "react";
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { buildLogoutUrl } from "../config/configuration";
import Image from './app/image';

const LOGOUT_URL = buildLogoutUrl();

const HeaderEvents = () => {

    return(
        <header className="sticky-top bg-dark">
            <Navbar bg="dark" variant="dark" expand="md">
            <Container fluid className="justify-content-between d-flex">
                <Navbar.Brand className="d-flex align-items-center">
                    <Link to="/">
                        <Image src="https://cdn.martinbros.com/content/m_badge_logo_300.png" width="60" alt="MB Logo" className="me-2" />
                        Events
                    </Link>
                </Navbar.Brand>
                <Navbar>
                    <Nav className="ms-auto">
                        <a href={LOGOUT_URL} className="nav-link">Logout</a>
                    </Nav>
                </Navbar>
            </Container>
            </Navbar>
        </header>
    )
}

export default HeaderEvents;